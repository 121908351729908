const template = `
  query StudioStylists($studioId: ID!, $date: Date!) {
    studio(id: $studioId) {
      stylists {
        id
        available(date: $date)
        user {
          id
          name
          avatar
        }
      }
    }
  }
`

export default async function stylists({ studioId, date }) {
  const { studio } = await request(template, { studioId, date })
  return studio.stylists
}

import fetchRatingStatus from './fetch-rating-status'
import fetchReviews from './fetch-reviews'
import fetchReviewReplies from './fetch-review-replies'
import { range } from 'lodash-es'

export default function ReviewPanel() {
  const t = useI18n()
  const { state, mutate } = useStatic({
    title: '',
    visible: false,
    type: null,
    id: null,
    params: null,
    tagIds: null,
    ratingName: '',
    ratingCounts: [],
    totalAverage: 0,
    totalCount: 0,
    reviews: [],
    finish: false,
    fetching: true,
    showAddress: false
  })

  h.reviewOpen = async({ type, id, params, tagIds, showAddress }) => {
    state.type = type
    state.id = id
    state.reviews = []
    state.finish = false
    state.params = params
    state.tagIds = tagIds
    state.showAddress = showAddress
    if (type !== 'guarantee' && type !== 'model') await fetchRatingStatus({ state })
    state.visible = true
    mutate()
    fetchReviews({ state, mutate })
    state.title = `${state.ratingName}${t('panel:review.title')}`
    if (type === 'stylist') return mkt.stylistReviewOpen(id)
    if (type === 'studio') return mkt.studioReviewOpen(id)
    if (type === 'guarantee') {
      state.title = `設計師${t('panel:review.title')}`
      return mkt.guaranteeReviewOpen()
    }
    state.title = `活動${t('panel:review.title')}`
    return mkt.modelReviewOpen()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    fetchReviews({ state, mutate })
  })

  const renderRatingStatus = () => {
    if (state.type === 'guarantee' || state.type === 'model') return
    return <RatingStatus
      totalAverage={ state.totalAverage }
      totalCount={ state.totalCount }
      ratingCounts={ state.ratingCounts }
      t={ t }
    />
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ state.title }>
        <div className='flex-1 overflow-scroll px-5' onScroll={ onScroll() }>
          {renderRatingStatus()}

          {state.reviews.map(review => (
            <Review
              key={ review.id }
              review={ review }
              showAddress={ state.showAddress }
              t={ t } />
          ))}
        </div>
      </C.ModalHeader>
    </C.Modal>
  )
}

const RatingStatus = ({ totalAverage, totalCount, ratingCounts, t }) => (
  <div className='pt-2 pb-5 border-b'>
    <div className='flex items-center pb-2'>
      <div className='text-[3rem] pl-8 pr-6'>{totalAverage.toFixed(1)}</div>
      <div className='pt-[0.5rem]'>
        <Stars score={ totalAverage } />
        <div className='text-xs font-medium text-gray-600 py-1'>{t('panel:review.total')}: {totalCount}</div>
      </div>
    </div>
    {ratingCounts.map(({ score, percentage, count }) => (
      <div key={ score } className='flex items-center space-x-3 py-[0.12rem]'>
        <Stars score={ score } />
        <div className='flex-1'>
          <C.ProgressBar
            percentage={ percentage }
            color='bg-teal-500'
          />
        </div>
        <div className='w-10 text-xs text-gray-600'>{count.format()}</div>
      </div>
    ))}
  </div>
)

const Stars = ({ score }) => (
  <div className='flex'>
    {range(5).map(index => {
      const color = () => {
        if (index < Math.round(score)) return 'text-yellow-400'
        return 'text-gray-300'
      }
      return (
        <Icon.StarFilled
          key={ index }
          className={ cn(color(), 'h-4 w-4') }
        />
      )
    })}
  </div>
)

const Review = ({ review, showAddress, t }) => {
  const { state, mutate } = useStatic({
    replies: review.$latestReplies,
    finish: review.$latestReplies.length < 3,
    fetching: false
  })

  const renderService = () => {
    if (!review.$serviceText) return
    return (
      <div className='text-sm text-gray-500 pb-1'>
        {t('panel:review.bookService')}: {review.$serviceText}
      </div>
    )
  }
  const toStylist = () => {
    h.path.go.stylist({ id: review.booking.stylistUser.id })
  }
  const renderStylist = () => {
    if (!review.$showStylist) return
    const { stylistUser } = review.booking
    return (
      <div className='text-sm pb-1 text-gray-500 flex'>
        <span className='mr-1'>{t('panel:review.bookStylist')}:</span>
        <div className='text-gray-500 font-bold cursor-pointer line-clamp-1 underline' onClick={ toStylist }>{stylistUser.name}</div>
      </div>
    )
  }
  const renderStudio = () => {
    if (!showAddress) return
    const { studio } = review.booking
    if (!studio) return
    return (
      <div className='text-sm text-gray-500 pb-1'>
        {t('panel:review.studioAddress')}: {studio.address}
      </div>
    )
  }
  const renderPhoto = () => {
    if (!review.photos.length) return
    return (
      <div className='flex space-x-2 pt-1 ml-1'>
        {review.photos.map((photo, idx) => (
          <div key={ idx } className='cursor-pointer' onClick={ () => h.openGallery(review.$largePhotos, idx) }>
            <C.Image
              className='w-10 h-10'
              src={ photo.url.small }
            />
          </div>
        ))}
      </div>
    )
  }
  const renderReplies = () => {
    if (!state.replies.length) return
    return (
      <div className='py-2'>
        {state.replies.map(reply => (
          <ReviewReply
            key={ reply.id }
            reply={ reply }
          />
        ))}
      </div>
    )
  }
  const moreRepliesClick = () => fetchReviewReplies({ review, state, mutate })

  const renderLoading = () => {
    if (state.fetching) return <C.Loading size={ 16 } />
    return <div className='text-xs font-bold cursor-pointer' onClick={ moreRepliesClick }>{t('panel:review.moreReply')}......</div>
  }

  return (
    <div className='py-2'>
      <div className='flex items-start'>
        <C.Avatar src={ review.user.avatar } />
        <div className='flex-1 pl-2'>
          <div className='flex'>
            <div className='font-bold text-sm'>
              <div>{review.$reviewerName}</div>
            </div>
            <div className='flex-1'></div>
            <div><Stars score={ review.rating } /></div>
          </div>
          <p className='text-sm py-1' { ...review.review.rawHtml() } />
          {renderStylist()}
          {renderService()}
          {renderStudio()}
          {renderPhoto()}
          <div className='text-xs text-gray-400 py-1'>
            <C.FromNow time={ review.createdAt } />
          </div>
          <div className={ cn('py-2', { hidden: state.finish }) }>
            {renderLoading()}
          </div>

          {renderReplies()}
        </div>
      </div>
    </div>
  )
}

const ReviewReply = ({ reply }) => (
  <div className='flex items-start'>
    <div>
      <C.Avatar
        src={ reply.replyUser.avatar }
        size='7'
      />
    </div>
    <div className='flex-1 pl-2'>
      <div className='text-sm font-bold line-clamp-1'>{reply.$replyerName}</div>
      <p className='text-sm' { ...reply.reply.rawHtml() } />
    </div>
  </div>
)

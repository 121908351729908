import DepositRecords from 'components/common/deposit-records'

export default function DepositPanel() {
  const t = useI18n()
  const { state, mutate } = useStatic({ visible: false })

  h.openMyDeposit = (userId, isAdmin) => {
    state.visible = true
    state.isAdmin = isAdmin
    state.userId = userId
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return (
      <C.ModalHeader title={ t('panel:my.deposits.title') }>
        <DepositRecords isAdmin ={ state.isAdmin } userId={ state.userId } />
        <C.Blur />
      </C.ModalHeader>
    )
  }

  return (
    <>
      <C.Modal
        visible={ state.visible }
        close={ () => mutate({ visible: false }) }
        noPadding
      >
        { renderBody() }
      </C.Modal>
    </>
  )
}
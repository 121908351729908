const template = `
  query Init($id: ID!, $studioId: ID!) {
    posts(input: {
      type: user
      typeParams: {id: $id}
      sortMethod: latest
      paginationInput: {limit: 9, offset: 0}
    }) {
      id
      isPrivate
      photos {
        id
        width
        height
      }
      tags {
        name
      }
    }
    reviews(
      type: booking,
      queryType: stylist,
      queryParams: {id: $id},
      input: {limit: 3, offset: 0}
    ) {
      id
      user {
        id
        name
        avatar
      }
      review
      rating
      createdAt
      photos {
        url {
          large
          small
        }
      }
      booking {
        services {
          service {
            name
          }
        }
        stylistUser {
          id
          name
          avatar
        }
        bookingDiscount {
          campaign {
            name
          }
        }
      }
    }
    user(id: $id) {
      id
      name
      uniqueName
      avatar
      stylist {
        id
        rating
        totalRatingCount
        isGuarantee
        profession {
          name
        }
        studio {
          id
          name
          address
          traffic
          lat
          lng
          rating
          totalRatingCount
          studioPhotos {
            url {
              large
              small
            }
          }
        }
      }
      postCount
    }
    studio(id: $studioId) {
      id
      name
      logoUrl
      address
      traffic
      lat
      lng
      rating
      totalRatingCount
      studioPhotos {
        url {
          large
          small
        }
      }
    }
  }
`

export default async function stylist(id, studioId) {
  const { user, posts, reviews, studio } = await request(template, { id, studioId })
  return { user, posts, reviews, studio }
}

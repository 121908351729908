const template = `
  query Albums($userId: ID!, $input: PaginationInput!) {
    albums(userId: $userId, input: $input) {
      id
      name
      description
      privacy
      postNum
      coverUrl
    }
  }
`

export default async function albums({ userId, limit, offset }) {
  const input = { offset, limit }
  const { albums } = await request(template, { userId, input })
  return albums
}

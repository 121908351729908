const template = `
  query Post($id: ID!){
    post(id: $id){
      id
      tagCategoryKey
      isPrivate
      description
      createdAt
      isCollected
      score
      user {
        id
        name
        avatar
        isFollowing
      }
      stylist {
        id
        profession{
          name
        }
        studio{
          id
          name
          city
          district
          address
        }
        rating
        totalRatingCount
        isGuarantee
      }
      photos {
        id
        width
        height
      }
      tags {
        id
        name
        postNum
      }
      album {
        id
        name
      }
      postMetaImage {
        url
        width
        height
      }
      postBeforePhoto {
        photo {
          large {
            url
          }
          normal {
            url
          }
          small {
            url
          }
        }
      }
    }
  }
`

export default async function post(postId) {
  const { post } = await request(template, { id: postId })
  return post
}

import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export default function Category() {
  const { services, selectCategory } = useCtx('prepayBooking')
  const t = useI18n()
  return (
    <div className='space-y-1'>
      { services.map(({ name, priceText }) => (
        <Row
          key={ name }
          text={ name }
          range={ priceText }
          selectCategory={ selectCategory }
          t={ t }
        />
      ))}
    </div>
  )
}

const Row = ({ text, range, selectCategory, t }) => (
  <div className='flex items-center justify-between cursor-pointer hover:bg-gray-50 p-3 border-b'
    onClick={ () => selectCategory(text) }
  >
    <div className='space-y-1'>
      <div className='font-bold'>{ t(`common:service.category.${text}`) }</div>
      <div className='text-sm'>{ range }</div>
    </div>
    <div>
      <ChevronRightIcon />
    </div>
  </div>
)

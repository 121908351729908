import loginRequest from 'request/auth/login'

export default function useLogin({ toStep, loginSuccess, mutate }) {
  const t = useI18n()

  const login = async params => {
    mutate({ action: 'login' })
    const renderParams = () => {
      if (params.type !== 'phone') return params
      const { type, phoneCode, phone, password } = params
      const key = params.key || parseInt(phoneCode) + phone
      return {
        type,
        key,
        password
      }
    }
    try {
      const { name } = await loginRequest(renderParams())
      loginSuccess({ name })
    } catch (error) {
      switch (error.code) {
        case 'auth.noValidatedPhone':
          const { phoneCode, phone } = params
          const { gender, name, email, token } = error.params
          mutate({
            isOldUser: error.params.enabled,
            registerForm: {
              phoneCode,
              phone,
              gender,
              name,
              email,
              token
            }
          })
          if (error.params.enabled) alert(t('panel:auth.continueRegisterWithPhone'))
          toStep('register')
          return
        case 'auth.phoneCodeIsSending':
        case 'auth.phoneNeedCode':
          mutate({
            phone: error.params.phone,
            codeExpiredAt: error.params.codeExpiredAt,
            resendPhoneCode: () => login({
              type: 'phone',
              key: error.params.phone
            })
          })
          toStep('phoneCode')
          return
        default:
          throw error
      }
    }
  }

  return login
}

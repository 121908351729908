export default function usePayment({ depositAmount, mapPayRewards, maxRewardUseRate }) {
  const { feeResolver } = usePaymentMethod()

  const usedRewardResolver = depositAmount => {
    const maxAvailableRewards = Math.floor(depositAmount * maxRewardUseRate)
    return Math.min(mapPayRewards, maxAvailableRewards)
  }

  const usedReward = usedRewardResolver(depositAmount)
  const fee = feeResolver(depositAmount - usedReward)
  const finalPayment = depositAmount - usedReward + fee

  return {
    finalPayment,
    usedReward,
    fee
  }
}
import fetchCustomerBookings from './fetch-customer-bookings'

export default function PanelBookingCustomer() {
  const t = useI18n()
  const { bookingStateDic } = useBookingConfig()
  const { state, mutate } = useStatic({
    bookings: [],
    fetching: true,
    finish: false
  })
  const { booking, customer, refreshCustomer } = useCtx('stylistBooking')
  const panel = usePanel()

  useEffect(() => {
    if (!customer) return
    state.bookings = []
    state.finish = false
    fetchCustomerBookings({ customerId: customer.id, state, mutate, bookingStateDic, t })
  }, [customer])

  if (!customer) return ''

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    fetchCustomerBookings({ customerId: customer.id, state, mutate, bookingStateDic, t })
  })

  const renderRemark = () => {
    if (!customer.notes) return
    return (
      <div className='pb-1'>
        <div className='text-sm font-medium pt-5 pb-1'>備註</div>
        <p className='text-sm' { ...customer.notes.rawHtml() }/>
      </div>
    )
  }

  const openBooking = bookingId => {
    panel.close('bookingCustomer')
    panel.close('stylistBooking')
    $openStylistBooking({ bookingId })
  }

  const renderBookings = () => state.bookings.map(booking => (
    <div key={ booking.id } className='flex items-center justify-between border-b py-3 cursor-pointer'
      onClick={ () => openBooking(booking.id) }
    >
      <div className='h-[3.25rem] flex'>
        <div className='my-auto'>
          <div className='font-medium'>{ booking.startTime.ymdhm() }</div>
          <div className='text-gray-500 text-xs line-clamp-1'>{ booking.$serviceText }</div>
          <div className={ cn('text-gray-500 text-xs line-clamp-1', { hidden: !booking.notes }) }>{ booking.notes }</div>
        </div>
      </div>
      <div className={ cn(booking.$stateColor, 'shrink-0') }>
        { booking.$i18nState }
      </div>
    </div>
  ))

  const renderLoading = () => {
    if (!state.fetching) return
    return <C.Loading />
  }

  const editCustomer = () => panel.open('stylistCustomerCreate', {
    stylist: booking.stylist,
    callback: refreshCustomer,
    defaultValue: customer
  })

  const sendMessage = () => $messenger.talkToUser(customer.user.id)

  return (
    <C.Modal
      visible={ panel.visible('bookingCustomer') }
      close={ () => panel.close('bookingCustomer') }
    >
      <div className='sm:w-[25rem] flex flex-col'>
        <div className='text-center py-1'>顧客資料</div>
        <div className='p-3 flex-1 overflow-scroll'>
          <CustomerBlock customer={ customer }/>
          <CustomerDataBlock customer={ customer } />
          { renderRemark() }
          <div className='text-sm font-medium pt-5'>預約紀錄</div>
          <div className='flex-1 overflow-scroll text-sm' onScroll={ onScroll() }>
            { renderBookings() }
            { renderLoading() }
          </div>
        </div>
        <div className='relative'><C.Blur/></div>
        <div className='flex shrink-0 space-x-3 justify-center'>
          <C.Button className='flex-1'onClick={ editCustomer }>編輯顧客</C.Button>
          <C.Button variant='outlined' className='flex-1' onClick={ sendMessage } disabled={ !customer.user }>發送訊息</C.Button>
        </div>
      </div>
    </C.Modal>
  )
}

const CustomerBlock = ({ customer }) => {
  const { $avatar, name, phone, $gender, $birthday } = customer
  return (
    <div className='pt-2 pb-4 flex space-x-4'>
      <div>
        <C.Image
          className='w-20 h-20 rounded-full'
          src={ $avatar }
        />
      </div>
      <div className='text-sm space-y-1 flex-1'>
        <CustomerBlockItem label='姓名:' value={ name } />
        <CustomerBlockItem label='性別:' value={ $gender } />
        <CustomerBlockItem label='電話:' value={ phone } />
        <CustomerBlockItem label='生日:' value={ $birthday } />
      </div>
    </div>
  )
}

const CustomerDataBlock = ({ customer }) => (
  <div className='px-5 py-3 flex justify-between text-sm border-b border-t text-center'>
    <div>
      <div>預約次數</div>
      <div className='text-base font-medium'>{ customer.bookedCount }</div>
    </div>
    <div>
      <div>貢獻收入</div>
      <div className='text-base font-medium'>{ customer.revenue.currency() }</div>
    </div>
    <div>
      <div>儲值餘額</div>
      <div className='text-base font-medium'>{ customer.remainDeposit.currency() }</div>
    </div>
  </div>
)

const CustomerBlockItem = ({ label, value }) => (
  <div className='flex space-x-4'><div>{label}</div> <span className='text-sm font-medium'>{ value }</span></div>
)
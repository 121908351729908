import Alert from '@mui/material/Alert'

export default function NotifyAlert() {
  const { state, mutate } = useStatic({})

  global.$alert = (message, type) => {
    const key = uuid()
    const autoClose = type !== 'error'
    mutate({
      [key]: {
        message,
        type,
        key,
        visible: true,
        autoClose,
        close: () => {
          mutate({
            [key]: {
              ...state[key],
              visible: false
            }
          })
        }
      }
    })
    if (!autoClose) return setTimeout(state[key].close, 10000)
    setTimeout(state[key].close, 3000)
  }

  const alerts = Object.values(state).map(({ key, message, type, visible, close, autoClose }) => (
    <C.Transition
      key={ key }
      visible={ visible }
    >
      <div className='py-1 opacity-95' onClick={ close }>
        <Alert severity={ type }
          onClose={ autoClose ? null : close }
        >
          { message }
        </Alert>
      </div>
    </C.Transition>
  ))

  return (
    <div className='fixed top-10 left-1/2 transform -translate-x-1/2 px-5 w-full max-w-3xl'
      style={
        { zIndex: 1000 }
      }
    >
      {alerts}
    </div>
  )
}

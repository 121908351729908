import bookingCancelRequest from 'request/user-booking/cancel'
import useCheckoutBooking from 'hooks/user-booking/action/checkout'

export default function Button({ booking, closePanel, refreshBooking }) {
  const t = useI18n()
  const { user } = useCurrent()
  const { payByMapPay, isCommented, state } = booking
  const cancelBooking = () => {
    h.openBookingCancelReasons({
      bookingId: booking.id,
      callback: confirmCancel
    })
  }

  const confirmCancel = async({ cancelType, cancelOtherReason }) => {
    await bookingCancelRequest(booking.id, cancelType, cancelOtherReason)
    refreshBooking(booking.id)
  }

  const openCreateReview = () => h.openStylistReviewCreate({
    bookingId: booking.id,
    callback: () => refreshBooking(booking.id)
  })

  const checkoutBooking = useCheckoutBooking({
    booking,
    refreshBooking,
    closePanel
  })

  const submitBtnResolver = () => {
    if (!booking) return null

    if (payByMapPay) {
      if (booking.hairmapId !== user?.id) return null
      return {
        submit: openCreateReview,
        type: isCommented ? 'reviewed' : 'review',
        text: isCommented ? t('panel:userBooking.reviewed') : t('panel:userBooking.review'),
        variant: 'contained',
        disabled: isCommented
      }
    }
    if (state === 2) return {
      submit: checkoutBooking,
      type: 'checkout',
      text: t('panel:userBooking.checkout'),
      variant: 'contained',
      disabled: false
    }
    if (state === 1) return {
      submit: cancelBooking,
      type: 'cancel',
      text: t('panel:userBooking.cancel'),
      variant: 'outlined',
      disabled: false
    }

    return null
  }
  const submitBtn = submitBtnResolver()

  return (
    <div className='flex-shrink-0 p-5 pt-0'>
      { !!submitBtn &&
        <C.Button uid='userBooking.submit' fullWidth variant={ submitBtn.variant } disabled={ submitBtn.disabled }
          onClick={ submitBtn.submit }>
          { submitBtn.text }
        </C.Button>
      }
    </div>
  )
}

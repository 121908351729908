const template = `
  query KeywordSearch($input: KeywordSearchInput!) {
    keywordSearch(input: $input) {
      studios {
        id
        name
        logoUrl
        authorized
        address
        rating
        totalRatingCount
        isChain
        contract {
          monthlyRent
          rentRemitDay
          rentType
          seatCount
          isRecruiting
          recruitingDescription
          isFull
        }
        rentPayoutReceiver {
          bankAccountName
          bankAccountNumber
          bankBranchCode
          bankCode
          bankVat
          taxTargetIdentifier
          taxTargetName
        }
      }
    }
  }
`

export default async function studioSearch({ keyword, offset, limit, authorized, notIncludeStudios }) {
  const input = {
    keyword: keyword || '',
    type: 'studio',
    paginationInput: { limit, offset }
  }
  if (authorized) input.params = { filterAuthorized: true, notIncludeStudios: notIncludeStudios || [] }
  const { keywordSearch } = await request(template, { input })
  return keywordSearch.studios
}

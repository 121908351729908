const template = `
  mutation PostUpdate($postId: ID!, $tagCategory: TagCategory, $albumId: ID, $description: String, $tagIds: [Int], $privacy: PostPrivacy, $images: [String], $beforePhoto: String){
    postUpdate(input: {
      postId: $postId
      tagCategory: $tagCategory
      albumId: $albumId
      description: $description
      tagIds: $tagIds
      privacy: $privacy
      images: $images
      beforePhoto: $beforePhoto
    })
  }
`

export default async function postUpdate({ postId, tagCategory, albumId, description, tagIds, privacy, images, beforePhoto }) {
  await request(template, { postId, tagCategory, albumId, description, tagIds, privacy, images, beforePhoto })
}

import { isEmpty } from 'lodash-es'
import userUpdateRequest from 'request/common/user-update'
import userInvalidate from 'request/user/user-invalidate-self'

export default function AccountSetting() {
  const t = useI18n()
  const { state, mutate } = useStatic({ visible: false })

  h.openAccountSetting = () => mutate({ visible: true })

  return (
    <C.Modal
      visible={ state.visible }
      close={ () => mutate({ visible: false }) }
      noPadding
    >
      { state.visible && <Body state={ state } mutate={ mutate } t={ t } /> }
    </C.Modal>
  )
}

const Body = parent => {
  const { user, refreshCurrent, logout } = useCurrent()
  const { state, mutate } = useStatic({
    percentage: 0,
    image: user.avatar,
    isSubmit: false
  })
  const form = useForm({
    name: { rule: 'required', value: user.name },
    uniqueName: { rule: 'required', value: user.uniqueName },
    gender: { rule: 'required', value: user.gender || 'female' },
    email: { value: user.email },
    phone: { value: user.phone },
    birthday: { value: user.birthday || null }
  })
  const ref = useRef(null)
  const { t } = parent

  const genderOptions = [{
    value: 'female',
    text: t('panel:accountSetting.female')
  }, {
    value: 'male',
    text: t('panel:accountSetting.male')
  }]

  const onImageClick = () => {
    ref.current.click()
  }

  const onFileChanged = async e => {
    const files = e.target.files
    if (!files.length) return
    const { string } = await h.imageToString(files[0])
    state.image = string
    e.target.value = null
    mutate()
  }

  const submit = async filename => {
    const { name, gender, email, uniqueName, birthday } = form
    const input = {}
    if (filename) input.avatarFilename = filename
    if (name.value != user.name) input.displayName = name.value
    if (gender.value != user.gender) input.gender = gender.value
    if (email.value != user.email) input.email = email.value
    if (uniqueName.value != user.uniqueName) input.uniqueName = uniqueName.value
    if (birthday.value != user.birthday) input.birthday = birthday.value
    if (isEmpty(input)) return finishUpdate()

    input.userId = user.id
    await userUpdateRequest(input)
    refreshCurrent()
    finishUpdate()
  }

  const finishUpdate = () => {
    $alert(t('panel:accountSetting.success'))
    parent.mutate({ visible: false })
  }

  const sentImage = async() => {
    if (state.image.isUrl()) return submit()
    mutate({ isSubmit: true })
    try {
      const filename = await h.saveFileWithProgress(state.image, percentage => mutate({ percentage }))
      await submit(filename)
    } catch (error) {
      mutate({ isSubmit: false })
      throw error
    }
  }

  const onDelete = async() => {
    if (!confirm(t('panel:accountSetting.invalidateWarning'))) return

    await userInvalidate()
    await logout(true)
    $alert(t('panel:accountSetting.invalidateSuccess'))
    h.path.go.root()
  }

  return (
    <C.ModalHeader title={ t('common:accountSetting') } >
      <div className='flex-1 overflow-scroll p-5 pb-10 flex flex-col space-y-5'>
        <div className='select-none'>
          <input
            type='file'
            ref={ ref }
            className='hidden'
            accept='image/*'
            onChange={ onFileChanged }
          />
          <div className='items-center flex justify-center w-full' >
            <div className='w-32 relative cursor-pointer' onClick={ onImageClick }>
              <C.Image
                src={ state.image }
                className='w-32 h-32 rounded-full shrink-0'
              />

              <div className='absolute left-1/2 -translate-x-1/2 -bottom-1 bg-black text-white px-4 py-1 rounded-[2px] text-sm opacity-70 text-center'>
                { t('panel:accountSetting.change') }
              </div>

              <div className={ cn('absolute inset-0', { hidden: !state.isSubmit }) }>
                <div className='absolute inset-0 bg-white opacity-70' />
                <div className='absolute top-1/2 w-32 px-2 -translate-y-1/2'>
                  <C.ProgressBar percentage={ state.percentage } />
                </div>
              </div>
            </div>
          </div>
        </div>

        <C.InputForm
          label={ t('panel:accountSetting.displayName') }
          variant='standard'
          form={ form }
          field='name'
          fullWidth
        />
        <div className='pb-5'>
          <C.SelectForm
            label={ t('panel:accountSetting.gender') }
            form={ form }
            field='gender'
            options={ genderOptions }
            className='w-full'
          />
        </div>
        <div>
          <C.DatePickerForm
            label={ t('panel:auth.birthday') }
            form={ form }
            field='birthday'
            className='w-full'
            disableFuture
          />
        </div>
        <C.InputForm
          label='Email'
          variant='standard'
          form={ form }
          field='email'
          fullWidth
        />
        <C.InputForm
          label={ t('panel:accountSetting.uniqueName') }
          variant='standard'
          form={ form }
          field='uniqueName'
          fullWidth
        />
        <C.InputForm
          label={ t('panel:accountSetting.phone') }
          variant='standard'
          form={ form }
          field='phone'
          fullWidth
          disabled
        />
        <C.Button
          variant='outlined'
          onClick={ onDelete }
          className='text-red-500'
          fullWidth
        >
          { t('panel:accountSetting.invalidateAccount') }
        </C.Button>
      </div>

      <div className='relative'><C.Blur/></div>
      <div className='shrink-0 p-5'>
        <C.Button uid='accountSetting.submit' check={ form }
          onClick={ sentImage } className='w-full'>
          { t('panel:accountSetting.submit') }
        </C.Button>
      </div>
    </C.ModalHeader>
  )

}
import orderCheckout from 'hooks/checkout'

export default function useBuyDeposit({ stylistUserId, depositAmount, finalPayment, isStylistEvent, closePanel }) {
  const t = useI18n()
  const { user } = useCurrent()
  const { method, methodParams } = usePaymentMethod()

  const successCallback = () => {
    $alert(t('panel:buyDeposit.success'))
    closePanel()
  }
  const atmCallback = () => {
    closePanel()
    $messenger.talkToUser(stylistUserId)
  }
  const input = {
    amount: finalPayment,
    type: 'buyDeposit',
    typeParams: {
      stylistUserId,
      isStylistEvent,
      amount: depositAmount
    },
    method,
    methodParams
  }
  const checkout = orderCheckout({
    input,
    onSuccess: successCallback,
    onAtm: atmCallback,
    onZerocard: closePanel
  })

  return async() => {
    if (user.id === stylistUserId) return $alert(t('panel:buyDeposit.notAvailable'), 'error')
    await checkout()
  }
}

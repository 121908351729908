import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export default function Service() {
  const { category, selectService, serviceMap } = useCtx('prepayBooking')
  const items = serviceMap[category].infos

  return (
    <div className='space-y-1'>
      { items.map((item, idx) => (
        <Row
          key={ idx }
          item={ item }
          selectService={ selectService }
        />
      )) }
    </div>
  )
}

const Row = ({ item, selectService }) => {
  const [active, setActive] = useState(!!item.shouldOpen)

  const height = () => {
    if (active) return 'max-h-[200rem]'
    return 'max-h-0'
  }

  return (
    <div className='cursor-pointer hover:bg-gray-50 p-3 border-b' onClick={ () => setActive(!active) }>
      <Header item={ item } isActive={ active } />
      <div className={ cn('transition-all duration-300 overflow-hidden', height()) }>
        <Content item={ item } selectService={ selectService }/>
      </div>
    </div>
  )
}

const Header = ({ item, isActive }) => {
  const { $type, $title, $originalRange, $remark, $range, isPublish } = item
  const t = useI18n()

  const titleResolver = () => {
    if ($type === 'stylistService') return $title
    if (isPublish) return $title
    return `(${ t('panel:my.services.unPublish') }) ${ $title }`
  }
  const title = titleResolver()

  const renderOriginalRange = () => {
    if (!$originalRange) return
    return <div className='text-gray-400 text-sm line-through'>{ $originalRange }</div>
  }

  const renderRemark = () => {
    if (!$remark) return
    return <div className='text-gray-500 text-xs' { ...$remark.rawHtml() } />
  }

  const rotate = () => {
    if (isActive) return 'rotate-90'
    return 'rotate-0'
  }
  return (
    <div className='flex items-center justify-between'>
      <div className='space-y-1'>
        <div className='font-bold'>{ title }</div>
        { renderOriginalRange() }
        { renderRemark() }
      </div>
      <div className='flex-1 px-1'></div>
      <div className='text-sm mr-1 shrink-0'>{ $range }</div>
      <div>
        <div className={ cn('transition-all', rotate()) }>
          <ChevronRightIcon />
        </div>
      </div>
    </div>
  )
}

const Content = ({ item, selectService }) => {
  const t = useI18n()
  const { $serviceTimeText, description, hairBrands } = item
  return (
    <div className='space-y-5 py-5'>
      <div className='text-sm' { ...description.rawHtml() } />
      <HairBrands brands={ hairBrands } t={ t }/>
      <div className='space-y-1'>
        <div className='font-bold text-sm'>{ t('panel:prepayBooking.detail.serviceTime') }</div>
        <div className='text-xs'> { $serviceTimeText } </div>
      </div>
      <div>
        <C.Button
          fullWidth
          onClick={ () => selectService(item) }
        >
          { t('panel:prepayBooking.detail.bookTime') }
        </C.Button>
      </div>
    </div>
  )
}

const HairBrands = ({ brands, t }) => {
  if (!brands?.length) return null

  return (
    <div className='space-y-1 overflow-scroll'>
      <div className='font-bold text-sm'>{ t('panel:prepayBooking.detail.hairBrands') }</div>
      <div className='space-y-2'>
        { brands.map(brand => (
          <div key={ brand.id } className='flex items-center shrink-0'>
            <div className='ml-2 mr-3'>
              <C.Image className='w-8 h-8' src={ brand.url } alt='logo'/>
            </div>
            <div className='text-xs'>{ brand.name }</div>
          </div>
        )) }
      </div>
    </div>
  )
}

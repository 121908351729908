export default function StepBar({ step, steps }) {

  const progressBarClass = {
    service: 'w-0',
    other: 'w-1/3',
    time: 'w-2/3',
    prepay: 'w-full'
  }

  return (
    <div className='pb-10 pt-4 px-10 border-b'>
      <div className='relative'>
        <div className='pt-[1px] bg-gray-300 top-0 translate-y-1/2'></div>
        <div className={ `translate-y-1/2 transition-all duration-500 absolute bg-black pt-[1px] top-0 ${progressBarClass[step]}` } />
        {
          steps.map(key => (
            <Step
              key={ key }
              stepAt={ step }
              step={ key }
              steps={ steps }
            />
          ))
        }
      </div>
    </div>
  )
}

const Step = ({ stepAt, step, steps }) => {
  const t = useI18n()
  const stepAtIndex = steps.indexOf(stepAt)
  const stepIndex = steps.indexOf(step)
  const bgColor = () => {
    if (stepIndex <= stepAtIndex) return 'bg-black'
    return 'bg-gray-300'
  }
  const textColor = () => {
    if (stepIndex <= stepAtIndex) return ''
    return 'text-gray-300'
  }

  const i18nKey = () => {
    const dic = {
      service: 'panel:prepayBooking.step.service',
      other: 'panel:prepayBooking.step.other',
      time: 'panel:prepayBooking.step.time',
      prepay: 'panel:prepayBooking.step.prepay'
    }
    return dic[step]
  }

  const left = () => {
    const dic = {
      service: '',
      other: 'left-1/3',
      time: 'left-2/3',
      prepay: 'right-0'
    }
    return dic[step]
  }

  const shadow = () => {
    if (step === stepAt) return 'scale-100'
    return 'scale-0'
  }

  return (
    <div className={ `absolute -top-0.5 ${left()}` }>
      <div className={ `transaction-all absolute p-[6px] rounded-full inline-block bg-black opacity-10 -translate-x-1/4 -translate-y-1/4 ${shadow()}` }></div>
      <div className={ `transaction-all duration-500 absolute p-[3px] rounded-full ${bgColor()}` }></div>
      <div className='absolute -translate-x-1/2  translate-y-4 whitespace-nowrap'>
        <div className={ `translate-x-0.5 ${textColor()} text-xs` }>{ t(i18nKey()) }</div>
      </div>
    </div>
  )
}

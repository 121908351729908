export default function useGoogleLogin({ login }) {
  const { setLoading } = useLoading()
  const handleCredentialResponse = async response => {
    setLoading('auth.google')
    try {
      await login({
        type: 'google',
        key: response.credential
      })
    } finally {
      setLoading(null)
    }
  }

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse
    })
    google.accounts.id.renderButton(
      document.getElementById('googleSignIn'),
      { size: 'large', width: 400 }
    )
  }, [])
}

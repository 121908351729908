import Guarantee from 'components/common/guarantee'

export default function GuaranteePanel() {
  const { state, mutate } = useStatic({ visible: false })

  h.openGuaranteeDetail = () => mutate({ visible: true })

  const renderBody = () => {
    if (!state.visible) return
    return (
      <div className='flex flex-col sm:w-[33rem] select-none'>
        <div className='flex-1 overflow-scroll px-3 flex flex-col'>
          <Guarantee />
          <C.Blur />
        </div>
      </div>

    )
  }

  return (
    <>
      <C.Modal
        visible={ state.visible }
        close={ () => mutate({ visible: false }) }
        noPadding
      >
        { renderBody() }
      </C.Modal>
    </>
  )
}
import All from './all'
import fetchAlbums from './fetch-albums'

export default function AlbumList(parent) {
  const { t } = parent
  const { state, mutate } = useStatic({
    albums: [],
    album: {},
    finish: false,
    fetching: true,
    init: false
  })

  const previous = () => {
    parent.state.childTitle = null
    parent.state.showPreviousBtn = false
    parent.mutate()
    state.active = null
    mutate()
  }

  const hidden = parent.state.active !== 'album' || state.active
  useEffect(() => {
    if (hidden) return
    parent.state.previous = previous
    if (state.init) return
    state.init = true
    fetchAlbums({ parent, state, mutate })
  }, [hidden])

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    fetchAlbums({ parent, state, mutate })
  })

  const selectAlbum = album => {
    parent.state.childTitle = album.name
    parent.state.showPreviousBtn = true
    parent.mutate()
    state.album = album
    state.active = 'all'
    state.type = 'album'
    state.id = album.id
    mutate()
  }

  const renderPhotos = () => {
    if (!state.active) return
    return (
      <Photos
        state={ state }
        mutate={ mutate }
        className={ parent.className }
      />
    )
  }

  const renderLoading = () => {
    if (state.fetching) return <div className='py-5'><C.Loading /></div>
    if (state.albums.length) return
    return <div className='py-5 text-gray-600 text-center'>{ t('panel:collection.noAlbum') }</div>
  }

  return (
    <>
      <div
        className={ cn('overflow-scroll', parent.className, { hidden }) }
        onScroll={ onScroll() }
      >
        <div className='flex flex-wrap mx-1'>
          { state.albums.map(album => (
            <Album
              key={ album.id }
              album={ album }
              selectAlbum={ selectAlbum }
            />
          )) }
        </div>
        { renderLoading() }
      </div>
      { renderPhotos() }
    </>
  )
}

const Album = ({ album, selectAlbum }) => (
  <div onClick={ () => selectAlbum(album) } className='w-1/2 sm:w-1/3 p-1 text-gray-700 text-xs cursor-pointer'>
    <div className='bg-white shadow'>
      <div className='py-1 px-2 truncate'>{ album.name }</div>
      { album.coverUrl && (
        <C.Image
          src={ album.coverUrl }
          className='pt-[100%]'
        />
      ) }
      { !album.coverUrl && <div className='pt-[100%] bg-gray-100'></div> }
      <div className='py-1 px-2 text-right'>
        { album.postNum } 篇作品
      </div>
    </div>
  </div>
)

const Photos = ({ state, mutate, className }) => (
  <>
    { !!state.album.description &&
    <div className='p-3 text-gray-700 text-sm shadow'>
      <p { ...state.album.description.rawHtml() }/>
    </div>
    }
    <All state={ state } mutate={ mutate } className={ className } />
  </>
)

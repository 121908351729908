import Button from './button'
import FacebookIcon from '@mui/icons-material/Facebook'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'

export default function Login() {
  const { useStepLogin } = useCtx('panelAuth')

  const {
    mode,
    toggleMode,
    facebookLogin,
    phoneCodes,
    form,
    formLogin
  } = useStepLogin()

  const t = useI18n()
  const LoginForm = fetchLoginForm({ mode })

  return (
    <div>
      <div className='pt-3'>
        <LoginForm
          phoneCodes={ phoneCodes }
          form={ form }
          formLogin={ formLogin }
          t={ t }
        />
      </div>
      <div className='my-10 relative'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-300'></div>
        </div>
        <div className='relative flex justify-center text-sm'>
          <span className='px-2 bg-white text-gray-500'>
            { t('panel:auth.or') }
          </span>
        </div>
      </div>

      <div>
        <Button uid='auth.facebook'
          variant='outlined'
          onClick={ facebookLogin }
        >
          <FacebookIcon fontSize='small' className='absolute left-3'/>
          { t('panel:auth.facebookLogin') }
        </Button>

        <div className='relative hover:bg-gray-50' >
          <Button uid='auth.google'
            variant='outlined'
          >
            <div className='w-4 h-4 absolute left-3'>
              <C.Image alt='Google login'
                type='contain'
                className='w-4 h-4'
                src={ cdn('google_login.png') }
              />
            </div>
            { t('panel:auth.googleLogin') }
          </Button>
          <div className='opacity-[0.01] z-10 absolute top-0 left-0 right-0 bottom-0' id='googleSignIn'/>
        </div>

        <ToggleModeButton
          mode={ mode }
          toggleMode={ toggleMode }
          t={ t }
        />
      </div>
    </div>
  )
}

const fetchLoginForm = ({ mode }) => {
  if (mode === 'phone') return PhoneForm
  return EmailForm
}

const ToggleModeButton = ({ mode, toggleMode }) => {
  const config = {
    email: {
      Icon: PhoneIphoneIcon,
      text: 'panel:auth.phoneLogin'
    },
    phone: {
      Icon: MailOutlineIcon,
      text: 'panel:auth.emailLogin'
    }
  }
  const t = useI18n()

  const { Icon, text } = config[mode]

  return (
    <Button variant='outlined' onClick={ toggleMode }>
      <Icon fontSize='smail' className='absolute left-3'/>
      { t(text) }
    </Button>
  )
}

const PhoneForm = ({ phoneCodes, form, formLogin, t }) => (
  <>
    <C.ComboBoxForm
      center
      form={ form }
      field='phoneCode'
      disableClearable
      options={ phoneCodes }
      label={ t('panel:auth.location') }
    />
    <C.InputForm
      label={ t('panel:auth.phone') }
      form={ form }
      field='phone'
      onEnter={ formLogin }
      fullWidth
      center
    />
    <Button uid='auth.login'
      variant='main'
      check={ form }
      onClick={ formLogin }
    >
      { t('panel:auth.continue')}
    </Button>
  </>
)
const EmailForm = ({ form, formLogin, t }) => (
  <>
    <C.InputForm
      type='email'
      label={ t('panel:auth.email') }
      form={ form }
      field='email'
      fullWidth
      center
    />
    <C.InputForm
      type='password'
      label={ t('panel:auth.password') }
      form={ form }
      field='password'
      fullWidth
      center
    />
    <Button uid='auth.login'
      variant='main'
      check={ form }
      onClick={ formLogin }
    >
      { t('panel:auth.continue') }
    </Button>
  </>
)

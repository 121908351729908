const template = `
  query BookingCancelReasons($bookingId: ID!){
    bookingCancelReasons(bookingId: $bookingId) {
      id
      reason
    }
  }
`

export default async function bookingCancelReasons(bookingId) {
  const { bookingCancelReasons } = await request(template, { bookingId })
  return bookingCancelReasons
}

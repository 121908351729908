import fetchBookings from './fetch-bookings'
import useBookingFormatter from 'hooks/booking-formatter'

export default function BookingRecords() {
  const t = useI18n()
  const { state, mutate } = useStatic({
    visible: false,
    bookings: [],
    fetching: true,
    finish: false,
    isAdmin: false,
    userId: 0
  })
  const bookingFormatter = useBookingFormatter(true)

  h.openBookingRecords = (userId, isAdmin) => {
    state.visible = true
    state.userId = userId
    state.isAdmin = isAdmin
    refresh()
  }

  const refresh = () => {
    state.bookings = []
    state.fetching = true
    state.finish = false
    fetchBookings({ state, mutate })
  }

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    fetchBookings({ state, mutate })
  })

  const refreshBooking = booking => {
    booking.isCommented = true
    mutate()
  }
  const updateBooking = booking => {
    for (let i = 0; i < state.bookings.length; i += 1) {
      if (state.bookings[i].id !== booking.id) continue
      state.bookings[i] = booking
      mutate()
      break
    }
  }
  const openBooking = booking => {
    if (state.isAdmin){
      $openStylistBooking({ bookingId: booking.id, callback: updateBooking })
      return
    }
    h.openUserBooking(booking.id, updateBooking)
  }
  const openCreateReview = booking => h.openStylistReviewCreate({
    bookingId: booking.id,
    callback: () => refreshBooking(booking)
  })

  const renderBody = () => state.bookings.map(booking => {
    bookingFormatter(booking)
    return <div key={ booking.id } className='select-none border-b p-2'>
      <div className='flex w-full flex-col sm:flex-row sm:items-center sm:justify-between sm:space-x-3'>
        <div className='flex-1 text-gray-800 space-y-[0.1rem]'>
          <div className='line-clamp-1 font-medium'>{ booking.$timeText }</div>
          <div className='line-clamp-1 text-sm'>{ t('panel:bookingRecords.item') }: { booking.$serviceText }</div>
          <div className='line-clamp-1 text-sm'>{ t('panel:bookingRecords.stylist') }: <C.Link href={ h.path.stylist({ id: booking.stylist.user.id }) } blank>{ booking.stylist.user.name }</C.Link></div>
          <div className='line-clamp-1 text-sm'>{ t('panel:bookingRecords.state') }: <span className={ booking.$stateColor }>{ booking.$i18nState }</span> </div>
        </div>
        <div className='flex space-x-3 shrink-0 py-3 sm:w-1/3 sm:flex-col sm:space-y-2 sm:space-x-0'>
          <C.Button className='sm:max-w-[8rem] flex-1'
            onClick={ () => openBooking(booking) }>
            { t('panel:bookingRecords.details') }
          </C.Button>
          <C.Button variant='outlined' className='sm:max-w-[8rem] flex-1' disabled={ booking.isCommented }
            onClick={ () => openCreateReview(booking) }>
            { t('panel:bookingRecords.addReview') }
          </C.Button>
        </div>
      </div>
    </div>
  })

  const renderLoading = () => {
    if (state.fetching) return <div className='py-5'><C.Loading /></div>
    if (state.bookings.length) return
    return <div className='py-5 text-center text-gray-500'>{ t('panel:bookingRecords.noResult') }</div>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ () => mutate({ visible: false }) }
      noPadding
    >
      <C.ModalHeader title={ t('common:myBooking') } >
        <div className='p-5 min-h-[20rem] overflow-scroll' onScroll={ onScroll() }>
          { renderBody() }
          { renderLoading() }
        </div>
        <C.Blur />
      </C.ModalHeader>
    </C.Modal>
  )
}
const template = `
  query Config($prepayAmount: Int!, $stylistId: ID!) {
    prepayPolicy(prepayAmount: $prepayAmount) {
      title
      content
    }
    depositConfig(stylistId: $stylistId) {
      remainDeposit
    }
  }
`

export default async function config({ prepayAmount, stylistId }) {
  const { prepayPolicy, depositConfig } = await request(template, { prepayAmount, stylistId })
  return { prepayPolicy, depositConfig }
}
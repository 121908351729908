import bookingCreateRequest from 'request/prepay-booking/create'
import usePayByPrime from 'hooks/payment-method/pay-by-prime'

export default function usePrepayBooking({ user, state, callback }) {
  const t = useI18n()
  const { fetchPrime, paymentUrlCallback } = usePayByPrime()

  return async() => {
    const {
      stylistUserId,
      startTime,
      duration,
      service,
      isModel,
      applyFormId,
      others,
      minPayment,
      prepayAmount,
      paymentMethod,
      paymentMethodParams,
      finalPayment,
      notes
    } = state

    mkt.prepayBookingClick()

    if (user.id === stylistUserId) return $alert(t('panel:prepayBooking.notAvailable'), 'error')
    if (!validateInput({ finalPayment, paymentMethod, t })) return

    const { name, phone, gender } = user

    const successCallback = () => {
      $alert(t('panel:prepayBooking.success'))
      callback(stylistUserId)
      mkt.prepayBooking({ stylistUserId, amount: prepayAmount, user })
    }
    const atmCallback = senderId => {
      callback(senderId)
      mkt.prepayBooking({ stylistUserId, amount: prepayAmount, user })
    }

    const onPaymentUrl = ({ paymentUrl, method }) => {
      if (!paymentUrl) return $alert('交易失敗', 'error')
      mkt.prepayBooking({ stylistUserId, amount: prepayAmount, user })
      if (method === 'zerocard') {
        window.location = paymentUrl
        return
      }
      paymentUrlCallback({ method, paymentUrl })
    }

    const endTime = startTime.add(duration, 'minute').dateTime()
    const discountId = discountIdResolver(service)
    const services = servicesResolver(service, others)
    const customer = { name, phone, gender }
    const input = {
      stylistUserId,
      minPayment,
      prepayAmount,
      payAmount: finalPayment,
      startTime: startTime.dateTime(),
      endTime,
      discountId,
      services,
      isModel,
      applyFormId,
      customer,
      payMethod: paymentMethod || 'atm',
      payMethodParams: paymentMethodParams,
      source: 'web',
      notes
    }

    const result = await fetchPrime({ method: paymentMethod, amount: finalPayment })
    if (result?.prime) {
      input.payMethod = result.method
      input.payMethodParams.prime = result.prime
    }

    try {
      await bookingCreateRequest(input)
      successCallback()
    } catch (error) {
      switch (error.code) {
        case 'payment.remitBankAccount':
          return atmCallback(error.params.sender.id)
        case 'payment.redirectTocallbackUrl':
          return onPaymentUrl({
            paymentUrl: error.params.paymentUrl,
            method: input.payMethod
          })
        default:
          throw error
      }
    }
  }
}

const validateInput = ({ finalPayment, paymentMethod, t }) => {
  if (!finalPayment) return true
  if (paymentMethod) return true
  $alert(t('page:common.needMethod'), 'error')
  return false
}

const discountIdResolver = item => {
  if (item.$type === 'discount') return item.id
  return 0
}

const servicesResolver = (item, others) => {
  const othersInput = others.map(s => serviceInputResolver(s))
  if (item.$type !== 'discount') return [serviceInputResolver(item), ...othersInput]
  const serviceInput = item.serviceList.map(s => ({ id: parseInt(s.id), price: 0 }))
  return [...serviceInput, ...othersInput]
}

const serviceInputResolver = item => {
  const price = item.minPrice > 0 ? item.minPrice : 0
  return { id: parseInt(item.service.id), price }
}

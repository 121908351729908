import Detail from './panel/detail'
import myVendorProductReceiptRequest from 'request/my/vendor-product-receipt'

export default function VendorProductReceiptRecords({ userId }) {
  const t = useI18n()
  const { state, mutate } = useStatic({
    records: [],
    fetching: true
  })

  useEffect(async() => {
    state.records = await myVendorProductReceiptRequest({ userId })
    state.fetching = false
    mutate()
  }, [])

  const detailClick = (orderId, buyerInfo) => {
    h.openVendorProductReceiptDetail(orderId, userId, buyerInfo)
  }

  const renderLoading = () => {
    if (state.fetching) return <div className='py-5'><C.Loading /></div>
    if (state.records.length) return
    return <div className='py-5 text-center text-gray-600'>{ t('page:vendorProductReceipts.noRecords') }</div>
  }

  return (
    <>
      <Row
        className='font-bold text-sm px-5'
        values={ [
          t('page:vendorProductReceipts.date'),
          t('page:vendorProductReceipts.amount'),
          t('page:vendorProductReceipts.detail')
        ] }
      />
      <Hr />
      <div className='flex-1 overflow-scroll text-sm pb-5 px-5'>
        { state.records.map(({ id, amount, orderBuyerInfo, checkoutAt }) => (
          <Row
            key={ id }
            className='font-medium'
            values={ [
              checkoutAt.ymd(),
              amount.currency(),
              <div key='detail' onClick={ () => detailClick(id, orderBuyerInfo) } className='border-gray-500 border-[0.125rem] rounded-[4px] px-[5px] py-[1px] cursor-pointer text-gray-600'>
                <Icon.Detail className='w-4 h-4 text-gray-500'/>
              </div>
            ] }
          />
        )) }
        { renderLoading() }
      </div>
      <Detail />
    </>
  )
}

const Row = ({ values, className }) => (
  <div className={ cn('flex space-x-3 items-center py-5', className) }>
    <div className='flex-1'>{ values[0] }</div>
    <div className='flex-1 text-center'>{ values[1] }</div>
    <div className='min-w-[15%] flex justify-end text-right'>{ values[2] }</div>
  </div>
)

const Hr = () => <div className='border-b'></div>

import Schedule from 'components/common/schedule'

export default function Time({ state, selectTimeSlot }){
  const {
    discountConfig,
    startTime,
    service,
    others,
    duration
  } = state
  const { stylistUser } = discountConfig

  const serviceIdsResolver = () => {
    const serviceId = parseInt(service.service.id)
    const addServiceIds = others.map(o => parseInt(o.service.id))
    return [serviceId, ...addServiceIds]
  }
  const serviceIds = serviceIdsResolver()

  return (
    <div>
      <Schedule
        startAt={ startTime }
        userId={ stylistUser.id }
        duration={ duration }
        serviceIds={ serviceIds }
        isEvent={ true }
        onClick={ selectTimeSlot }
      />
    </div>
  )
}

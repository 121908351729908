const template = `
  mutation PostCreate($tagCategory: TagCategory, $albumId: ID, $description: String!, $tagIds: [Int], $privacy: PostPrivacy!, $images: [String!], $beforePhoto: String){
    postCreate(input: {
      tagCategory: $tagCategory
      albumId: $albumId
      description: $description
      tagIds: $tagIds
      privacy: $privacy
      images: $images
      beforePhoto: $beforePhoto
    })
  }
`

export default async function postCreate({ tagCategory, albumId, description, tagIds, privacy, images, beforePhoto }) {
  await request(template, { tagCategory, albumId, description, tagIds, privacy, images, beforePhoto })
}

export default function EventRule() {
  const { user } = useCurrent()
  const { open } = usePanel()
  const { state, mutate } = useStatic({ visible: false })

  h.openEventRule = () => {
    state.visible = true
    mkt.eventRuleClick()
    mutate()
  }

  const buyDepositClick = () => {
    if (!user) return open('auth')
    h.openBuyDeposit({
      stylistId: 36,
      stylistUserId: 1,
      stylistName: 'StyleMap美配',
      isStylistEvent: true
    })
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body buyDepositClick={ buyDepositClick }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ '活動辦法' }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({ buyDepositClick }) => (
  <div className='overflow-auto p-5 font-light text-gray-600'>
    <div className='text-sm pb-3'>
      依據設計師的活動報名人數，開放不同的優惠方案:
    </div>
    <div className='min-w-fit border text-center text-[0.76rem]'>
      <div className='bg-gray-200 font-normal'>
        <Row>
          <div key='0' className='w-16'>報名人數</div>
          <Col key='1'>3人以下</Col>
          <Col key='2'>4 ~ 6人</Col>
          <Col key='3'>6人以上</Col>
        </Row>
      </div>
      <div className='bg-gray-50'>
        <Row>
          <div key='0' className='w-16 font-normal'>首次預約</div>
          <Col key='1'>洗剪3折<br/>指定項目6折</Col>
          <Col key='2'>洗剪5折<br/>指定項目7折</Col>
          <Col key='3'>洗剪7折<br/>指定項目8折</Col>
        </Row>
        <Row>
          <div key='0' className='w-16 font-normal'>首次體驗</div>
          <Col key='1'>洗剪5折<br/>指定項目7折</Col>
          <Col key='2'>洗剪7折<br/>指定項目8折</Col>
          <Col key='3'>洗剪9折<br/>指定項目9折</Col>
        </Row>
      </div>
    </div>

    <div className='py-5 space-y-1 text-sm'>
      <div><Title>報名人數:</Title>未來兩週內該名設計師的活動報名人數</div>
      <div><Title>首次預約:</Title>首次透過 StyleMap 進行預約</div>
      <div><Title>首次體驗:</Title>首次在平台上預約該名設計師</div>
      <div><Title>指定項目:</Title>染髮 / 燙髮 / 美甲</div>
    </div>

    <div className='py-5 text-sm'>
      ＊報名活動需預付訂金，支援多元付款方式如： 儲值金、信用卡、簽帳金融卡、Apple Pay、Google Pay、LINE Pay、全盈+Pay、零卡分期。
      如欲使用 ATM 轉帳，請先<span className='underline mx-1 font-normal cursor-pointer' onClick={ buyDepositClick }>點此購買儲值金</span>再進行預約。
    </div>

    <C.Blur/>
  </div>
)
const Row = ({ children }) => <div className='flex items-center p-3 space-x-3'>{ children }</div>
const Col = ({ children }) => <div className='flex-1 min-w-24'>{ children }</div>
const Title = ({ children }) => <span className='font-normal mr-2'>{ children }</span>
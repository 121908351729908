export default function SinglePhoto() {
  const [src, setSrc] = useState(null)
  const [style, setStyle] = useState({ width: 0, height: 0 })
  const panel = usePanel()

  h.$singlePhoto = src => {
    setSrc(src)
    setStyle({ width: 0, height: 0 })
    mkt.photoClick()
    panel.open('singlePhoto')
  }

  const renderLoading = () => {
    if (style.width) return ''
    return <C.Loading size={ 40 } color='white' />
  }

  const scale = () => {
    if (!style.width) return 'scale-0'
    return 'scale-100'
  }

  const opacity = () => {
    if (!style.width) return 'opacity-0'
    return 'opacity-100'
  }

  const renderImage = () => {
    if (!src) return
    return (
      <div className='flex items-center justify-center max-w-full max-h-full'
        onClick={ () => panel.close('singlePhoto') }
      >
        { renderLoading() }
        <div
          className={ cn('transition max-w-full max-h-full duration-200', scale(), opacity()) }
          style={ {
            width: style.width + 'px',
            height: style.height + 'px'
          } }
        >
          <C.Image
            src={ src }
            className='w-full h-full'
            downloadable
            type='contain'
            onLoad={ ({ width, height }) => {
              setStyle({ width, height })
            } }
            noLoading
          />
        </div>
      </div>
    )
  }
  return (
    <C.Panel
      noPadding
      noRounded
      noBgColor
      noCloseBtn
      fullHeightCenter
      bgMask='backdrop-blur bg-black/70'
      panel='singlePhoto'
    >
      { renderImage() }
    </C.Panel>
  )
}

export default function useFacebookLogin({ login }) {
  const { setLoading } = useLoading()
  const t = useI18n()

  return () => {
    if (h.isEmbedBrowser() && !confirm(t('common:warning.embedBrowserFBLoginWillFail'))) return
    FB.login(async response => {
      if (response.status !== 'connected') return
      const token = response.authResponse.accessToken
      setLoading('auth.facebook')
      await login({
        type: 'facebook',
        key: token
      })
      try {
        FB.logout()
      } catch {
        console.log('fb logout with error')
      }
      setLoading(null)
    }, { scope: 'public_profile, email, user_friends, user_gender' })
  }
}

const template = `
  mutation InvalidateUser {
    userInvalidateSelf
    logout
  }
`

export default async function userInvalidateSelf() {
  await request(template)
}

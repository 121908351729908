import Title from './title'
import StarRateIcon from '@mui/icons-material/StarRate'

export default function StudioInfo({ studio }) {
  const t = useI18n()
  const openGallery = index => {
    const srcs = studio.studioPhotos.map(({ url }) => url.large)
    h.openGallery(srcs, index)
  }

  return (
    <div>
      <div className='container'>
        <Title>
          { t('panel:eventStylist.studio') }
        </Title>
      </div>
      <div className='container pt-1'>
        <div className='flex items-center space-x-5'>
          <div className='flex items-start'>
            <C.Image
              className='w-16 h-16 rounded-full shadow shrink-0'
              src={ studio.logoUrl }
            />
          </div>
          <div className='font-bold flex-1'>
            <div className='flex items-center space-x-1'>
              <div className='line-clamp-1'>{ studio.name }</div>
            </div>
            <div className='text-gray-500'>
              <div className='flex items-center'>
                <span className='text-yellow-400 -translate-y-0.5'>
                  <StarRateIcon fontSize='inherit' />
                </span>
                <span className='text-xs'>
                  { studio.$rating } ({ t('page:userId.ratingCount', { count: studio.$ratingCount }) })
                </span>
              </div>
            </div>

          </div>
        </div>

        <div className='font-normal tracking-widest py-3'>
          <RowAddress iconKey='locationF' content={ studio.address || 'NA' } />
          <Row iconKey='officeF' content={ studio.traffic || 'NA' } />
        </div>

        <div className='flex flex-wrap pb-3'>
          { studio.studioPhotos.map(({ url }, index) => (
            <Photo
              key={ url.small }
              studio={ studio }
              photo={ url }
              onClick={ () => openGallery(index) }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const Photo = ({ studio, photo, onClick }) => (
  <div className='w-1/5 p-[2px] cursor-pointer' onClick={ onClick }>
    <C.Image
      src={ photo.small }
      className='pt-[100%]'
      alt={ studio.name }
    />
  </div>
)

const Row = ({ content, iconKey }) => (
  <div className='flex space-x-2 items-start py-2'>
    <div className='text-gray-400'>{ icon(iconKey) }</div>
    <div className='text-sm font-medium' { ...content.rawHtml() }/>
  </div>
)

const RowAddress = ({ content, iconKey }) => {
  const t = useI18n()
  return (
    <div className='flex space-x-2 items-start py-2'>
      <div className='text-gray-400'>{ icon(iconKey) }</div>
      <div className='text-sm font-medium'>
        { content }
      </div>
      <div className='shrink-0 text-xs pt-[0.18rem] font-bold underline'>
        { h.mapUrl(t('common:openMap'), content) }
      </div>
    </div>
  )
}
import Login from './login'
import Register from './register'
import PhoneCode from './phone-code'
import { Context, useStore } from 'store/panel-auth'

export default function Auth() {
  return (
    <Context.Provider value={ useStore() }>
      <RenderPanel />
    </Context.Provider>
  )
}

const dic = {
  login: Login,
  register: Register,
  phoneCode: PhoneCode
}

const RenderPanel = () => {
  const { step, onClose, previousAble, toPreviousStep, subtitle } = useCtx('panelAuth')
  const Component = dic[step]
  return (
    <C.Panel
      panel='auth'
      noPadding
      onClose={ onClose }
      noCloseBtn={ previousAble }
    >
      <C.ButtonIcon
        className='absolute text-gray-400 pt-2'
        visible={ previousAble }
        onClick={ toPreviousStep }
      >
        <Icon.ChevronLeft />
      </C.ButtonIcon>
      <div className='px-10 py-6 w-auto sm:w-[30rem] text-gray-600 select-none flex flex-col'>
        <div className='text-5xl font-serif text-center tracking-wider'>StyleMap</div>
        <div className='py-2 text-center font-bold text-sm text-gray-500'
          dangerouslySetInnerHTML={ { __html: subtitle } }
        />
        <Component />
      </div>
    </C.Panel>
  )
}

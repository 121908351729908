import useLogin from './action/login'
import useRegister from './action/register'

export default function useStoreState() {
  const { state, mutate } = useStatic({
    step: 'login',
    previousSteps: [],
    isOldUser: false,
    phone: '',
    action: null,
    codeExpiredAt: null,
    resendPhoneCode: null,
    registerForm: {}
  })

  const t = useI18n()
  const { refreshCurrent } = useCurrent()
  const panel = usePanel()

  const onClose = () => {
    mutate({
      step: 'login',
      previousSteps: []
    })
  }

  const subtitleKey = () => {
    if (state.step === 'login') return 'panel:auth.loginOrRegister'
    if (state.step === 'phoneCode') return 'panel:auth.pleaseEnterPhoneCode'
    if (state.isOldUser) return 'panel:auth.continuePhoneValidate'
    return 'common:register'
  }

  const subtitle = t(subtitleKey(), { phone: state.phone })

  const previousAble = !!state.previousSteps.length

  const toPreviousStep = () => {
    mutate({
      step: state.previousSteps.at(-1),
      previousSteps: state.previousSteps.slice(0, -1)
    })
  }

  const toStep = nextStep => {
    if (nextStep === 'phoneCode') mkt.authPhoneCode()
    mutate({
      step: nextStep,
      previousSteps: [...state.previousSteps, state.step]
    })
  }

  const loginSuccess = ({ name }) => {
    $alert(t('panel:auth.welcomeBack', { name }))
    refreshCurrent()
    panel.close('auth')
    onClose()
  }
  const login = useLogin({
    onClose,
    toStep,
    loginSuccess,
    mutate
  })

  const register = useRegister({
    mutate,
    loginSuccess,
    toStep
  })

  return {
    state,
    mutate,
    login,
    register,
    toPreviousStep,
    toStep,
    onClose,
    previousAble,
    subtitle
  }
}

import { PhotoWithLike } from 'facades'
import fetchPhotos from './fetch-photos'

export default function All(parent) {
  const { state, mutate } = useStatic({
    photos: [],
    offset: 0,
    finish: false,
    fetching: true,
    init: false
  })

  const hidden = parent.state.active !== 'all'
  useEffect(() => {
    if (hidden) return
    if (state.init) return
    state.init = true
    fetchPhotos({ parent, state, mutate })
  }, [hidden])

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    fetchPhotos({ parent, state, mutate })
  })

  return (
    <div
      className={ cn('overflow-scroll overflow-x-hidden', parent.className, { hidden }) }
      onScroll={ onScroll() }
    >
      <div className='flex flex-wrap -mx-[1px]'>
        {state.photos.map(photo => (

          <PhotoWithLike
            key={ photo.key }
            width='w-1/3 p-[0.5px]'
            photo={ photo }
          />
        ))}
      </div>
      {state.fetching && <div className='py-5'><C.Loading /></div>}
    </div>
  )
}

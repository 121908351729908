import TextField from '@mui/material/TextField'

export default function Prepay({ state }) {
  const t = useI18n()
  const {
    bookingDetails,
    userDetails,
    paymentDetails,
    subtotal,
    finalPayment,
    prepayPolicy
  } = state

  const openPaymentMethod = () => {
    const source = stateToSource(state)
    h.openPaymentMethod({
      type: 'prepayBooking',
      path: window.location.pathname,
      source
    }, 'prepayBooking', true)
  }

  const notesChange = event => {
    state.notes = event.target.value
  }

  return (
    <div className='space-y-5'>
      <C.PaymentDetail title={ t('panel:prepayBooking.detail.book') } items={ bookingDetails } />
      <C.PaymentDetail title={ t('panel:prepayBooking.detail.user') } items={ userDetails } />
      <RemarkBlock notesChange={ notesChange } t={ t } />
      <C.PaymentDetail title={ t('panel:prepayBooking.detail.payment') } items={ paymentDetails } />
      { finalPayment > 0 && (
        <C.PaymentSelect
          type='prepayBooking'
          openPaymentMethod={ openPaymentMethod }
          amount={ subtotal }
          isStylistEvent={ true }
        />
      )}
      <C.PaymentPolicy title={ t('panel:prepayBooking.detail.policy') } policy={ prepayPolicy } />
    </div>
  )
}

const RemarkBlock = ({ notesChange, t }) => (
  <div className='pb-2'>
    <div className='font-bold'>{ t('panel:prepayBooking.detail.remark') }</div>
    <div className='py-3'>
      <TextField
        fullWidth
        multiline
        placeholder={ t('panel:prepayBooking.detail.option') }
        onChange={ notesChange }
      />
    </div>
  </div>
)

const stateToSource = state => {
  const {
    service,
    others,
    startTime,
    stylistId
  } = state

  return {
    serviceKey: service.id,
    otherIds: others.map(o => o.id),
    startTime: startTime.dateTime(),
    stylistId,
    isEvent: true
  }
}
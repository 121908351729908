const template = `
query StylistEventDiscountRatio($stylistId: ID!) {
    stylistEventDiscountRatio(stylistId: $stylistId) {
      usedQuota
      discount
      haircutRatio
      othersRatio
      isFirstTimeBooking
      stylistUser {
        id
        name
      }
      services {
        id
        service {
          id
          name
          isHairCutAndWash
        }
        minPrice
        maxPrice
        serviceTime
        description
        hairBrands {
          id
          name
          url
        }
        customMinPayment
        prepayAmount
      }
    }
  }
`

export default async function stylistEventDiscountRatio(stylistId) {
  const { stylistEventDiscountRatio } = await request(template, { stylistId })
  return stylistEventDiscountRatio
}

export default function Btn({ ...props }) {
  return (
    <div className='my-6'>
      <C.Button
        fullWidth
        { ...props }
      />
    </div>
  )
}

export default function formatter({ booking, bookingStateDic, discountLimits, t }) {

  const stateResolver = bookingState => {
    if (booking.payByMapPay) return 'paid'
    if (bookingState === 2 && booking.realPayment > 0) return 'notPaid'
    if (bookingState == 3) return 'cancel'
    return bookingStateDic[bookingState] || 'unknown'
  }
  const stateColor = bookingState => {
    switch (bookingState) {
      case 'checked':
        return 'text-green-500'
      case 'notPaid':
        return 'text-red-400'
      case 'paid':
        return 'text-black'
      default:
        return 'text-gray-600'
    }
  }
  const prepareStylistDetails = () => {
    const { state, startTime, endTime } = booking
    booking.$serviceDate = startTime.ymdDash()
    booking.$serviceTime = `${ startTime.hm() } - ${ endTime.hm() }`

    const bookingState = stateResolver(state)
    booking.$i18nState = t(`common:booking.state.${ bookingState }`)
    booking.$stateColor = stateColor(bookingState)
  }
  prepareStylistDetails()

  const prepareStudioDetails = () => {
    const studio = booking.stylist.studio
    if (!studio) return []
    return [
      [t('panel:userBooking.studioName'), studio.name],
      [t('panel:userBooking.studioAddress'), studio.address]
    ]
  }
  booking.$studioDetails = prepareStudioDetails()

  booking.$serviceDetails = booking.services.map(service => {
    const price = booking.state === 2 ? service.finalPrice.currency() : ''
    return [service.service.name, price]
  })
  booking.$serviceTitle = booking.state === 2 ? t('panel:userBooking.buyService') : t('panel:userBooking.bookService')
  booking.$productDetails = booking.products.map(product => [product.name, `${product.finalPrice.currency()} x ${product.quantity}`])

  const prepareDiscount = () => {
    let { discount } = booking
    if (!discount) return
    const { name, originalMinPrice, originalMaxPrice } = discount
    discount.$title = name
    discount.$originalRange = `${ t('common:originalPrice') }: ${ getPriceRangeText(originalMinPrice, originalMaxPrice, t) }`
    discount.$range = getDiscountPrice(discount, t)
    discount.$remark = getDiscountRemark(discount, discountLimits, t)
  }
  prepareDiscount()
}

const getDiscountRemark = (discount, discountLimits, t) => {
  const limit = getDiscountLimit(discount, discountLimits, t)
  const deadline = getDiscountDeadline(discount.deadline, t)
  if (!deadline) return limit
  return `${limit}\n${deadline}`
}

const getDiscountLimit = (discount, discountLimits, t) => {
  const { limitCustomer, limitDay, limitTimeSlot } = discount
  if (limitCustomer === 0) return ''
  const key = discountLimits[limitCustomer]
  const i18nPath = 'common:discount.limit'
  if (limitCustomer === 5) return t(`${i18nPath}.${key}`, { day: limitDay })
  if (limitCustomer !== 6) return t(`${i18nPath}.${key}`, { time: limitTimeSlot.slotToTime() })
  if (limitCustomer === 7) return t(`${i18nPath}.${key}`, { time: limitTimeSlot.slotToTime() })
  return t(`${i18nPath}.${key}`)
}

const getDiscountDeadline = (deadline, t) => {
  if (deadline === 2147483647) return ''
  const date = dayjs(deadline * 1000).format('YYYY/MM/DD')
  return t('common:discount:effectiveUntil', { date })
}

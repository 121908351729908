import ReceiptRecords from 'components/common/vendor-product-receipt'

export default function VendorReceipts() {
  const { state, mutate } = useStatic({
    visible: false,
    receipts: [],
    fetching: true,
    finish: false,
    userId: 0
  })

  h.openVendorReceipts = async({ userId }) => {
    state.visible = true
    state.userId = userId
    mutate()
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ () => mutate({ visible: false }) }
      noPadding
    >
      <C.ModalHeader title= '採購紀錄' >
        <ReceiptRecords userId={ state.userId } />
        <C.Blur />
      </C.ModalHeader>
    </C.Modal>
  )
}
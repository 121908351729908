import Title from './title'

export default function Reviews({ reviews, profile, openReview }) {
  const t = useI18n()
  if (reviews?.length < 3 || !profile) return ''
  const reviewsView = reviews.map(review => <Review key={ review.id } review={ review } t={ t }/>)

  return (
    <div className='container relative'>
      <Title>
        { t('page:userId.review') }
        <span className='text-xs text-gray-500 px-1'>({ profile.stylist.$ratingCount })</span>
      </Title>
      <div className='pt-2'/>
      <div className='space-y-4'>
        { reviewsView }
      </div>
      <C.Blur>
        <div
          className='text-center font-bold cursor-pointer md:text-[1.05rem]'
          onClick={ openReview }
        >
          { t('page:userId.moreReview') }
        </div>
      </C.Blur>
    </div>
  )
}

const Review = ({ review, t }) => {
  const renderService = () => {
    if (!review.$serviceText) return
    return (
      <div className='text-sm text-gray-400'>
        { t('panel:review.bookService') }: { review.$serviceText }
      </div>
    )
  }
  const renderPhoto = () => {
    if (!review.photos.length) return
    return (
      <div className='flex space-x-2 pt-2 ml-2'>
        { review.photos.map((photo, idx) => (
          <div key={ idx } className='cursor-pointer' onClick={ () => h.openGallery(review.$largePhotos, idx) }>
            <C.Image className='w-10 h-10' src={ photo.url.small } alt=''/>
          </div>
        )) }
      </div>
    )
  }
  return (
    <div>
      <div className='flex items-center'>
        <C.Image
          src={ review.user.avatar }
          className='w-9 h-9 rounded-full'
        />
        <div className='text-xs pl-2'>
          <div className='font-bold'>{ review.$reviewerName }</div>
          <div className='text-gray-500 pt-[0.1rem]'>
            <C.FromNow time={ review.createdAt } />
          </div>
        </div>
        <div className='flex-1'></div>
        <C.Stars score={ review.$rating } className='w-4 h-4'/>
      </div>
      <p className='text-sm py-2' { ...review.review.rawHtml() } />
      { renderService() }
      { renderPhoto() }
    </div>
  )
}

export default function QRCode() {
  const { state, mutate } = useStatic({ visible: false, title: '', code: '' })

  h.openQRCode = ({ title, code }) => {
    state.title = title
    state.code = code
    state.visible = true
    mutate()
    return closePanel
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body title={ state.title } code={ state.code }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      { renderBody() }
    </C.Modal>
  )
}

const Body = ({ title, code }) => (
  <div className='sm:min-w-[22rem] flex flex-col'>
    <div className='text-center py-4 flex-shrink-0'>{ title }</div>
    <div className='p-5 flex justify-center'>
      <C.QRCode string={ code } className='w-[20rem] h-[20rem]'/>
    </div>
  </div>
)

const template = `
  query MyDeposits {
    me {
      deposits {
        id
        amount
        coupon
        stylist {
          user {
            id
            name
          }
        }
      }
    }
  }
`

export default async function myDeposits() {
  const { me } = await request(template)
  return me.deposits
}

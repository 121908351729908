const template = `
  mutation TagCreate($name: String!, $category: TagCategory) {
    tagCreate(name: $name, category: $category) {
      id
      name
    }
  }
`

export default async function tagCreate(name, category) {
  const { tagCreate } = await request(template, { name, category })
  return tagCreate
}

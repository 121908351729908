import Auth from './auth'
import Alert from './alert'
import PhoneUpdate from './phone-update'
import PaymentMethod from './payment-method'
import CreateCreditCard from './create-credit-card'
import PrepayBooking from './prepay-booking'
import UserBooking from './user-booking'
import StylistBooking from './stylist-booking'
import BuyDeposit from './buy-deposit'
import BuyCourse from './buy-course'
import StylistReviewCreate from './stylist-review-create'
import StylistCustomerCreate from './stylist-customer-create'
import ContactUs from './contact-us'
import SinglePhoto from './single-photo'
import Review from './review'
import Gallery from './gallery'
import Post from './post'
import PostCreate from './post-create'
import Collection from './collection'
import SwitchAccount from './switch-account'
import Search from './search'
import CardSetting from './card-setting'
import BookingRecords from './booking-records'
import AccountSetting from './account-setting'
import TagCreate from './tag-create'
import Deposits from './deposit'
import RouterQuery from './route-query'
import GuaranteeDetail from './guarantee-detail'
import SaveMessages from './save-message'
import BookingCancelReasons from './booking-cancel-reasons'
import SwitchLocale from './locale'
import VendorReceipts from './vendor-receipts'
import StylistEventBooking from './stylist-event-booking'
import EventStylist from './event-stylist'
import StylistEventRule from './stylist-event-rule'
import QRCode from './qrcode'

export default function Panel() {
  return (
    <div>
      <RouterQuery/>
      <Auth />
      <PhoneUpdate />
      <CreateCreditCard />
      <PaymentMethod />
      <ContactUs />
      <Alert />
      <SinglePhoto />
      <PrepayBooking />
      <UserBooking />
      <StylistBooking />
      <BuyDeposit />
      <BuyCourse />
      <StylistReviewCreate />
      <StylistCustomerCreate />
      <Review />
      <Gallery />
      <Collection />
      <Post />
      <PostCreate />
      <SwitchAccount />
      <Search />
      <CardSetting />
      <BookingRecords />
      <AccountSetting />
      <TagCreate />
      <Deposits />
      <GuaranteeDetail />
      <SaveMessages />
      <BookingCancelReasons />
      <SwitchLocale />
      <VendorReceipts/>
      <StylistEventBooking/>
      <EventStylist/>
      <StylistEventRule />
      <QRCode />
    </div>
  )
}

const template = `
  query DepositLogs($id: ID!) {
    depositLogs(id: $id) {
      id
      description
      createdAt
      amount
    }
  }
`

export default async function depositLogs(id) {
  const { depositLogs } = await request(template, { id })
  return depositLogs
}

import contactUsRequest from 'request/contact-us'

export default function ContactUs() {
  const { state, mutate } = useStatic({ visible: false })
  const { user } = useCurrent()
  const t = useI18n()
  const form = useForm({
    title: { value: '' },
    name: { rule: 'required', value: '' },
    email: { value: '' },
    phone: { rule: 'required', value: '' },
    message: { rule: 'required', value: '' }
  })

  h.contactUs = async({ title, message, phone, name, email }) => {
    state.visible = true
    const params = {}
    if (title != null) params.title = title
    if (message != null) params.message = message
    if (phone != null) params.phone = phone
    if (name != null) params.name = name
    if (email != null) params.email = email
    if (Object.keys(params).length) form.updateValues(params)

    mutate()
  }

  useEffect(() => {
    if (!user) return
    form.updateValues({
      phone: user.phone,
      name: user.name,
      email: user.email
    })
  }, [user])

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const onSubmit = async() => {
    await contactUsRequest({
      title: form.title.value,
      name: form.name.value,
      emailAddress: form.email.value,
      phone: form.phone.value,
      message: form.message.value
    })
    $alert(t('panel:contactUs.submitSuccess'))
    closePanel()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body form={ form } t={ t } onSubmit={ onSubmit }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ t('panel:contactUs.title') }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({ form, t, onSubmit }) => (
  <div className='overflow-auto px-5 py-8'>
    <C.InputForm
      label={ t('panel:contactUs.name') }
      form={ form }
      field='name'
      fullWidth
    />
    <C.InputForm
      label={ t('panel:contactUs.email') }
      form={ form }
      field='email'
      fullWidth
    />
    <C.InputForm
      label={ t('panel:contactUs.phone') }
      form={ form }
      field='phone'
      fullWidth
    />
    <C.InputForm
      label={ t('panel:contactUs.message') }
      form={ form }
      field='message'
      multiline
      fullWidth
    />
    <C.Button uid='contactUs.submit'
      fullWidth
      check={ form }
      onClick={ onSubmit }
    >
      { t('common:submit') }
    </C.Button>
  </div>
)
const template = `
query ReviewReplies($reviewId: ID!, $input: PaginationInput!) {
    reviewReplies(type: booking, reviewId: $reviewId, input: $input) {
      id
      reply
      createdAt
      replyUser {
        id
        name
        avatar
      }
      shouldHideName
    }
  }
`

export default async function reviewReplies({ reviewId, offset, limit }) {
  const input = { offset, limit }
  const { reviewReplies } = await request(template, { reviewId, input })
  return reviewReplies
}

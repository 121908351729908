const template = `
  query Posts(
    $type: PostQueryType!,
    $typeParams: PostQueryTypeParams,
    $paginationInput: PaginationInput!
    $serviceCategory: ServiceCategory
  ){
    posts(input: {
      type: $type
      typeParams: $typeParams
      sortMethod: latest
      paginationInput: $paginationInput
      serviceCategory: $serviceCategory
    }){
      id
      isPrivate
      isCollected
      photos {
        id
        width
        height
      }
      tags {
        name
      }
      postBeforePhoto {
        photo {
          small {
            url
          }
        }
      }
    }
  }
`

export default async function posts({ serviceCategory, type, id, tagId, limit, offset }) {
  const typeParams = { id }
  if (tagId) typeParams.tagId = tagId
  const paginationInput = { offset, limit }
  const { posts } = await request(template, { serviceCategory, type, typeParams, paginationInput })
  return posts
}

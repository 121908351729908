import { useSearch } from 'facades'
import Keyword from './keyword'

export default function Search() {
  const { state, toggleVisible } = useSearch()
  const t = useI18n()

  const hrefs = [
    h.path.studioEvents(),
    h.path.stylists(),
    h.path.styles()
  ]

  const toPage = url => {
    h.path.direct(url)
  }

  const blocks = t('panel:search.blocks')
    .map((props, index) => <Block
      key={ index }
      href={ hrefs[index] }
      desc={ props.desc }
      subDesc={ props.subDesc }
      btnText={ props.btnText }
      toPage={ toPage }
    />)

  return (
    <C.Modal
      visible={ state.visible }
      close={ toggleVisible }
    >
      <div className='sm:w-[28rem] sm:max-h-[35rem] py-2 select-none overflow-scroll flex-1 flex flex-col'>

        <Keyword toggleVisible={ toggleVisible }/>
        <div className='pb-3'>
          { blocks }
        </div>
      </div>
    </C.Modal>
  )
}

const Block = ({ href, desc, subDesc, btnText, toPage }) => (
  <div className='pt-8'>
    <div className='text-[0.8rem] font-bold text-gray-600'>{ desc }</div>
    <div className='flex space-x-2 pt-1 text-xs text-gray-400 font-normal'>
      { subDesc.map((text, idx) => (
        <div key={ idx }>#{text}</div>
      )) }
    </div>
    <Button className='mt-3 rounded-[10px]' onClick={ () => toPage(href) }>
      { btnText }
      <Icon.ChevronRight />
    </Button>
  </div>
)

const Button = ({ children, onClick, className }) => {
  const styleClass = 'border text-gray-500 shadow'
  const hoverStyle = 'hover:bg-gray-50'

  return (
    <div className={ cn('text-base font-medium text-cyan-700 px-5 py-[0.75rem] flex items-b justify-between rounded-[50px] cursor-pointer select-none relative transition-all', hoverStyle, styleClass, className) }
      onClick={ onClick }>
      { children }
    </div>
  )
}

import registerRequest from 'request/auth/register'

export default function useRegister({ mutate, loginSuccess, toStep }) {
  const register = async params => {
    mutate({ action: 'register' })
    const { phoneCode, phone, email, name, gender, code, token, birthday } = params
    const key = parseInt(phoneCode) + phone
    try {
      const user = await registerRequest({
        phone: key,
        email,
        name,
        gender,
        token,
        code,
        birthday
      })
      loginSuccess({ name: user.name })
    } catch (error) {
      switch (error.code) {
        case 'auth.phoneCodeIsSending':
        case 'auth.phoneNeedCode':
          mutate({
            phone: error.params.phone,
            codeExpiredAt: error.params.codeExpiredAt,
            resendPhoneCode: () => register(params),
            registerForm: {
              phoneCode,
              phone,
              email,
              name,
              gender,
              token
            }
          })
          toStep('phoneCode')
          return
        default:
          throw error
      }
    }
  }
  return register
}

const template = `
  query Customer($customerId: ID!){
    customer(customerId: $customerId){
      id
      name
      phone
      gender
      birthday
      notes
      bookedCount
      revenue
      remainDeposit
      user {
        id
        avatar
      }
      hairStates {
        score
        type
      }
    }
  }
`

export default async function customer(customerId) {
  const { customer } = await request(template, { customerId })
  return customer
}

let isChecked = false

export default function useRouterQueryResolver() {
  const t = useI18n()
  const router = useRouter()
  const { loading } = useCurrent()
  const paymentMethodFunc = usePaymentMethod()

  useEffect(() => {
    checkRouterQuery()
  }, [router, loading])

  const checkRouterQuery = () => {
    if (isChecked) return
    if (loading) return
    isChecked = true

    const { title, message, action, source, installment } = router.query
    if (!source) return
    const s = JSON.parse(source)
    if (checkRedirectPath(s)) return
    redirectByAction(t, action, s, title, paymentMethodFunc, installment)
    clearRouterQuery()

    if (!title) return
    if (!message) return
    $alert(`${title}! ${message}`, 'error')
  }

  const checkRedirectPath = panelSource => {
    if (!panelSource.path) return false
    if (panelSource.path === router.url) return false
    isChecked = false
    // const pathname = panelSource.path
    // delete panelSource.path
    // router.query.source = JSON.stringify(panelSource)
    // h.path.direct({ pathname, query: router.query })
    return true
  }

  const clearRouterQuery = () => {
    if (!router.query.source) return

    h.path.replace({
      source: undefined,
      title: undefined,
      message: undefined,
      action: undefined
    })
  }

  const redirectByAction = (t, action, panelSource, title, paymentMethodFunc, installment) => {
    if (action === 'bindcard') {
      redirectByBindcard(panelSource)
      if (title === '綁卡成功') $alert(t('common:bindCardSuccess'))
      return
    }

    if (title === '付款成功') {
      redirectByActionSuccess(panelSource)
      $alert(t('common:paidSuccess'))
      return
    }
    redirectByActionFail(panelSource, action, paymentMethodFunc, installment)
  }

  const redirectByBindcard = panelSource => {
    const { type, source } = panelSource
    switch (type) {
      case 'prepayBooking':
        return openPrepayBookingBySource(source)
      case 'checkoutBooking':
        return openCheckoutBookingBySource(source)
      case 'buyDeposit':
        return openBuyDepositSource(source)
      case 'buyCourse':
        return openBuyCourseSource(source)
      case 'buyVendorProduct':
        return openBuyVendorProductSource(source)
      case 'cardSetting':
        return h.openCardSetting()
      default:
    }

  }

  const redirectByActionSuccess = panelSource => {
    const { type, source } = panelSource
    switch (type) {
      case 'prepayBooking':
        return h.openBookingRecords()
      case 'checkoutBooking':
        return openCheckoutBookingBySource(source)
      case 'buyDeposit':
        return h.openMyDeposit()
      case 'buyCourse':
        return $messenger.talkToUser(1)
      case 'buyVendorProduct':
        return $messenger.talkToUser(1)
      default:
    }
  }

  const redirectByActionFail = (panelSource, action, paymentMethodFunc, installment) => {
    const { type, source } = panelSource
    const { paymentMethodChange, setType, setUrlQueryParams, setInstallment } = paymentMethodFunc
    setType(type)
    setUrlQueryParams(source)
    paymentMethodChange(action)
    if (action === 'zerocard') setInstallment(`${installment || 1}`)
    switch (type) {
      case 'prepayBooking':
        return openPrepayBookingBySource(source)
      case 'checkoutBooking':
        return openCheckoutBookingBySource(source)
      case 'buyDeposit':
        return openBuyDepositSource(source)
      case 'buyCourse':
        return openBuyCourseSource(source)
      case 'buyVendorProduct':
        return openBuyVendorProductSource(source)
      default:
    }
  }
}

const openPrepayBookingBySource = source => {
  if (source.isEvent) return h.openStylistEventBooking({ stylistId: source.stylistId, source })

  const { stylistId, stylistUserId, stylistName, defaultPrepayAmount, defaultMinPayment, applyFormId } = source
  $openPrepayBooking({
    stylistId,
    stylistUserId,
    stylistName,
    defaultPrepayAmount,
    defaultMinPayment,
    applyFormId,
    source
  })
}

const openCheckoutBookingBySource = source => {
  const path = window.location.pathname.split('/')[1]
  const shouldSkip = ['checkout-booking', 'visitor-checkout'].includes(path)
  if (shouldSkip) return
  const { bookingId } = source
  h.openUserBooking(bookingId)
}

const openBuyDepositSource = source => {
  const { stylistId, stylistUserId, stylistName, isStylistEvent } = source
  h.openBuyDeposit({ stylistId, stylistUserId, stylistName, isStylistEvent })
}

const openBuyCourseSource = source => {
  const { courseId, quantity, ig } = source
  h.openBuyCourse({ courseId, quantity, ig })
}

const openBuyVendorProductSource = () => {
  h.openVendorProductCart()
}
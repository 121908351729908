
export default function Payment({
  finalPayment,
  usedReward,
  fee,
  maxRewardUseRateText,
  t
}) {
  return <>
    { (usedReward > 0 || fee > 0) && (
      <div className='pb-5'>
        <div className='font-bold'>{ t('panel:buyDeposit.payment') }</div>
        <div className='py-3 space-y-3'>

          { usedReward > 0 && <div className='flex justify-between items-center text-sm'>
            <div>{ maxRewardUseRateText }</div>
            <div>-{ usedReward.currency() }</div>
          </div>
          }

          { fee > 0 && <div className='flex justify-between items-center text-sm'>
            <div>{ t('panel:buyDeposit.zerocardFee') }</div>
            <div>{ fee.currency() }</div>
          </div>
          }

          <div className='flex justify-between items-center text-sm text-red-500 font-bold'>
            <div>{ t('panel:buyDeposit.payAmount') }</div>
            <div>{ finalPayment.currency() }</div>
          </div>
        </div>
      </div>
    )}
  </>
}

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import LanguageIcon from '@mui/icons-material/Language'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural'
import BirthdayIcon from '@mui/icons-material/CakeOutlined'

export default function Register() {
  const t = useI18n()
  const { useStepRegister } = useCtx('panelAuth')
  const { phoneCodes, form, submit, genderOptions, locale } = useStepRegister()

  return (
    <div className='flex-1 overflow-scroll'>
      <InputWrapper
        Icon={ LanguageIcon }
      >
        <C.ComboBoxForm
          fullWidth
          variant='standard'
          form={ form }
          field='phoneCode'
          disableClearable
          options={ phoneCodes }
          label={ t('panel:auth.location') }
        />
      </InputWrapper>
      <IconInput
        form={ form }
        field='phone'
        Icon={ PhoneIphoneIcon }
        label={ t('panel:auth.phone') }
        helperText={ t('panel:auth.helpTextPhone') }
      />

      <IconInput
        form={ form }
        field='email'
        Icon={ MailOutlineIcon }
        label={ t('panel:auth.email') }
        helperText={ t('panel:auth.helpTextEmail') }
      />

      <IconInput
        form={ form }
        field='name'
        Icon={ PersonOutlineIcon }
        label={ t('panel:auth.name') }
      />

      <InputWrapper
        Icon={ FaceRetouchingNaturalIcon }
      >
        <C.SelectForm
          label={ t('panel:auth.gender') }
          form={ form }
          field='gender'
          options={ genderOptions }
        />
      </InputWrapper>

      <InputWrapper Icon={ BirthdayIcon }>
        <div className='flex-1'>
          <div className='w-full'>
            <C.DatePickerForm
              label={ t('panel:auth.birthday') }
              form={ form }
              field='birthday'
              className='w-full'
              textColor='text-black'
              disableFuture
            />
          </div>
          <div className='text-right text-xs text-black/[.6] opacity-50 mt-[-20px]'>{ t('panel:auth.helpTextBirthday') }</div>
        </div>
      </InputWrapper>

      <p className='text-[0.7rem] text-gray-500 py-1 text-center tracking-wider'>
        { t('panel:auth.aggrementText')}
        <a className='text-blue-600 underline cursor-pointer hover:text-blue-700' target='_blank' href={ `https://style-map.com/terms?locale=${locale}` } rel='noreferrer'>
          { t('panel:auth.aggrement')}
        </a>
      </p>

      <div className='mt-3'>
        <C.Button
          variant='main'
          check={ form }
          onClick={ submit }
          className='w-full'
        >
          { t('panel:auth.aggreAndContinue') }
        </C.Button>
      </div>
    </div>
  )
}

const InputWrapper = ({ children, Icon }) => (
  <div className='flex min-h-[4.5rem]'>
    <div className='w-7 relative'>
      <Icon className='absolute top-5' fontSize='small' />
    </div>
    { children }
  </div>
)

const IconInput = ({ Icon, ...props }) => (
  <InputWrapper Icon={ Icon }>
    <C.InputForm
      variant='standard'
      fullWidth
      { ...props }
    />
  </InputWrapper>
)

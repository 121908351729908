import vendorProductOrderDetailRequest from 'request/my/vendor-product-receipt-detail'

export default function VendorProductReceiptDetail() {
  const { state, mutate } = useStatic({
    visible: false,
    isOfficialStock: false,
    vendors: [],
    buyerInfo: {},
    totalAmount: 0,
    title: ''
  })
  const t = useI18n()

  h.openVendorProductReceiptDetail = async(orderId, userId, buyerInfo) => {
    const { vendors, isOfficialStock } = await vendorProductOrderDetailRequest({ orderId, userId })
    state.isOfficialStock = isOfficialStock
    state.vendors = vendors
    state.buyerInfo = buyerInfo
    state.totalAmount = state.vendors.sum(v => v.amount)
    state.title = t('page:vendorProductReceipts.detail')
    state.visible = true
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body state={ state } t={ t }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ state.title }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = parent => {
  const { state } = parent
  const paymentAmountResolver = () => {
    if (!state.isOfficialStock) return state.totalAmount.currency()
    return `${state.totalAmount.currency()} (由美配代墊)`
  }

  return (
    <div className='overflow-scroll'>
      {state.vendors.map(vendor => (
        <Vendor key={ vendor.name } vendor= { vendor }/>
      ))}
      <div className='p-5'>
        <C.PaymentDetail title='付款資訊' items={ [
          ['總金額', paymentAmountResolver(), 'font-bold text-red-500']
        ] } />
        <C.PaymentDetail title='訂購人資訊' items={ [
          ['姓名', state.buyerInfo.name],
          ['電話', state.buyerInfo.phone]
        ] } />
        <C.PaymentDetail title='收貨地點' items={ [
          ['店家名稱', state.buyerInfo.studio],
          ['店家地址', state.buyerInfo.address]
        ] } />
      </div>
      <C.Blur />
    </div>
  )
}

const Vendor = ({ vendor }) => {
  const { name, amount, shipFee, items } = vendor
  const renderShipFee = () => (
    <div>
      <VendorRow
        title= { '運費' }
        value={ shipFee.currency() }
      />
      <Hr/>
      <VendorRow
        title= { '小計' }
        value={ amount.currency() }
      />
    </div>
  )
  return (
    <div className='px-3 mt-3'>
      <div className='py-2 px-3 bg-gray-100 font-medium'>{ name }</div>
      { items.map((item, index) => (
        <Item
          key={ index }
          index= { index + 1 }
          item= { item }
        />
      )) }
      <VendorRow
        title= { `共${items.length}件商品` }
        value={ (amount - shipFee).currency() }
      />
      { !!shipFee && renderShipFee() }
    </div>
  )
}
const VendorRow = ({ title, value }) => (
  <div className='flex justify-between p-2 text-sm'>
    <div className='text-gray-500'>{title}</div>
    <div className='text-black font-medium'>{value}</div>
  </div>
)
const Item = ({ index, item }) => {
  const { name, price, brand, cover, spec, count, amount } = item

  return (
    <>
      <div className='flex py-4 space-x-3'>
        <C.Image
          className='w-20 h-20'
          type='contain'
          alt='product icon'
          src={ cover.small.url }
        />
        <div className='flex-1 my-auto'>
          <div className='font-medium pb-1 text-sm'>{`${index}.【${brand}】${name}`}</div>
          <ItemRow title='售價' value={ price.currency() }/>
          <ItemRow title='規格' value={ spec }/>
          <ItemRow title='數量' value={ count }/>
          <ItemRow title='小計' value={ amount.currency() }/>
        </div>
      </div>
      <Hr />
    </>
  )
}
const ItemRow = ({ title, value }) => (
  <div className='flex items-center my-[0.15rem] text-sm'>
    <div className='text-gray-500'>{title}:</div>
    <div className='text-black ml-2'>{value}</div>
  </div>
)
const Hr = () => <div className='border-b'></div>

const template = `
  mutation bookingCheckout($input: BookingCheckoutInput!) {
    bookingCheckout(input: $input)
  }
`

export default async function bookingCheckout(bookingId, services, products) {
  const input = { bookingId }
  input.services = services.map(service => {
    const { id, price } = service
    return { id: parseInt(id), price }
  })
  input.products = products.map(product => {
    const { id, price, count } = product
    return { id: parseInt(id), price, quantity: count }
  })

  await request(template, { input })
}
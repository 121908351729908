export default function PaymentMethod() {
  const { state, mutate } = useStatic({
    visible: false,
    isStylistEvent: false,
    onPaymentMethodChange: null
  })
  const {
    method,
    selectedCard,
    cards,
    paymentMethodChange,
    isGooglePayReady,
    isApplePayReady,
    urlQueryParams,
    setUrlQueryParams,
    refreshConfig,
    isPayMethodSupported,
    setType
  } = usePaymentMethod()

  h.openPaymentMethod = (urlQueryParams, type, isStylistEvent) => {
    state.isStylistEvent = isStylistEvent
    state.visible = true
    setType(type)
    setUrlQueryParams(urlQueryParams)
    mutate()
  }

  useEffect(() => {
    state.isPayMethodSupported = isPayMethodSupported
    mutate()
  }, [isPayMethodSupported])

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body
      defaultMethod={ method }
      defaultCard={ selectedCard }
      cards={ cards }
      urlQueryParams={ urlQueryParams }
      paymentMethodChange={ paymentMethodChange }
      isGooglePayReady= { isGooglePayReady }
      isApplePayReady= { isApplePayReady }
      refreshConfig={ refreshConfig }
      isPayMethodSupported={ isPayMethodSupported }
      isStylistEvent={ state.isStylistEvent }
      closePanel={ closePanel }
    />
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      { renderBody() }
    </C.Modal>
  )
}

const Body = ({
  defaultMethod,
  defaultCard,
  cards,
  urlQueryParams,
  paymentMethodChange,
  refreshConfig,
  isGooglePayReady,
  isApplePayReady,
  isPayMethodSupported,
  closePanel
}) => {
  const t = useI18n()
  const { user } = useCurrent()
  const { open } = usePanel()
  const { state, mutate } = useStatic({
    method: defaultMethod,
    selectCard: defaultCard
  })
  const { method, selectCard } = state

  const itemClick = (method, card) => {
    state.method = method
    state.selectCard = card
    mutate()
  }
  const submit = () => {
    paymentMethodChange(method, selectCard)
    closePanel()
  }

  const openCardCreatePanel = () => {
    if (!user) return open('auth')
    h.openCardCreate({ onSuccess: refreshConfig, urlQueryParams })
  }

  const Cards = () => cards.map(card =>
    <PaymentMethodItem
      key={ card.id }
      iconAlt={ 'creditcard' }
      iconClass={ 'w-10 h-8' }
      iconSrc ={ card.$imgUrl }
      iconType={ 'contain' }
      text={ <div>
        <div className='text-sm'>****{ card.last4 }</div>
        <div className='text-gray-400 text-xs'>{ card.bankNameTw }</div>
      </div> }
      isChecked={ selectCard?.id === card.id }
      onClick={ () => itemClick('bindedCard', card) }/>
  )

  const ApplePay = () => {
    if (!isApplePayReady) return ''
    return <PaymentMethodItem
      iconAlt={ 'Apple Pay' }
      iconClass={ 'w-10 h-8' }
      iconSrc ={ cdn('images/apple_pay.png') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.applePay') }
      isChecked={ method === 'applePay' }
      onClick={ () => itemClick('applePay') }/>
  }

  const GooglePay = () => {
    if (!isGooglePayReady) return ''
    return <PaymentMethodItem
      iconAlt={ 'Google Pay' }
      iconClass={ 'w-10 h-8' }
      iconSrc ={ cdn('images/googlePay3.png') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.googlePay') }
      isChecked={ method === 'googlePay' }
      onClick={ () => itemClick('googlePay') }/>
  }

  const Atm = () => {
    if (!user) return ''
    // if (isStylistEvent) return ''
    return <PaymentMethodItem
      iconAlt={ 'atm' }
      iconClass={ 'w-10 h-8' }
      iconSrc ={ cdn('images/atm.png') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.atm') }
      isChecked={ method === 'atm' }
      onClick={ () => itemClick('atm') }/>
  }

  const Zerocard = () =>
    <PaymentMethodItem
      iconAlt={ 'zerocard' }
      iconClass={ 'w-10 h-7' }
      iconSrc ={ cdn('images/zerocard.png') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.zerocard') }
      isChecked={ method === 'zerocard' }
      onClick={ () => itemClick('zerocard') }/>

  const PayoutPay = () => {
    if (!isPayMethodSupported.payoutPay) return ''
    return <PaymentMethodItem
      iconAlt={ 'Payout Pay' }
      iconClass={ 'w-10 h-8' }
      iconSrc ={ cdn('svg/logo_no_bg.png') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.payoutPay') }
      isChecked={ method === 'payout' }
      onClick={ () => itemClick('payout') }/>
  }

  const LinePay = () => {
    if (!isPayMethodSupported.linePay) return ''
    return <PaymentMethodItem
      iconAlt={ 'LINE Pay' }
      iconClass={ 'w-10 h-7' }
      iconSrc ={ cdn('images/line_pay.png') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.linePay') }
      isChecked={ method === 'linePay' }
      onClick={ () => itemClick('linePay') }/>
  }

  const PlusPay = () => {
    if (!isPayMethodSupported.plusPay) return ''
    return <PaymentMethodItem
      iconAlt={ 'Plus Pay' }
      iconClass={ 'w-10 h-7' }
      iconSrc ={ cdn('images/plus_pay.png') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.plusPay') }
      isChecked={ method === 'plusPay' }
      onClick={ () => itemClick('plusPay') }/>
  }

  const CashPay = () => {
    if (!isPayMethodSupported.cash) return ''
    return <PaymentMethodItem
      iconAlt={ 'Cash' }
      iconClass={ 'w-10 h-7' }
      iconSrc ={ cdn('images/cash_pay.jpg') }
      iconType={ 'contain' }
      text={ t('panel:paymentMethod.cashPay') }
      isChecked={ method === 'cash' }
      onClick={ () => itemClick('cash') }/>
  }

  return (
    <div className='sm:min-w-[22rem] flex flex-col'>
      <div className='text-center py-4 flex-shrink-0'>{ t('panel:paymentMethod.title') }</div>
      <div className='p-5 sm:p-3 overflow-scroll'>
        <div className='px-5'>
          <PayoutPay/>
          <ApplePay/>
          <GooglePay/>
          <Cards/>
          <LinePay/>
          <PlusPay/>
          <Zerocard/>
          <Atm/>
          <CashPay/>
          <div className='text-center text-business cursor-pointer pt-5 pb-3'
            onClick={ openCardCreatePanel }>
            { t('panel:paymentMethod.cardCreate') }
          </div>
        </div>
      </div>
      <div className='p-5 pt-0 flex-shrink-0'>
        <div className='relative'><C.Blur/></div>
        <C.Button uid='paymentMethod.submit' fullWidth
          onClick={ submit }>
          { t('panel:paymentMethod.submit') }
        </C.Button>
      </div>
    </div>
  )
}

const PaymentMethodItem = ({ iconAlt, iconClass, iconSrc, iconType, text, isChecked, onClick }) =>
  <div className='px-3 border-b flex items-center justify-between cursor-pointer h-[4.5rem]'
    onClick={ onClick }>
    <div className='flex items-center space-x-2'>
      <C.Image
        alt={ iconAlt }
        className={ iconClass }
        type={ iconType }
        src={ iconSrc }
      />
      <div>{text}</div>
    </div>
    <Icon.Check className={ cn({ hidden: !isChecked }) }/>
  </div>

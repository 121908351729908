import postRequest from 'request/common/post'
import CommonPost from 'components/common/post'

export default function Post() {
  const { state, mutate } = useStatic({
    visible: false,
    post: null
  })

  h.openPost = async({ id }) => {
    mkt.postClick(id)
    state.post = await postRequest(id)
    state.visible = true
    if (!state.post) return
    mutate()
  }

  const close = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => <CommonPost post={ state.post } closePanel={ close }/>

  return (
    <C.Modal
      noPadding
      visible={ state.visible }
      close={ close }
    >
      { renderBody() }
    </C.Modal>
  )
}

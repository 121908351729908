import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined'
import TimerIcon from '@mui/icons-material/Timer'

export default function PhoneCode() {
  const t = useI18n()
  const { useStepPhoneCode, state } = useCtx('panelAuth')
  const { countDown, resendable, resendPhoneCode, sendPhoneCode, phoneCall } = useStepPhoneCode()
  const { token } = state.registerForm
  const askPhoneCall = () => {
    if (!token) {
      phoneCall()
      return
    }

    if (!confirm(t('panel:auth.phoneCallConfirm', { phone: state.phone }))) return
    phoneCall()
  }

  const cannotReceiveCodeTextResolver = () => {
    if (token) return t('panel:auth.cannotReceiveCode')
    return t('panel:auth.cannotReceiveCodeWithoutToken')
  }

  return (
    <div>
      <div className='flex min-h-[4.5rem] mx-auto max-w-xs'>
        <div className='w-7 relative'>
          <LockClockOutlinedIcon className='absolute top-5' fontSize='small' />
        </div>
        <C.Input
          label={ t('panel:auth.phoneCode') }
          variant='standard'
          onChange={ sendPhoneCode }
          center
          fullWidth
        />
      </div>
      <div className='text-center pt-5 text-sm flex items-center mx-auto justify-center'>
        <TimerIcon fontSize='small'/>
        <span className='px-1 w-12 inline-block'>{ countDown }</span>
        <C.Button
          sx={ { fontSize: '0.75rem' } }
          variant='text'
          disabled={ !resendable }
          onClick={ resendPhoneCode }
        >{ t('panel:auth.resendPhoneCode') }</C.Button>
      </div>
      <div className='text-center'>
        <div className='cursor-pointer inline-flex items-center text-sm border-b'
          onClick={ askPhoneCall }
        >
          <div>{ cannotReceiveCodeTextResolver() }</div>
          <div className='rotate-180'>
            <ArrowBackIcon fontSize='inherit'/>
          </div>
        </div>
      </div>
    </div>
  )
}

import ratingInfoRequest from 'request/review/rating-info'

export default async function fetchRatingStatus({ state }) {
  const ratingInfo = await ratingInfoRequest({ type: state.type, id: state.id })
  const ratingCounts = ratingInfo.ratingCounts
  const totalCount = ratingCounts.sum()
  const totalAverage = ratingInfo.rating

  state.totalAverage = totalAverage
  state.totalCount = totalCount
  state.ratingCounts = ratingCounts
    .map((count, index) => ({
      score: index + 1,
      count,
      percentage: count / totalCount
    }))
    .reverse()
  state.ratingName = ratingInfo.name
}

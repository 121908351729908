//import AlbumCreate from './album-create'
//import albumsRequest from 'request/common/albums'
import postUpdateRequest from 'request/common/post-update'
import postCreateRequest from 'request/common/post-create'
import postRequest from 'request/common/post'
import tagSearchRequest from 'request/common/tag-search'

export default function PostCreate() {
  const t = useI18n()
  const { categoryOptions } = useTagConfig()
  const { state, mutate } = useStatic({
    visible: false,
    post: null,
    defaultPhotos: [],
    defaultTags: [],
    defaultBeforePhotos: null,
    defaultTagCategory: 'hair',
    callback: null,
    tagCategoryOptions: categoryOptions
  })

  const prepareDefaultPhotos = photos => {
    if (!photos?.length) return []
    return photos.map(photo => h.path.postPhoto({ id: photo.id }))
  }

  const prepareDefaultTags = tags => {
    if (!tags?.length) return []
    return tags
  }

  const prepareDefaultBeforePhotos = post => {
    const beforePhoto = post?.postBeforePhoto?.photo?.small?.url
    if (!beforePhoto) return []
    return [beforePhoto]
  }

  h.openPostCreate = ({ post, user, callback }) => {
    state.visible = true
    state.post = post
    state.user = user
    state.defaultTagCategory = post?.tagCategoryKey || 'hair'
    state.defaultPhotos = prepareDefaultPhotos(post?.photos)
    state.defaultTags = prepareDefaultTags(post?.tags)
    state.defaultBeforePhotos = prepareDefaultBeforePhotos(post)
    state.callback = callback
    mutate()
  }

  h.openPostEdit = async({ postId, user }) => {
    const post = await postRequest(postId)
    state.visible = true
    state.post = post
    state.user = user
    state.defaultTagCategory = post.tagCategoryKey
    state.defaultPhotos = prepareDefaultPhotos(post.photos)
    state.defaultTags = prepareDefaultTags(post.tags)
    state.defaultBeforePhotos = prepareDefaultBeforePhotos(post)
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const title = state.post ? t('panel:postCreate.edit') : t('panel:postCreate.create')

  const renderBody = () => {
    if (!state.visible) return
    return <Body state={ state } closePanel={ closePanel } />
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
      confirmOnClose
    >
      <C.ModalHeader title={ title }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = parent => {
  const { post, defaultTagCategory, defaultTags, defaultPhotos, defaultBeforePhotos, tagCategoryOptions, callback } = parent.state
  const t = useI18n()
  const tagOptions = tag => {
    const { id, name, postNum } = tag
    return {
      ...tag,
      key: id,
      value: `#${name}`,
      $postCount: t('panel:selectTag.postCount', { count: postNum.format() })
    }
  }
  const { state, mutate } = useStatic({
    tags: defaultTagCategory === 'hair' ? defaultTags : [],
    //albums: [],
    isSubmit: false
  })
  const form = useForm({
    //album: { value: [] },
    privacy: { value: 'public' },
    description: { value: '', rule: 'required' },
    //albumKeyword: { value: '' },
    tagCategory: { value: defaultTagCategory },
    postTags: { value: defaultTagCategory !== 'hair' ? defaultTags.map(tag => tagOptions(tag)) : [] },
    tagKeyword: { value: '' }
  })

  // const refreshAlbums = async defaultAlbum => {
  //   state.albums = await albumsRequest(post?.user.id || user.id)
  //   if (!defaultAlbum) return mutate()
  //   const album = state.albums.find(album => album.id === defaultAlbum.id)
  //   form.album.value = [albumOptions(album)]
  //   form.albumKeyword.value = ''
  //   mutate()
  // }

  const postInput = {}
  let beforePhotoReady = false
  let photosReady = false

  useEffect(() => {
    if (!post) {
      //refreshAlbums()
      return
    }
    const { isPrivate, description } = post
    form.privacy.value = isPrivate ? 'private' : 'public'
    form.description.value = description
    //refreshAlbums(album)
    mutate()
  }, [])

  const submit = () => {
    //if (!privacyCheck()) return
    mutate({ isSubmit: true })
  }

  // const privacyCheck = () => {
  //   const { privacy, album } = form
  //   if (!album.value.length) return true
  //   const albumInfo = album.value[0]
  //   if (privacy.value === albumInfo.privacy) return true
  //   const privacyText = albumInfo.privacy === 'public' ? t('panel:postCreate.public') : t('panel:postCreate.private')
  //   $alert(t('panel:postCreate.privacyCheck', { name: albumInfo.name, privacy: privacyText }), 'error')
  //   return false
  // }

  const postUpdate = async input => {
    await postUpdateRequest(input)
    $alert(t('panel:postCreate.editSuccess'))
    if (!callback) return parent.closePanel()
    const newPost = await postRequest(post.id)
    callback(newPost)
    parent.closePanel()
  }
  const postCreate = async input => {
    await postCreateRequest(input)
    $alert(t('panel:postCreate.createSuccess'))
    if (callback) callback()
    parent.closePanel()
  }

  const finishUploadPostPhotos = async filenames => {
    const { tags } = state
    const { postTags, description, tagCategory, privacy } = form

    const renderTags = () => {
      if (tagCategory.value === 'hair') return tags
      return postTags.value
    }

    photosReady = true

    postInput.description = description.value,
    postInput.tagIds = renderTags().map(tag => parseInt(tag.id)),
    postInput.privacy = privacy.value,
    postInput.images = filenames,
    //postInput.albumId = null,
    postInput.tagCategory = tagCategory.value

    //if (album.value.length) postInput.albumId = album.value[0].id
    if (!beforePhotoReady) return
    await submitPostRequest(postInput)
  }

  const finishUploadPostBefore = async filenames => {
    beforePhotoReady = true
    const beforePhoto = filenames.first() || ''
    postInput.beforePhoto = beforePhoto
    if (!photosReady) return
    await submitPostRequest(postInput)
  }

  const submitPostRequest = async input => {
    try {
      if (post) {
        await postUpdate({ postId: post.id, ...input })
        return
      }
      await postCreate(input)
    } catch (error) {
      mutate({ isSubmit: false })
      throw error
    }
  }

  const errorUploadFile = (key, params) => {
    $alert(t(key, params), 'error')
    mutate({ isSubmit: false })
  }

  const tagCategoryChange = () => {
    form.postTags.value = []
    mutate({ tags: [] })
  }

  const finishTagSelect = tags => mutate({ tags })

  // const albumCreateCallback = async keyword => {
  //   await refreshAlbums()
  //   form.update('albumKeyword', keyword)
  // }

  // const openAlbumCreate = () => {
  //   if (post && post.user.id !== user.id) return $alert('不能幫別人新增相簿', 'error')
  //   h.openAlbumCreate({ callback: albumCreateCallback })
  // }
  // const albumOptions = album => {
  //   const { id, name } = album
  //   return {
  //     ...album,
  //     key: id,
  //     value: name,
  //     $postCount: t('panel:search.postCount', { count: album.postNum.format() })
  //   }
  // }
  // const albumLoadMore = ({ keyword, offset }) => {
  //   if (offset > 0) return []
  //   const substring = keyword.trim().toLowerCase()
  //   return state.albums.filter(album => album.name.toLowerCase().includes(substring))
  // }

  const renderSelectTag = () => {
    const tagCategory = form.tagCategory.value
    if (tagCategory === 'hair') return (
      <C.SelectTag
        title={ t('panel:postCreate.postTag') }
        defaultTags={ state.tags }
        onFinished={ finishTagSelect }
      />
    )

    const tagCreateCallback = keyword => form.update('tagKeyword', keyword)
    const openTagCreate = () => h.openTagCreate({ category: tagCategory, callback: tagCreateCallback })
    const tagLoadMore = ({ keyword, offset, limit }) => tagSearchRequest({ keyword, offset, limit, filterCategory: true, filterTagCategory: tagCategory })

    return (
      <C.AsyncSelectForm
        multiple
        maxCheckedCount={ 10 }
        label={ t('panel:postCreate.postTag') }
        field='postTags'
        form={ form }
        createValueLabel={ t('panel:selectTag.newTag') }
        defaultKeyword={ form.tagKeyword.value }
        openCreateValue={ openTagCreate }
        optionFormat={ tagOptions }
        optionRender={ option => (
          <div className=''>
            <div className='line-clamp-1'>{ option.value }</div>
            <div className='text-sm text-gray-400'>{ option.$postCount }</div>
          </div>
        ) }
        loadMore={ tagLoadMore }
      />
    )
  }

  const privacyOptions = [{
    value: 'public',
    text: t('panel:postCreate.public')
  }, {
    value: 'private',
    text: t('panel:postCreate.private')
  }]

  return (
    <>
      <div className='flex-1 overflow-scroll p-5 pb-10 flex flex-col space-y-5'>
        <div className='border-b pb-5'>
          <div className='py-2 text-xs text-gray-500'>{ t('panel:postCreate.choosePhoto') }</div>
          <div>
            <C.SelectPhoto
              uid='postCreate.submit'
              min='1'
              max='6'
              isSubmit={ state.isSubmit }
              onFinished={ finishUploadPostPhotos }
              onError={ errorUploadFile }
              defaultImages={ defaultPhotos }
            />
          </div>
        </div>

        <div>
          <div className='text-xs text-gray-500 pb-2'>{ t('panel:postCreate.chooseBefore') }</div>
          <C.SelectPhoto
            uid='postCreate.submit'
            max='1'
            isSubmit={ state.isSubmit }
            onFinished={ finishUploadPostBefore }
            onError={ errorUploadFile }
            defaultImages={ defaultBeforePhotos }
          />
        </div>

        <div className='pt-4'>
          <C.InputForm
            label={ t('panel:postCreate.postDesc') }
            variant='standard'
            form={ form }
            field='description'
            multiline
            fullWidth
          />
        </div>

        <div className='pb-5'>
          <C.SelectForm
            label={ t('panel:postCreate.tagCategory') }
            form={ form }
            field='tagCategory'
            options={ tagCategoryOptions }
            className='w-full'
            onChange={ tagCategoryChange }
          />
        </div>

        <div>
          { renderSelectTag() }

        </div>

        {/* <div>
          <C.AsyncSelectForm
            multiple
            maxCheckedCount={ 1 }
            label={ t('panel:postCreate.album') }
            field='album'
            form={ form }
            createValueLabel={ t('panel:postCreate.newAlbum') }
            defaultKeyword={ form.albumKeyword.value }
            openCreateValue={ openAlbumCreate }
            optionFormat={ albumOptions }
            optionRender={ option => (
              <div className='flex items-center'>
                { option.coverUrl && <C.Image className='w-10 h-10' src={ option.coverUrl } alt='cover'/> }
                { !option.coverUrl && <div className='h-[40px] w-[40px] bg-gray-100'></div> }
                <div className='ml-3'>
                  <div className='truncate'>{ option.value }</div>
                  <div className='truncate text-sm text-gray-600'>{ option.$postCount }</div>
                </div>
              </div>
            ) }
            loadMore={ albumLoadMore }
          />
        </div> */}
        <div>
          <C.SelectForm
            label={ t('panel:postCreate.privacy') }
            form={ form }
            field='privacy'
            options={ privacyOptions }
            className='w-full'
          />
        </div>
      </div>
      <div className='relative'><C.Blur/></div>
      <div className='shrink-0 p-5'>
        <C.Button uid='postCreate.submit' check={ form }
          onClick={ submit } className='w-full'>
          { t('panel:postCreate.submit') }
        </C.Button>
      </div>
      {/* <AlbumCreate /> */}
    </>
  )
}

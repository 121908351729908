import cancelReasonsRequest from 'request/common/booking-cancel-reasons'

export default function BookingCancelReasons() {
  const { state, mutate } = useStatic({ visible: false, callback: null, cancelReasons: [] })

  h.openBookingCancelReasons = async({ bookingId, callback }) => {
    state.cancelReasons = await cancelReasonsRequest(bookingId)
    state.callback = callback
    state.visible = true
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body
      cancelReasons={ state.cancelReasons }
      callback={ state.callback }
      closePanel={ closePanel }
    />
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      { renderBody() }
    </C.Modal>
  )
}

const Body = ({ cancelReasons, callback, closePanel }) => {
  const t = useI18n()
  const { state, mutate } = useStatic({ selectIdx: 0 })
  const form = useForm({ cancelOtherReason: { value: '' } })
  const reasonsCount = cancelReasons.length

  const reasonClick = idx => {
    if (idx === state.selectIdx) return
    mutate({ selectIdx: idx })
  }

  const submit = async() => {
    let cancelType = null
    let cancelOtherReason = null
    switch (state.selectIdx) {
      case reasonsCount:
        cancelOtherReason = form.cancelOtherReason.value.trim()
        if (!cancelOtherReason) return $alert(t('panel:bookingCancelReason.otherReasonWarning'), 'error')
        break
      default:
        cancelType = cancelReasons[state.selectIdx].id
    }
    if (callback) await callback({ cancelType, cancelOtherReason })
    $alert(t('panel:bookingCancelReason.success'))
    closePanel()
  }

  return (
    <div className='sm:min-w-[22rem] flex flex-col'>
      <div className='text-center py-4 flex-shrink-0'>{ t('panel:bookingCancelReason.title') }</div>
      <div className='p-5'>
        <div className='px-2'>
          { cancelReasons.map((cancelReason, idx) =>
            <div key={ cancelReason.id } className='flex-1 flex items-center justify-between cursor-pointer h-[4.5rem] border-b'
              onClick={ () => reasonClick(idx) }>
              <div>{ cancelReason.reason }</div>
              <Icon.Check className={ cn({ hidden: state.selectIdx !== idx }) }/>
            </div>
          ) }

          <div className='flex-1 flex items-center justify-between pt-2 cursor-pointer border-b'
            onClick={ () => reasonClick(reasonsCount) }>
            <div className='flex-1'>
              <C.InputForm
                variant='standard'
                label={ t('panel:bookingCancelReason.other') }
                form={ form }
                field='cancelOtherReason'
                fullWidth
                multiline
              />
            </div>
            <Icon.Check className={ cn({ hidden: state.selectIdx !== reasonsCount }) }/>
          </div>

        </div>
        <div className='pt-12'>
          <C.Button uid='bookingCancelReason.submit'
            fullWidth
            onClick={ submit }
          >
            { t('common:submit') }
          </C.Button>
        </div>
      </div>
    </div>
  )
}

import tagSearchRequest from 'request/search/tags'
import userSearchRequest from 'request/search/users'
import studioSearchRequest from 'request/search/studios'

const limit = 20

export default async function fetchResult({ state, mutate, t }) {
  const { uid } = state
  try {
    if (state.uid !== uid) return
    const searchRequest = fetchRequestByType(state.keywordType)
    const fetched = await searchRequest({
      keyword: state.keyword,
      offset: state.result[state.keywordType].length,
      limit
    })

    const result = state.result[state.keywordType]
    if (state.uid !== uid) return
    const idDic = result.keyBy('id')
    fetched.forEach(r => {
      if (idDic[r.id]) return
      result.push(formatter({ type: state.keywordType, result: r, t }))
    })
    state.result[state.keywordType] = result

    state.finishSearch = fetched.length < limit

  } finally {
    if (state.uid !== uid) return
    state.searching = false
    mutate()
  }
}

const fetchRequestByType = type => {
  switch (type) {
    case 'stylist':
      return userSearchRequest
    case 'studio':
      return studioSearchRequest
    default:
      return tagSearchRequest
  }
}

const formatter = ({ type, result, t }) => {
  const obj = { id: result.id }
  switch (type) {
    case 'stylist':
      obj.src = result.avatar
      obj.title = result.name
      obj.subTitle = `@${result.uniqueName}`
      if (!result.stylist) return obj
      const { stylist } = result
      const { isStudioAccount, certificated, show, studio } = stylist
      const ratingTarget = isStudioAccount ? studio : stylist
      obj.rating = {
        score: ratingTarget.rating,
        countText: t('panel:search.ratingCount', { count: ratingTarget.totalRatingCount.format() })
      }
      obj.certificated = certificated

      if (show && studio) {
        obj.url = h.path.stylist({ id: result.id })
        obj.authorized = stylist.isGuarantee
      }
      return obj
    case 'studio':
      obj.src = result.logoUrl
      obj.title = result.name
      obj.subTitle = result.address
      obj.rating = {
        score: result.rating,
        countText: t('panel:search.ratingCount', { count: result.totalRatingCount.format() })
      }
      obj.certificated = result.certificated
      obj.authorized = result.authorized
      obj.url = h.path.studio({ id: result.id })
      return obj
    default:
      obj.src = result.coverUrl
      obj.title = result.name
      obj.subTitle = t('panel:search.postCount', { count: result.postNum.format() })
      obj.url = h.path.style({ id: result.id })
      return obj
  }
}

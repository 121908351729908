import fetchSchedule from 'request/common/fetch-schedule'

export default async function stateSimulation({
  state,
  nextStep,
  selectTimeSlot
}) {
  const { source, serviceMap, stylistServices, isModel } = state
  const { category, serviceKey, otherIds } = source
  if (source.startTime) state.startTime = dayjs(source.startTime)

  if (!fetchCategory(state, category, serviceMap)) return
  await nextStep(true)

  if (!fetchService(state, serviceKey, serviceMap)) return
  await nextStep(true)

  if (!fetchOthers(state, otherIds, stylistServices)) return
  await nextStep()
  if (!state.startTime) return

  const serviceIdsResolver = () => {
    if (state.service.$type === 'discount') return []
    const serviceId = parseInt(state.service.service.id)
    const addServiceIds = state.others.map(o => parseInt(o.service.id))
    return [serviceId, ...addServiceIds]
  }
  const serviceIds = serviceIdsResolver()

  const info = await fetchSchedule({
    userId: state.stylistUserId,
    duration: state.duration,
    startDate: state.startTime,
    campaignId: state.service.campaign?.id,
    serviceIds,
    isModel,
    endDate: state.startTime
  })

  const slot = state.startTime.slot()
  if (info[0].closedSlots.includes(slot)) return

  await selectTimeSlot(state.startTime)
}

const fetchCategory = (state, category, serviceMap) => {
  if (!serviceMap[category]) return false
  state.category = category
  return true
}

const fetchService = (state, serviceKey, serviceMap) => {
  const services = serviceMap[state.category].infos
  const service = services.find(s => s.$key === serviceKey)
  if (!service) return false
  state.service = service
  return true
}

const fetchOthers = (state, otherIds, stylistServices) => {
  const serviceDic = stylistServices.keyBy('id')
  for (const id of otherIds) {
    const service = serviceDic[id]
    if (!service) return false
    state.otherDic[id] = true
    state.others.push(service)
  }
  return true
}

import depositLogRequest from 'request/my/deposit-logs'
import adminDepositLogRequest from 'request/admin/deposit-logs'

export default function DepositDetail() {
  const { state, mutate } = useStatic({
    visible: false,
    logs: [],
    title: ''
  })
  const t = useI18n()

  h.openDepositDetail = async({ stylistName, depositId, isAdmin }) => {
    state.title = t('panel:my.depositDetail.title', { stylistName })
    state.isAdmin = isAdmin
    state.depositId = depositId
    state.logs = await reqeuestResolver(state)
    state.visible = true
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const reqeuestResolver = async state => {
    const { depositId, isAdmin } = state
    if (isAdmin) return adminDepositLogRequest(depositId)
    return depositLogRequest(depositId)
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body state={ state } t={ t }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ state.title }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = parent => {
  const { t, state } = parent

  const amountStyle = amount => {
    if (amount > 0) return 'text-green-500'
    return 'text-red-500'
  }

  const amountText = amount => {
    if (amount >= 0) return `+${amount.currency()}`
    return amount.currency()
  }

  return (
    <>
      <Row
        className='font-bold text-sm'
        values={ [
          t('panel:my.depositDetail.time'),
          t('panel:my.depositDetail.event'),
          t('panel:my.depositDetail.amount')
        ] }
      />
      <Hr />
      <div className='flex-1 overflow-scroll pb-5 text-sm'>
        { state.logs.map(({ id, amount, createdAt, description }) => (
          <Row
            key={ id }
            className='font-medium'
            values={ [
              createdAt.ymdhm(),
              description,
              <div key='amount' className={ amountStyle(amount) }>
                { amountText(amount) }
              </div>
            ] }
          />
        )) }
      </div>
      <C.Blur />
    </>
  )
}

const Row = ({ values, className }) => (
  <div className={ cn('flex space-x-3 items-center py-4 px-5', className) }>
    <div className='flex-1 truncate'>{ values[0] }</div>
    <div className='min-w-[25%] shrink-0'>{ values[1] }</div>
    <div className='min-w-[25%] flex justify-end text-right shrink-0'>{ values[2] }</div>
  </div>
)

const Hr = () => <div className='border-b'></div>
import All from './all'
import Tag from './tag'
import Album from './album'

export default function Collection() {
  const t = useI18n()
  const { state, mutate } = useStatic({
    visible: false,
    title: '',
    childTitle: null,
    showPreviousBtn: false,
    previous: () => {},
    active: 'all',
    type: null,
    id: null,
    serviceCategory: null
  })

  h.openCollection = ({ title, type, id, serviceCategory }) => {
    state.visible = true
    state.showPreviousBtn = false
    state.childTitle = null
    state.active = 'all'
    state.type = type
    state.id = id
    state.title = title
    state.serviceCategory = serviceCategory
    mutate()

    if (type === 'user') return mkt.stylistPostOpen(id)
    if (type === 'studio') return mkt.studioPostOpen(id)
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const changeType = type => {
    if (state.active === type) return
    state.active = type
    mutate()
  }

  const menuVisible = () => {
    if (state.showPreviousBtn) return 'hidden'
    return
  }

  const Label = ({ text, type }) => {
    const style = () => {
      if (type === state.active) return
      return 'opacity-40 hover:opacity-80 cursor-pointer'
    }
    return (
      <div
        className={ cn('flex-1 font-medium text-center py-3 text-sm select-none', style()) }
        onClick={ () => changeType(type) }
      >
        { text }
      </div>
    )
  }

  const renderTabs = () => {
    switch (state.type) {
      // case 'user':
      //   return (
      //     <div className={ cn('flex shadow border-b', menuVisible()) }>
      //       <Label text={ t('panel:collection.allPost') } type='all'/>
      //       <Label text={ t('panel:collection.tag') } type='tag' />
      //       <Label text={ t('panel:collection.album') } type='album' />
      //     </div>
      //   )
      case 'collection':
        return (
          <div className={ cn('flex shadow border-b', menuVisible()) }>
            <Label text={ t('panel:collection.allCollect') } type='all'/>
            <Label text={ t('panel:collection.tag') } type='tag' />
          </div>
        )
      default:
        return
    }
  }

  const renderBody = () => {
    if (!state.visible) return
    const title = state.childTitle || state.title
    const className = 'flex-1 py-[1px] min-h-[28.5rem]'
    return (
      <C.ModalHeader title={ title } className='sm:w-[36rem]'>
        <div className='shrink-0'>
          { renderTabs() }
        </div>
        <All state={ state } mutate={ mutate } className={ className }/>
        <Tag state={ state } mutate={ mutate } className={ className }/>
        <Album state={ state } mutate={ mutate } className={ className } t={ t }/>
      </C.ModalHeader>
    )
  }

  return (
    <C.Modal
      noPadding
      showPreviousBtn={ state.showPreviousBtn }
      previous={ state.previous }
      visible={ state.visible }
      close={ closePanel }
    >
      { renderBody() }
    </C.Modal>
  )
}

import deleteCardRequest from 'request/payment-method/card-delete'

export default function CardSetting() {
  const { state, mutate } = useStatic({ visible: false })

  h.openCardSetting = async urlQueryParams => {
    state.visible = true
    state.urlQueryParams = urlQueryParams
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body urlQueryParams={ state.urlQueryParams }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      { renderBody() }
    </C.Modal>
  )
}

const Body = ({ urlQueryParams }) => {
  const {
    selectedCard,
    cards,
    paymentMethodChange,
    refreshConfig
  } = usePaymentMethod()
  const t = useI18n()

  const creditCardClick = card => {
    if (selectedCard?.id === card.id) return
    paymentMethodChange('bindedCard', card)
  }

  const deleteCardClick = async card => {
    if (!confirm(t('panel:paymentMethod.confirmDelete'))) return
    await deleteCardRequest(card.id)
    await refreshConfig()
  }

  const openCreateCardPanel = () => {
    h.openCardCreate({ onSuccess: refreshConfig, urlQueryParams })
  }

  return (
    <div className='sm:min-w-[20rem] flex flex-col'>
      <div className='text-center py-4 flex-shrink-0'>{ t('common:cardSetting') }</div>
      <div className='p-5 sm:p-3'>
        <div className='px-2'>
          { cards.map(card =>
            <div key={ card.id } className='flex items-center border-b space-x-3'>
              <div className='cursor-pointer' onClick={ () => deleteCardClick(card) }>
                <Icon.Close className='w-4 h-4'/>
              </div>
              <div className='flex-1 flex items-center justify-between cursor-pointer h-[4.5rem]'
                onClick={ () => creditCardClick(card) }>
                <div className='flex items-center space-x-2'>
                  <C.Image
                    alt='creditcard'
                    className='w-8 h-5'
                    src={ card.$imgUrl }
                  />
                  <div>
                    <div className='text-sm'>****{ card.last4 }</div>
                    <div className='text-gray-400 text-xs'>{ card.bankNameTw }</div>
                  </div>
                </div>
                <Icon.Check className={ cn({ hidden: selectedCard?.id !== card.id }) }/>
              </div>
            </div>
          ) }
          <div className='text-center text-business cursor-pointer pt-5 pb-3'
            onClick={ openCreateCardPanel }>
            { t('panel:paymentMethod.cardCreate') }
          </div>
        </div>
      </div>
    </div>
  )
}

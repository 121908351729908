import useSendPhoneCode from '../action/send-phone-code'
import phoneCallRequest from 'request/auth/phone-call'

export default function factoryPhoneCode({ state, login, register }) {
  return function useStepPhoneCode() {
    const [resendable, updateResendable] = useState(false)
    const [countDown, updateCountDown] = useState(state.codeExpiredAt.countDown())

    const sendPhoneCode = useSendPhoneCode({ state, login, register })
    const phoneCall = async() => {
      const { phone } = state
      const { token } = state.registerForm
      const msg = await phoneCallRequest(phone, token)
      $alert(msg)
    }
    useEffect(() => {
      const interval = setInterval(() => {
        const sendable = state.codeExpiredAt.diff() < 0
        updateCountDown(state.codeExpiredAt.countDown())
        updateResendable(sendable)
        if (!sendable) return
        clearInterval(interval)
      }, 200)
      return () => clearInterval(interval)
    })

    return {
      resendable,
      countDown,
      resendPhoneCode: state.resendPhoneCode,
      sendPhoneCode,
      phoneCall
    }
  }
}

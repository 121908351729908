export default function PanelModifyBookingTime() {
  const { useModifyBookingTime } = useCtx('stylistBooking')
  const panel = usePanel()
  const state = useModifyBookingTime()
  if (!state) return ''
  const {
    date,
    setDate,
    form,
    availableSlots,
    durations,
    submit,
    loadStylistSchedule
  } = state

  const onDateChange = async date => {
    await loadStylistSchedule(date)
    setDate(date)
  }

  return (
    <C.Modal
      visible={ panel.visible('modifyBookingTime') }
      close={ () => panel.close('modifyBookingTime') }
    >
      <div className='sm:w-[25rem]'>
        <div className='text-center pt-1 pb-5'>修改時間</div>
        <C.DatePicker
          className='w-full'
          label='預約日期'
          value={ date }
          onChange={ onDateChange }
        />
        <C.ComboBoxForm
          label='預約時間'
          variant='standard'
          field='startSlot'
          form={ form }
          options={ availableSlots() }
        />
        <C.ComboBoxForm
          label='服務時間'
          variant='standard'
          field='durationSlot'
          form={ form }
          options={ durations() }
        />
        <C.Button uid='modifyBookingTime.submit' className='w-full'
          check={ form } onClick={ submit }>送出</C.Button>
      </div>
    </C.Modal>
  )
}

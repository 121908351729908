import { range } from 'lodash-es'
import reviewCreateRequest from 'request/user-booking/review-create'

export default function StylistReviewCreate() {
  const { state, mutate } = useStatic({
    visible: false,
    bookingId: null,
    callback: null
  })

  h.openStylistReviewCreate = ({ bookingId, callback }) => {
    state.visible = true
    state.bookingId = bookingId
    state.callback = callback
    mutate()
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ () => mutate({ visible: false }) }
    >
      { state.visible && (
        <Body state={ state } mutate={ mutate }/>
      )}
    </C.Modal>
  )
}

const Body = parent => {
  const t = useI18n()
  const { state, mutate } = useStatic({
    ratingCount: 0,
    reviewText: '',
    isSubmit: false
  })
  const { bookingId, callback } = parent.state

  const ratingHint = () => {
    switch (state.ratingCount) {
      case 1:
        return t('panel:stylistReviewCreate.rating.1')
      case 2:
        return t('panel:stylistReviewCreate.rating.2')
      case 3:
        return t('panel:stylistReviewCreate.rating.3')
      case 4:
        return t('panel:stylistReviewCreate.rating.4')
      case 5:
        return t('panel:stylistReviewCreate.rating.5')
      default:
        return t('panel:stylistReviewCreate.clickStar')
    }
  }

  const updateReviewText = value => {
    mutate({ reviewText: value })
  }

  const onStarClick = index => {
    if (index + 1 === state.ratingCount) return
    mutate({ ratingCount: index + 1 })
  }
  const onStarOver = index => {
    if (index + 1 === state.ratingCount) return
    mutate({ ratingCount: index + 1 })
  }

  const submit = () => {
    const { ratingCount, reviewText } = state
    if (!ratingCount) return $alert(t('panel:stylistReviewCreate.pleaseClickStar'), 'error')
    if (!reviewText.trim()) return $alert(t('panel:stylistReviewCreate.pleaseReview'), 'error')
    mutate({ isSubmit: true })
  }

  const finishUploadFile = async filenames => {
    const { ratingCount, reviewText } = state
    try {
      await reviewCreateRequest({
        bookingId,
        ratingCount,
        reviewText: reviewText.trim(),
        filenames
      })
      $alert(t('panel:stylistReviewCreate.success'))
      if (callback) callback()
      parent.mutate({ visible: false })
    } catch (error) {
      mutate({ isSubmit: false })
      throw error
    }
  }

  const Star = ({ color, index }) => <Icon.StarFilled
    className={ cn('h-[2.2rem] w-[2.2rem] cursor-pointer', color || 'text-gray-300') }
    onClick={ () => { onStarClick(index) } }
    onMouseOver={ () => onStarOver(index) }
  />

  const renderStars = () => (
    <>
      { range(5).map(index => {
        const color = index < state.ratingCount ? 'text-yellow-400' : 'text-gray-300'
        return <Star
          key={ index }
          index={ index }
          color={ color }
        />
      }) }
    </>
  )

  const errorUploadFile = (key, params) => {
    $alert(t(key, params), 'error')
    mutate({ isSubmit: false })
  }

  return (
    <div className='sm:w-[22rem] text-gray-700 flex flex-col'>
      <div className='text-center py-1'>{ t('panel:stylistReviewCreate.title') }</div>
      <div className='overflow-scroll'>
        <div className='text-center py-5'>
          <div className='flex justify-center'>
            { renderStars() }
          </div>
          <div className='pt-1 text-sm text-gray-500'>{ ratingHint() }</div>
        </div>
        <div>
          <C.Input
            label={ t('panel:stylistReviewCreate.writeReview') }
            variant='standard'
            multiline
            maxRows='3'
            fullWidth
            value={ state.reviewText }
            onChange={ v => updateReviewText(v) }
          />
        </div>
        <div className='pb-5'>
          <div className='py-2 text-sm text-gray-500'>{ t('panel:stylistReviewCreate.choosePhoto') }</div>
          <div>
            <C.SelectPhoto
              uid='stylistReviewCreate.submit'
              max='6'
              isSubmit={ state.isSubmit }
              onFinished={ finishUploadFile }
              onError={ errorUploadFile }
            />
          </div>
        </div>
      </div>
      <div className='relative'><C.Blur/></div>
      <div className='shrink-0'>
        <C.Button uid='stylistReviewCreate.submit'
          onClick={ submit } className='w-full'>
          { t('panel:stylistReviewCreate.submit') }
        </C.Button>
      </div>

    </div>
  )
}

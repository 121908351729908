import usePayment from 'hooks/user-booking/payment'

export default function PaymentInfo({ booking, openPaymentMethod }) {
  const t = useI18n()
  const { realPayment, prepayAmount, payByMapPay, state } = booking
  const {
    subTotal,
    paymentExcludeFee,
    finalPayment,
    usedDeposit,
    maxRewardUseRate,
    usedReward,
    fee
  } = usePayment(booking)
  const remainDepositTextResolver = usedDeposit => {
    if (booking.payByMapPay) return ''
    const remainDeposit = booking.remainDeposit - usedDeposit
    return ` (${ t('panel:userBooking.remainDeposit') }: ${ remainDeposit.currency() })`
  }

  const showFinalPayment = finalPayment !== realPayment
  const showSubTotal = subTotal && subTotal !== realPayment && subTotal !== finalPayment
  const remainDepositText = remainDepositTextResolver(usedDeposit)

  const renderMaxRewardUseRateText = () => {
    if (maxRewardUseRate >= 1) return t('panel:userBooking.rewardUsed')
    return t('panel:userBooking.rewardUsedRate', { maxRate: maxRewardUseRate * 100 })
  }

  const preparePaymentDetails = () => {
    if (prepayAmount > 0 && state === 1) {
      return [
        [t('panel:userBooking.minPayment'), booking.minPayment.currency()],
        [t('panel:userBooking.prepay'), booking.prepayAmount.currency()]
      ]

    }
    if (state !== 2) return []

    const paymentDetails = [
      [t('panel:userBooking.total'), realPayment.currency()]
    ]
    if (prepayAmount > 0) paymentDetails.push([t('panel:userBooking.prepay'), `-${ prepayAmount.currency() }`])
    if (usedDeposit > 0) paymentDetails.push([t('panel:userBooking.depositUsed'), `-${ usedDeposit.currency() }${ remainDepositText }`])
    if (showSubTotal) paymentDetails.push([t('panel:userBooking.subTotal'), subTotal.currency()])
    if (usedReward) paymentDetails.push([renderMaxRewardUseRateText(), `-${usedReward.currency()}`])
    if (fee > 0) paymentDetails.push([t('panel:userBooking.zerocardFee'), fee.currency()])
    if (showFinalPayment) paymentDetails.push(
      [t('panel:userBooking.final'), finalPayment.currency(), cn({ 'text-red-500 font-bold': !payByMapPay })]
    )

    return paymentDetails
  }

  const showPaymentSelect = finalPayment > 0 && state === 2 && !payByMapPay

  return (
    <>
      <C.PaymentDetail
        title={ t('panel:userBooking.payment') }
        items={ preparePaymentDetails() }
      />
      { showPaymentSelect && (
        <C.PaymentSelect
          type='checkoutBooking'
          amount={ paymentExcludeFee }
          openPaymentMethod={ openPaymentMethod }
        />
      )}
    </>
  )
}

export default function useSendPhoneCode({ state, login, register }) {
  const { setLoading } = useLoading()

  return async password => {
    if (password.length !== 6) return
    setLoading('panel.auth.sendPhoneCode')
    const send = async() => {
      if (state.action === 'login') {
        await login({
          type: 'phone',
          key: state.phone,
          password
        })
        return
      }
      await register({
        code: password,
        ...state.registerForm
      })
    }

    try {
      await send()
    } finally {
      setLoading(null)
    }
  }
}


import depositConfigRequest from 'request/buy-deposit/config'
import DescriptionBlock from './description'
import Payment from './payment'
import usePayment from 'hooks/deposit/payment'
import useBuyDeposit from 'hooks/deposit/buy'

export default function BuyDeposit() {
  const { state, mutate } = useStatic({
    visible: false,
    stylistId: 0,
    stylistUserId: 0,
    stylistName: '',
    isStylistEvent: false,
    remainDeposit: 0,
    mapPayRewards: 0,
    maxRewardUseRate: 0,
    maxRewardUseRateText: '',
    description: '',
    policy: [],
    couponDescription: ''
  })
  const t = useI18n()

  h.openBuyDeposit = async({ stylistId, stylistUserId, stylistName, isStylistEvent }) => {
    state.stylistId = stylistId
    state.stylistUserId = stylistUserId
    state.stylistName = stylistName
    state.isStylistEvent = isStylistEvent
    await requestDepositConfig()
    state.visible = true
    mutate()
  }

  const requestDepositConfig = async() => {
    const config = await depositConfigRequest(state.stylistId)
    const { remainDeposit, mapPayRewards, maxRewardUseRate, discountText, policy, couponDescription } = config
    state.remainDeposit = remainDeposit
    state.mapPayRewards = mapPayRewards
    state.maxRewardUseRate = maxRewardUseRate
    state.maxRewardUseRateText = maxRewardUseRate >= 1 ? t('panel:buyDeposit.rewardUsed') : t('panel:buyDeposit.rewardUsedRate', { maxRate: maxRewardUseRate * 100 })
    state.description = discountText.description
    state.couponDescription = couponDescription
    state.policy = policy
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body
      t={ t }
      { ...state }
      closePanel={ closePanel }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ t('panel:buyDeposit.title', {
        name: state.stylistName,
        interpolation: { escapeValue: false }
      }) }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({
  remainDeposit,
  mapPayRewards,
  maxRewardUseRate,
  maxRewardUseRateText,
  description,
  policy,
  couponDescription,
  closePanel,
  stylistId,
  stylistUserId,
  stylistName,
  isStylistEvent,
  t
}) => {
  const form = useForm({ depositAmount: { rule: 'notZero', value: 2500 } })
  const { finalPayment, usedReward, fee } = usePayment({
    depositAmount: form.depositAmount.value,
    mapPayRewards,
    maxRewardUseRate
  })
  const buyDeposit = useBuyDeposit({
    stylistUserId,
    depositAmount: form.depositAmount.value,
    finalPayment: finalPayment,
    isStylistEvent,
    closePanel: closePanel
  })

  const openPaymentMethod = () => h.openPaymentMethod({
    type: 'buyDeposit',
    path: window.location.pathname,
    source: {
      stylistId,
      stylistUserId,
      stylistName,
      isStylistEvent
    }
  }, 'buyDeposit')

  return (
    <>
      <div className='p-5 overflow-scroll'>
        <div className='space-y-5'>
          <DescriptionBlock description={ description } couponDescription={ couponDescription } t={ t }/>
          <div>
            <RemainDeposit remainDeposit={ remainDeposit } t={ t } />
            <DepositAmountInput form={ form } t={ t } />
            <Payment
              finalPayment={ finalPayment }
              usedReward={ usedReward }
              fee={ fee }
              maxRewardUseRateText={ maxRewardUseRateText }
              t={ t }
            />
            <C.PaymentSelect
              type='buyDeposit'
              openPaymentMethod={ openPaymentMethod }
              amount={ finalPayment - fee }
            />
          </div>
          <C.PaymentPolicy title={ t('panel:buyDeposit.policy') } policy={ policy } />
        </div>
      </div>
      <div className='relative'><C.Blur/></div>
      <div className='flex-shrink-0 p-5 pt-0'>
        <C.Button check={ form } uid='buyDeposit.submit' fullWidth
          onClick={ buyDeposit }>
          { t('panel:buyDeposit.submit') }
        </C.Button>
      </div>
    </>
  )
}

const RemainDeposit = ({ remainDeposit, t }) =>
  <div className='flex items-center'>
    <div className='font-bold flex-1'>{ t('panel:buyDeposit.remainDeposit') }</div>
    <div>{ remainDeposit.currency() }</div>
  </div>

const DepositAmountInput = ({ t, form, onDepositAmountChange }) =>
  <div className='flex items-center space-x-2'>
    <div className='font-bold flex-1'>{ t('panel:buyDeposit.depositAmount') }</div>
    <div>$</div>
    <div className='pt-7 w-20'>
      <C.InputForm
        variant='standard'
        form={ form }
        field='depositAmount'
        onChange={ onDepositAmountChange }
        autoFocus
        number
        right
      />
    </div>
  </div>

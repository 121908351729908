import All from './all'
import fetchTags from './fetch-tags'

export default function Tag(parent) {
  const { state, mutate } = useStatic({
    active: null,
    tags: [],
    finish: false,
    fetching: true,
    init: false
  })

  const previous = () => {
    parent.state.childTitle = null
    parent.state.showPreviousBtn = false
    parent.mutate()
    state.active = null
    mutate()
  }

  const hidden = parent.state.active !== 'tag' || state.active
  useEffect(() => {
    if (hidden) return
    parent.state.previous = previous
    if (state.init) return
    state.init = true
    fetchTags({ parent, state, mutate })
  }, [hidden])

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    fetchTags({ parent, state, mutate })
  })

  const selectTag = tag => {
    parent.state.childTitle = tag.name
    parent.state.showPreviousBtn = true
    parent.mutate()
    state.active = 'all'
    state.type = parent.state.type
    state.id = parent.state.id
    state.tagId = tag.id
    mutate()
  }

  const renderPhotos = () => {
    if (!state.active) return
    return (<All
      state={ state }
      mutate={ mutate }
      className={ parent.className }
    />
    )
  }

  return (
    <>
      <div className={ cn('overflow-scroll overflow-x-hidden', parent.className, { hidden }) }
        onScroll={ onScroll() }
      >
        { state.tags.map(tag => (
          <Row key={ tag.id } tag={ tag } selectTag={ selectTag }/>
        ))}
        { state.fetching && <div className='py-5'><C.Loading /></div> }
      </div>
      { renderPhotos() }
    </>
  )
}

const Row = ({ tag, selectTag }) => (
  <div onClick={ () => selectTag(tag) } className='hover:bg-gray-100 cursor-pointer mb-3'>
    <div className='font-medium flex items-center p-2 select-none text-sm text-gray-700'>
      <div className='px-2'>#</div>{ tag.name }
    </div>
    <div className='flex relative -mx-[1px]'>
      { tag.covers.map(post => (
        <LongPhoto key={ post.id } src={ post.firstPhoto.url } alt={ tag.name }/>
      )) }
    </div>
  </div>
)

const LongPhoto = ({ src, alt }) => (
  <div className='w-1/3 relative px-[1px]'>
    <C.Image
      src={ src }
      alt={ alt }
      className='pt-[133%]'
    />
  </div>
)

const template = `
  query Bookings($input: PaginationInput!, $key: String!) {
    admin {
      user(key: $key) {
        bookings(input: $input) {
          id
          startTime
          endTime
          realPayment
          state
          payByMapPay
          isCommented
          stylist{
            user{
              id
              name
            }
          }
          services{
            service{
              name
            }
          }
        }
      }
    }
  }
`

export default async function bookingRecords({ offset, limit, userId }) {
  const input = { limit, offset }
  const { admin } = await request(template, { input, key: userId })
  return admin.user.bookings
}

const template = `
  query Config($courseId: ID!) {
    courseConfig(courseId: $courseId) {
      courseTitle
      coursePrice
      teacherName
      startTime
      endTime
      address
      location
      earlyPrice
      subsidyAmount
    }
  }
`

export default async function config(courseId) {
  const { courseConfig } = await request(template, { courseId })
  return courseConfig
}

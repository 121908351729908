import tagCreateRequest from 'request/common/tag-create'

export default function TagCreate() {
  const t = useI18n()
  const { state, mutate } = useStatic({
    category: 'hair',
    visible: false,
    callback: null
  })

  h.openTagCreate = ({ category, callback }) => {
    state.category = category
    state.visible = true
    state.callback = callback
    mutate()
  }

  const closePanel = () => mutate({ visible: false })

  const renderBody = () => {
    if (!state.visible) return
    return <Body state={ state } closePanel={ closePanel } t={ t }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ t('panel:tagCreate:title') }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = parent => {
  const form = useForm({ name: { rule: 'required' } })
  const { t } = parent
  const { category, callback } = parent.state
  const submit = async() => {
    const tagName = form.name.value
    await tagCreateRequest(tagName, category)
    if (callback) callback(tagName)
    parent.closePanel()
  }
  return (
    <div className='flex-1 overflow-scroll p-5 flex flex-col space-y-5'>
      <div>
        <C.InputForm
          label={ t('panel:tagCreate.name') }
          variant='standard'
          form={ form }
          field='name'
          fullWidth
        />
      </div>
      <div className='relative'><C.Blur/></div>
      <div className='shrink-0'>
        <C.Button uid='tagCreate.submit' check={ form }
          onClick={ submit } className='w-full'>
          { t('panel:tagCreate.submit') }
        </C.Button>
      </div>
    </div>
  )
}

import cardCreateRequest from 'request/payment-method/card-create'

export default function useCardCreate() {

  return async function cardCreate({ source }) {
    const tappayStatus = TPDirect.card.getTappayFieldsStatus()
    if (!tappayStatus.canGetPrime) {
      $alert('信用卡設定錯誤', 'error')
      return
    }

    const getPrimeFromTappay = () => new Promise((resolve, reject) => TPDirect.card.getPrime(result => {
      if (result.status !== 0) {
        $alert(result.msg, 'error')
        reject(result.msg)
      }
      resolve(result.card.prime)
    }))

    const prime = await getPrimeFromTappay()

    try {
      await cardCreateRequest(prime, source)
    } catch (error) {
      switch (error.code) {
        case 'payment.need3DAuth':
          const { paymentUrl } = error.params
          if (!paymentUrl) return
          window.location = paymentUrl
        default:
          throw error
      }
    }
  }
}

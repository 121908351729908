import messageCreateRequest from 'request/save-message/create'
import messageUpdateRequest from 'request/save-message/update'

export default function SaveMessageCreateOrUpdate() {
  const { state, mutate } = useStatic({
    visible: false,
    title: '',
    message: null,
    callback: null,
    isEdit: false
  })

  h.openSaveMessageCreateOrUpdate = ({ message, callback }) => {
    state.message = message
    state.title = !!message ? '編輯預存回覆' : '新增預存回覆'
    state.visible = true
    state.callback = callback
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body state={ state } closePanel={ closePanel } />
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ state.title }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({ state, closePanel }) => {

  const form = useForm({
    title: { rule: 'required', value: state.message?.title || '' },
    message: { rule: 'required', value: state.message?.message || '' }
  })

  const submit = async() => {
    const { title, message } = form

    const input = {}
    input.title = title.value.trim()
    input.message = message.value.trim()

    if (!state.message) {
      await messageCreateRequest(input)
      finishUpdate()
      return
    }

    input.id = state.message.id
    await messageUpdateRequest(input)
    state.message.title = input.title
    state.message.message = input.message
    finishUpdate()
  }

  const finishUpdate = () => {
    const msg = state.isEdit ? '修改成功' : '新增成功'
    $alert(msg)
    if (state.callback) state.callback()
    closePanel()
  }

  return (
    <>
      <div className='flex-1 overflow-scroll p-5 flex flex-col space-y-5'>

        <C.InputForm
          label='標題'
          variant='standard'
          form={ form }
          field='title'
          fullWidth
        />

        <C.InputForm
          label='訊息'
          variant='standard'
          form={ form }
          field='message'
          multiline
          fullWidth
        />

      </div>
      <div className='relative'><C.Blur/></div>
      <div className='shrink-0 p-5'>
        <C.Button uid='saveMessageCreateOrUpdate.submit' check={ form } onClick={ submit } className='w-full'>
          送出
        </C.Button>
      </div>
    </>
  )
}
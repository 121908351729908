import customerBookingsRequest from 'request/stylist-booking/customer-bookings'

export default async function fetchCustomerBookings({ customerId, state, mutate, bookingStateDic, t }) {
  const limit = 20
  state.fetching = true
  mutate()
  try {
    const fetched = await customerBookingsRequest({
      customerId,
      offset: state.bookings.length,
      limit
    })
    const idDic = state.bookings.keyBy('id')
    fetched.forEach(booking => {
      if (idDic[booking.id]) return
      formatter({ booking, bookingStateDic, t })
      state.bookings.push(booking)
    })

    state.finish = fetched.length < limit

  } finally {
    state.fetching = false
    mutate()
  }
}

const formatter = ({ booking, bookingStateDic, t }) => {

  const stateResolver = bookingState => {
    if (booking.payByMapPay) return 'paid'
    if (bookingState === 2 && booking.realPayment > 0) return 'notPaid'
    return bookingStateDic[bookingState] || 'unknown'
  }
  const stateColor = bookingState => {
    switch (bookingState) {
      case 'checked':
        return 'text-green-500'
      case 'notPaid':
        return 'text-red-400'
      case 'paid':
        return 'text-black'
      default:
        return 'text-gray-600'
    }
  }

  const { services, state, payByMapPay, realPayment } = booking
  booking.$serviceText = services.map(({ service }) => service.name).join(', ')
  const bookingState = stateResolver(state)
  booking.$i18nState = t(`common:booking.state.${ bookingState }`)
  booking.$stateColor = stateColor(bookingState)
  if (!payByMapPay) return
  booking.$i18nState = `${booking.$i18nState} ${t('panel:bookingRecords.amount', { amount: realPayment.currency() })}`
}
const template = `
  query Deposits($key: String!) {
    admin {
      user(key: $key) {
        deposits {
          id
          amount
          coupon
          stylist {
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default async function adminDeposits(userId) {
  const { admin } = await request(template, { key: userId })
  return admin.user.deposits
}

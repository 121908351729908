const template = `
  query CustomerBookings($customerId: ID!, $input: PaginationInput!){
    customerBookings(id: $customerId, input: $input){
      id
      startTime
      realPayment
      state
      payByMapPay
      notes
      services{
        service{
          name
        }
      }
    }
  }
`

export default async function customerBookings({ customerId, offset, limit }) {
  const input = { offset, limit }
  const { customerBookings } = await request(template, { customerId, input })
  return customerBookings
}

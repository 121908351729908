const template = `
  query SaveMessages($keyword: String!, $input: PaginationInput!) {
    saveMessages(keyword: $keyword, input: $input) {
      id
      title
      message
      clickCount
    }
  }
`

export default async function saveMessages({ keyword = '', offset, limit }) {
  const input = { limit, offset }

  const { saveMessages } = await request(template, { keyword, input })
  return saveMessages
}

export default function useBookingFormatter(isCustomer) {
  const t = useI18n()
  const { bookingStateDic } = useBookingConfig()
  return booking => {
    const stateResolver = bookingState => {
      if (booking.payByMapPay) return 'paid'
      if (bookingState === 2 && booking.realPayment > 0) return 'notPaid'
      if (isCustomer && bookingState === 3) return 'cancel'
      return bookingStateDic[bookingState] || 'unknown'
    }
    const stateColor = bookingState => {
      switch (bookingState) {
        case 'checked':
          return 'text-green-500'
        case 'notPaid':
          return 'text-red-400'
        case 'paid':
          return 'text-black'
        default:
          return 'text-gray-600'
      }
    }

    const { startTime, endTime, state, payByMapPay, realPayment } = booking

    booking.$timeText = `${ startTime.ymdhm() } - ${ endTime.hm() }`
    booking.$serviceText = booking.services.map(({ service }) => service.name).join(', ')
    const bookingState = stateResolver(state)
    booking.$i18nState = t(`common:booking.state.${ bookingState }`)
    booking.$stateColor = stateColor(bookingState)
    if (!payByMapPay) return
    booking.$i18nState = `${booking.$i18nState} ${t('panel:bookingRecords.amount', { amount: realPayment.currency() })}`
    return booking
  }
}

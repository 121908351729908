export default function formatter({ state }) {
  const { stylist } = state.user
  stylist.$rating = stylist.rating.toFixed(1)
  stylist.$ratingCount = stylist.totalRatingCount.format()

  const { studio } = state
  studio.$rating = studio.rating.toFixed(1)
  studio.$ratingCount = studio.totalRatingCount.format()

  state.reviews.map(review => {
    const name = review.user.name.substring(0, 1)
    review.$reviewerName = `${name}****`
    review.$rating = review.rating
    review.$largePhotos = review.photos.map(photo => photo.url.large)
    if (!review.booking) return review
    const { bookingDiscount, services } = review.booking
    if (bookingDiscount?.campaign) {
      review.$serviceText = bookingDiscount.campaign.name
      return review
    }
    review.$serviceText = services.map(({ service }) => service.name).join(', ')
    return review
  })

  // const serviceMap = store.serviceCategories.reduce((dic, category) => {
  //   dic[category] = { infos: [], minPrice: 999999, maxPrice: -2, priceText: '' }
  //   return dic
  // }, {})

  // stylistServices.forEach(stylistService => {
  //   const { category } = stylistService.service
  //   categoryCheck(stylistService, [category], serviceMap)
  // })

  // for (const key in serviceMap) {
  //   const { minPrice } = serviceMap[key]
  //   if (minPrice === 999999) serviceMap[key].minPrice = -1
  // }

  // for (const key in serviceMap) {
  //   const { minPrice, maxPrice } = serviceMap[key]
  //   serviceMap[key].priceText = getPriceRangeText(minPrice, maxPrice, t)
  // }

  // const services = []
  // for (const name in serviceMap) {
  //   const { infos, priceText } = serviceMap[name]
  //   if (!infos.length) continue
  //   services.push({ name, priceText })
  // }
  // state.services = services
}

// const categoryCheck = (info, categories, categoryDic) => {
//   let isMatch = false
//   for (const category of categories) {
//     if (!categoryDic[category]) continue
//     addInfoToDic(info, categoryDic, category)
//     isMatch = true
//   }
//   if (isMatch) return
//   addInfoToDic(info, categoryDic, 'other')
// }

// const addInfoToDic = (info, dic, key) => {
//   const { infos, minPrice, maxPrice } = dic[key]
//   infos.push(info)
//   if (info.minPrice >= 0 && info.minPrice < minPrice) dic[key].minPrice = info.minPrice
//   if (maxPrice != -1 && info.maxPrice > 0 && info.maxPrice > maxPrice) dic[key].maxPrice = info.maxPrice
//   if (info.maxPrice < 0) dic[key].maxPrice = -1
// }

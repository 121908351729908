import TextField from '@mui/material/TextField'

export default function Prepay() {
  const t = useI18n()
  const {
    service,
    bookingDetails,
    userDetails,
    paymentDetails,
    prepayPolicy,
    notesChange,
    openPaymentMethod,
    finalPayment,
    subtotal
  } = useCtx('prepayBooking')

  return (
    <div className='space-y-5'>
      <C.PaymentDetail title={ t('panel:prepayBooking.detail.book') } items={ bookingDetails } />
      <C.PaymentDetail title={ t('panel:prepayBooking.detail.user') } items={ userDetails } />
      { service.$type === 'discount' && (
        <C.PaymentDiscount
          title={ service.$title }
          originalRange={ service.$originalRange }
          remark={ service.$remark }
          range={ service.$range }
          description={ service.description }
        />
      )}
      <RemarkBlock notesChange={ notesChange } t={ t } />
      <C.PaymentDetail title={ t('panel:prepayBooking.detail.payment') } items={ paymentDetails } />
      { finalPayment > 0 && (
        <C.PaymentSelect
          type='prepayBooking'
          openPaymentMethod={ openPaymentMethod }
          amount={ subtotal }
        />
      )}
      <C.PaymentPolicy title={ t('panel:prepayBooking.detail.policy') } policy={ prepayPolicy } />
    </div>
  )
}

const RemarkBlock = ({ notesChange, t }) => (
  <div className='pb-2'>
    <div className='font-bold'>{ t('panel:prepayBooking.detail.remark') }</div>
    <div className='py-3'>
      <TextField
        fullWidth
        multiline
        placeholder={ t('panel:prepayBooking.detail.option') }
        onChange={ notesChange }
      />
    </div>
  </div>
)

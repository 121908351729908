import Schedule from 'components/common/schedule'

export default function Time(){
  const {
    startTime,
    stylistUserId,
    service,
    isModel,
    others,
    duration,
    selectTimeSlot
  } = useCtx('prepayBooking')

  const serviceIdsResolver = () => {
    if (service.$type === 'discount') return []
    const serviceId = parseInt(service.service.id)
    const addServiceIds = others.map(o => parseInt(o.service.id))
    return [serviceId, ...addServiceIds]
  }
  const serviceIds = serviceIdsResolver()

  return (
    <div>
      <Schedule
        startAt={ startTime }
        userId={ stylistUserId }
        duration={ duration }
        campaignId={ service.campaign?.id }
        serviceIds={ serviceIds }
        isModel={ isModel }
        onClick={ selectTimeSlot }
      />
    </div>
  )
}

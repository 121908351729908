
export default function DescriptionBlock({ description, couponDescription, t }) {
  return <div className='pb-2'>
    <div className='font-bold'>{ t('panel:buyDeposit.description') }</div>
    <div className='py-3'>
      <div className=' text-sm' { ...description.rawHtml() } />
    </div>
    { !!couponDescription && (
      <div className='text-red-500 text-sm' { ...couponDescription.rawHtml() } />
    )}
  </div>
}

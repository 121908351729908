import useStoreState from './state'

import useStepLogin from './factory/login'
import useStepRegister from './factory/register'
import useStepPhoneCode from './factory/phone-code'

const Context = ctx('panelAuth')

const useStore = () => useStoreFactory(
  useStoreState,
  {
    useStepLogin,
    useStepRegister,
    useStepPhoneCode
  }
)

export { Context, useStore }

export default function PanelModifyBookingRemark() {
  const { useModifyBookingRemark } = useCtx('stylistBooking')
  const [isSubmit, setIsSubmit] = useState(false)
  const t = useI18n()
  const panel = usePanel()
  const state = useModifyBookingRemark()
  if (!state) return ''
  const {
    setRemark,
    remark,
    noteSmallImageUrls,
    submit
  } = state

  const finishUploadFile = async filenames => {
    try {
      await submit(filenames)
    } catch (error) {
      throw error
    } finally {
      setIsSubmit(false)
    }
  }

  const errorUploadFile = (key, params) => {
    $alert(t(key, params), 'error')
    setIsSubmit(false)
  }

  return (
    <C.Modal
      visible={ panel.visible('modifyBookingRemark') }
      close={ () => panel.close('modifyBookingRemark') }
      noPadding
    >
      <C.ModalHeader title='修改備註'>
        <>
          <div className='overflow-auto p-5'>
            <C.Input
              className='w-full'
              label='預約備註'
              variant='standard'
              value={ remark }
              onChange={ value => setRemark(value) }
              multiline
            />
            <div className='pt-5 pb-2 text-xs text-black/60'>附加資料</div>
            <div className='pb-5'>
              <C.SelectPhoto
                uid='bookingNoteImage.submit'
                max='15'
                isSubmit={ isSubmit }
                onFinished={ finishUploadFile }
                onError={ errorUploadFile }
                defaultImages={ noteSmallImageUrls }
              />
            </div>
          </div>
          <div className='relative'><C.Blur/></div>
          <div className='flex shrink-0 p-5'>
            <C.Button uid='modifyBookingRemark.submit' className='w-full'
              onClick={ () => { setIsSubmit(true) } }>送出</C.Button>
          </div>
        </>
      </C.ModalHeader>
    </C.Modal>
  )
}

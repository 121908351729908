import useCreateCard from 'hooks/payment-method/create-card'

export default function CreateCreditCard() {
  const { state, mutate } = useStatic({
    visible: false,
    callback: null
  })
  const createCard = useCreateCard()

  h.openCardCreate = async({ onSuccess, urlQueryParams }) => {
    state.visible = true
    state.urlQueryParams = urlQueryParams
    state.onSuccess = onSuccess
    mutate()

    mkt.bindCardOpen()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body
      urlQueryParams={ state.urlQueryParams }
      onSuccess={ state.onSuccess }
      createCard={ createCard }
      closePanel={ closePanel }
    />
  }
  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      { renderBody() }
    </C.Modal>
  )
}

const Body = ({ urlQueryParams, onSuccess, createCard, closePanel }) => {
  const t = useI18n()

  useEffect(() => {
    TPDirect.card.setup({
      fields: {
        number: {
          element: ref1.current,
          placeholder: t('panel:createCreditCard.cardNo')
        },
        expirationDate: {
          element: ref2.current,
          placeholder: t('panel:createCreditCard.monthYear')
        },
        ccv: {
          element: ref3.current,
          placeholder: t('panel:createCreditCard.ccv')
        }
      },
      styles: {
        // Style all elements
        'input': { 'color': 'gray' },
        // Styling ccv field
        'input.ccv': { 'font-size': '16px' },
        // Styling expiration-date field
        'input.expiration-date': { 'font-size': '16px' },
        // Styling card-number field
        'input.card-number': { 'font-size': '16px' },
        // style focus state
        ':focus': { 'color': 'black' },
        // style valid state
        '.valid': { 'color': 'black' },
        // style invalid state
        '.invalid': { 'color': 'red' }
      },
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11
      }
    })
  }, [])

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)

  const submit = async() => {
    const source = JSON.stringify(urlQueryParams)
    await createCard({ source })
    await createCardSuccess()
  }

  const createCardSuccess = async() => {
    onSuccess()
    closePanel()
    mkt.bindCardSuccess()
  }

  return (
    <div className='sm:max-w-[25rem] flex flex-col'>
      <div className='text-center py-4 flex-shrink-0'>{ t('panel:createCreditCard.title') }</div>
      <div className='p-5'>
        <div ref={ ref1 } className='rounded-md w-full h-10 border border-gray-400 p-2'/>
        <div className='flex justify-between space-x-3 pt-6'>
          <div ref={ ref2 } className='rounded-md w-full h-10 border border-gray-400 p-2'/>
          <div ref={ ref3 } className='rounded-md w-full h-10 border border-gray-400 p-2'/>
        </div>
        <p className='text-xs pt-4 pb-2'>
          本公司採用喬睿科技TapPay金流交易系統，消費者刷卡時直接在銀行端系統中交易，本公司不會留下您的信用卡資料，以保障您的權益，資料傳輸過程採用嚴密的SSL 2048 加密技術保護，請你放心。
        </p>
      </div>
      <div className='p-5 flex-shrink-0'>
        <C.Button
          uid='createCard.submit'
          fullWidth
          onClick={ () => submit() }
        >
          { t('panel:createCreditCard.submit') }
        </C.Button>
      </div>
    </div>
  )
}

import saveMessagesRequest from 'request/save-message/query'

const limit = 20

export default async function fetchSaveMessages({ state, mutate }) {
  const { uid } = state
  try {
    if (state.uid !== uid) return
    state.fetching = true
    mutate()
    const fetched = await saveMessagesRequest({
      keyword: state.keyword,
      offset: state.offset,
      limit
    })

    if (state.uid !== uid) return
    const idDic = state.messages.keyBy('id')
    fetched.forEach(message => {
      if (idDic[message.id]) return
      state.messages.push(message)
    })
    state.offset += limit
    state.finish = fetched.length < limit
  } finally {
    if (state.uid !== uid) return
    state.fetching = false
    mutate()
  }
}
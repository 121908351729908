const template = `
  query GuaranteeReviews($queryParams: ReviewQueryParams!, $paginationInput: PaginationInput!) {
    reviews(type: booking, queryType: guarantee, queryParams: $queryParams, input: $paginationInput) {
      id
      user {
        id
        name
        avatar
      }
      review
      rating
      createdAt
      replyCount
      latestReplies {
        id
        reply
        createdAt
        replyUser {
          id
          name
          avatar
        }
        shouldHideName
      }
      photos {
        url {
          large
          small
        }
      }
      booking {
        services {
          service {
            name
          }
        }
        stylistUser {
          id
          name
          avatar
        }
        studio {
          address
        }
        bookingDiscount {
          campaign {
            name
          }
        }
      }
    }
  }
`

export default async function guaranteeReviews({ queryParams, offset, limit }) {
  const paginationInput = { offset, limit }
  const { reviews } = await request(template, { queryParams, paginationInput })
  return reviews
}

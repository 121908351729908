const template = `
  query Config($stylistId: ID!) {
    me {
      mapPayRewards
    }
    depositConfig(stylistId: $stylistId) {
      remainDeposit
      maxRewardUseRate
      couponDescription
      discountText {
        description
        footer
      }
      policy {
        title
        content
      }
    }
  }
`

export default async function config(stylistId) {
  let { me, depositConfig } = await request(template, { stylistId })
  depositConfig.mapPayRewards = me.mapPayRewards
  return depositConfig
}

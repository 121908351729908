const template = `
  query($id: ID!, $userId: ID) {
    vendorProductOrderDetail(id: $id, userId: $userId) {
      isOfficialStock
      vendors {
        name
        amount
        shipFee
        items {
          name
          price
          brand
          cover {
            small {
              url
            }
          }
          spec
          count
          amount
        }
      }
    }
  }
`

export default async function receiptDetail({ orderId, userId }) {
  const { vendorProductOrderDetail } = await request(template, { id: orderId, userId })
  return vendorProductOrderDetail
}

import useUserBooking from 'hooks/user-booking'
import PaymentInfo from './payment-info'
import Button from './button'

export default function UserBooking() {
  const { state, mutate } = useStatic({ visible: false, callback: null })
  const t = useI18n()
  const { prepayPolicy, getBooking, openPaymentMethod, refreshBooking } = useUserBooking()

  h.openUserBooking = async(bookingId, callback) => {
    await refreshBooking(bookingId)
    state.callback = callback
    state.visible = true
    mutate()
  }

  const closePanel = () => {
    mutate({ visible: false })
  }

  const refresh = async bookingId => {
    await refreshBooking(bookingId)
    const booking = getBooking()
    if (state.callback) state.callback(booking)
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body booking={ getBooking() }
      prepayPolicy={ prepayPolicy }
      openPaymentMethod={ openPaymentMethod }
      closePanel={ closePanel }
      refreshBooking={ refresh }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ t('panel:userBooking.title') }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({
  booking,
  prepayPolicy,
  closePanel,
  refreshBooking,
  openPaymentMethod
}) => {
  const t = useI18n()
  const {
    $studioDetails,
    $serviceTitle,
    $serviceDetails,
    $productDetails,
    discount
  } = booking

  return <>
    <div className='p-5 overflow-scroll'>
      <div className='space-y-5'>
        <StylistBlock booking={ booking } t={ t }/>
        <C.PaymentDetail title={ t('panel:userBooking.bookStudio') } items={ $studioDetails } />
        <C.PaymentDetail title={ $serviceTitle } items={ $serviceDetails } />
        <C.PaymentDetail title={ t('panel:userBooking.buyProduct') } items={ $productDetails } />
        { discount && (
          <C.PaymentDiscount
            title={ discount.$title }
            originalRange={ discount.$originalRange }
            remark={ discount.$remark }
            range={ discount.$range }
            description={ discount.description }
          />
        )}
        <PaymentInfo booking={ booking }
          openPaymentMethod={ openPaymentMethod }
        />
        <C.PaymentPolicy title={ t('panel:prepayBooking.detail.policy') } policy={ prepayPolicy } />
      </div>
    </div>
    <div className='relative'><C.Blur/></div>
    <Button
      booking={ booking }
      closePanel={ closePanel }
      refreshBooking={ refreshBooking }
    />
  </>
}

const StylistBlock = ({ booking, t }) => {
  const { $serviceDate, $serviceTime, stylist, $i18nState, $stateColor } = booking
  return (
    <div className='pt-2 pb-5 flex space-x-3 border-b'>
      <div>
        <C.Image
          className='w-20 h-20 rounded-full'
          src={ stylist.user.avatar }
        />
      </div>
      <div className='text-right text-sm space-y-1'>
        <div>{t('panel:userBooking.date')}</div>
        <div>{t('panel:userBooking.time')}</div>
        <div>{t('panel:userBooking.stylist')}</div>
        <div>{t('panel:userBooking.state')}</div>
      </div>
      <div className='text-sm space-y-1'>
        <div>{$serviceDate}</div>
        <div>{$serviceTime}</div>
        <div>{stylist.user.name}</div>
        <div className={ $stateColor }>{ $i18nState }</div>
      </div>
    </div>
  )
}

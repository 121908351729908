const template = `
  mutation ReviewCreate($input: ReviewCreateInput!) {
    reviewCreate(input: $input)
  }
`

export default async function reviewCreate({ bookingId, ratingCount, reviewText, filenames }) {
  const input = {
    type: 'booking',
    id: bookingId,
    rating: ratingCount,
    review: reviewText,
    filenames
  }
  await request(template, { input })
}

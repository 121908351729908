import tagsRequest from 'request/collection/tags'

export default async function fetchTags({ parent, state, mutate }) {
  const limit = 20
  state.fetching = true
  mutate()
  try {
    const fetched = await tagsRequest({
      type: parent.state.type,
      userId: parent.state.id,
      offset: state.tags.length,
      limit
    })

    const idDic = state.tags.keyBy('id')
    fetched.forEach(tag => {
      if (idDic[tag.id]) return
      tag.key = tag.id
      tag.covers = tag.covers.slice(0, 3)
      state.tags.push(tag)
    })

    state.finish = fetched.length < limit

  } finally {
    state.fetching = false
    mutate()
  }
}
import courseConfigRequest from 'request/course/config'
import Payment from './payment'
import usePayment from 'hooks/course/payment'
import useBuyCourse from 'hooks/course/buy'

export default function BuyCourse() {
  const { state, mutate } = useStatic({
    visible: false,
    quantity: 0,
    courseId: 0,
    courseTitle: '',
    courseDetails: [],
    price: 0,
    callback: null
  })
  const t = useI18n()

  h.openBuyCourse = async({ courseId, quantity, callback }) => {
    state.quantity = quantity
    state.courseId = courseId
    await requestCourseConfig()
    state.visible = true
    state.callback = callback
    mutate()
  }

  const requestCourseConfig = async() => {
    const config = await courseConfigRequest(state.courseId)
    const { courseTitle, coursePrice, teacherName, startTime, endTime, address, location, earlyPrice, subsidyAmount } = config

    state.courseTitle = courseTitle
    state.coursePrice = coursePrice
    state.earlyPrice = earlyPrice
    state.subsidyAmount = subsidyAmount

    state.courseDetails = [
      [t('panel:buyCourse.startTime'), startTime.ymdwhm()],
      [t('panel:buyCourse.endTime'), endTime.ymdwhm()],
      [t('panel:buyCourse.location'), location],
      [t('panel:buyCourse.address'), address],
      [t('panel:buyCourse.teacher'), teacherName]
    ]

    if (earlyPrice) state.courseDetails.push([t('panel:buyCourse.earlyPrice'), state.earlyPrice.currency()])
    else state.courseDetails.push([t('panel:buyCourse.price'), state.coursePrice.currency()])

    state.courseDetails.push([t('panel:buyCourse.quantity'), state.quantity])

    if (!location) state.courseDetails.splice(2, 1)
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body
      t={ t }
      { ...state }
      closePanel={ closePanel }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ t('panel:buyCourse.title', {
        title: state.courseTitle,
        interpolation: { escapeValue: false }
      }) }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({
  closePanel,
  courseId,
  courseDetails,
  quantity,
  coursePrice,
  earlyPrice,
  subsidyAmount,
  t,
  callback
}) => {

  const renderPrice = () => {
    if (earlyPrice) return earlyPrice * quantity
    if (subsidyAmount) return coursePrice - subsidyAmount
    return coursePrice * quantity
  }

  const { finalPayment, fee } = usePayment(renderPrice())
  const buyCourse = useBuyCourse({
    courseId,
    quantity,
    finalPayment,
    callback: ({ status }) => {
      closePanel()
      callback(status)
    }
  })

  const openPaymentMethod = () => h.openPaymentMethod({
    type: 'buyCourse',
    path: window.location.pathname,
    source: {
      courseId,
      quantity
    }
  }, 'buyCourse')

  return (
    <>
      <div className='p-5 overflow-scroll'>
        <div className='space-y-5'>
          <div>
            <C.PaymentDetail title={ t('panel:buyCourse.detail') } items={ courseDetails } />
            <Payment
              finalPayment={ finalPayment }
              subsidyAmount={ subsidyAmount }
              fee={ fee }
              t={ t }
            />
            <C.PaymentSelect
              type='buyCourse'
              openPaymentMethod={ openPaymentMethod }
              amount={ finalPayment - fee }
            />
          </div>

        </div>
      </div>
      <div className='relative'><C.Blur/></div>
      <div className='flex-shrink-0 p-5 pt-0'>
        <C.Button uid='buyCourse.submit' fullWidth
          onClick={ buyCourse }>
          { t('panel:buyCourse.submit') }
        </C.Button>
      </div>
    </>
  )
}
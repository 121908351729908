import reviewsRequest from 'request/review/reviews'
import guaranteeReviewsRequest from 'request/review/guarantee-reviews'

const limit = 20

export default async function fetchReviews({ state, mutate }) {
  const fetcher = state.type === 'guarantee' ? guaranteeReviewsRequest : reviewsRequest

  state.fetching = true
  mutate()
  try {
    const fetched = await fetcher({
      type: state.type,
      queryParams: {
        id: state.id,
        stylistConditionParams: state.params,
        appearanceConditionTagIds: state.tagIds
      },
      offset: state.reviews.length,
      limit
    })
    const idDic = state.reviews.keyBy('id')
    fetched.forEach(review => {
      if (idDic[review.id]) return
      formatter({ review, type: state.type })
      state.reviews.push(review)
    })

    state.finish = fetched.length < limit

  } finally {
    state.fetching = false
    mutate()
  }
}

const formatter = ({ review, type }) => {
  const name = review.user.name.substring(0, 1)
  review.$reviewerName = `${name}****`
  review.$rating = review.rating
  review.$latestReplies = review.latestReplies.map(reply => {
    const replyName = () => {
      const { name } = reply.replyUser
      if (!reply.shouldHideName) return name
      return `${name.substring(0, 1)}****`
    }
    reply.$replyerName = replyName()
    return reply
  }).reverse()
  review.$largePhotos = review.photos.map(photo => photo.url.large)

  if (!review.booking) return
  review.$showStylist = type !== 'stylist'
  const { bookingDiscount, services } = review.booking
  if (bookingDiscount?.campaign) {
    review.$serviceText = bookingDiscount.campaign.name
    return
  }
  review.$serviceText = services.map(({ service }) => service.name).join(', ')
}
const template = `
  query KeywordSearch($input: KeywordSearchInput!) {
    keywordSearch(input: $input) {
      users {
        id
        name
        avatar
        uniqueName
        stylist {
          id
          show
          certificated
          rating
          totalRatingCount
          isStudioAccount
          isGuarantee
          studio {
            name
            rating
            totalRatingCount
            authorized
          }
        }
      }
    }
  }
`

export default async function userSearch({ keyword, offset, limit }) {
  const input = {
    keyword: keyword || '',
    type: 'user',
    paginationInput: { limit, offset }
  }
  const { keywordSearch } = await request(template, { input })
  return keywordSearch.users
}

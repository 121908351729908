import fetchSchedule from 'request/common/fetch-schedule'

export default async function stateSimulation({
  state,
  nextStep,
  selectTimeSlot
}) {
  const { source, discountConfig } = state
  const { services, stylistUser } = discountConfig
  const { serviceKey, otherIds } = source
  if (source.startTime) state.startTime = dayjs(source.startTime)

  if (!fetchService(state, serviceKey)) return
  await nextStep(true)

  if (!fetchOthers(state, otherIds, services)) return
  await nextStep()
  if (!state.startTime) return

  const serviceIdsResolver = () => {
    const serviceId = parseInt(state.service.service.id)
    const addServiceIds = state.others.map(o => parseInt(o.service.id))
    return [serviceId, ...addServiceIds]
  }
  const serviceIds = serviceIdsResolver()

  const info = await fetchSchedule({
    userId: stylistUser.id,
    duration: state.duration,
    startDate: state.startTime,
    serviceIds,
    isEvent: true,
    endDate: state.startTime
  })

  const slot = state.startTime.slot()
  if (info[0].closedSlots.includes(slot)) return

  await selectTimeSlot(state.startTime)
}

const fetchService = (state, services, serviceKey) => {
  const service = services.find(s => s.id === serviceKey)
  if (!service) return false
  state.service = service
  return true
}

const fetchOthers = (state, otherIds, stylistServices) => {
  const serviceDic = stylistServices.keyBy('id')
  for (const id of otherIds) {
    const service = serviceDic[id]
    if (!service) return false
    state.otherDic[id] = true
    state.others.push(service)
  }
  return true
}

import customerCreateRequest from 'request/stylist-booking/customer-create'
import customerUpdateRequest from 'request/stylist-booking/customer-update'

export default function StylistCustomerCreate() {
  const panel = usePanel()
  const form = useForm({
    name: { rule: 'required' },
    gender: { rule: 'required', value: 'female' },
    phone: { value: '' },
    notes: { value: '' }
  })
  const params = panel.params('stylistCustomerCreate')
  useEffect(() => {
    if (!panel.state.stylistCustomerCreate) return
    form.name.error = ''
    form.gender.error = ''
    if (defaultValue) {
      form.name.value = defaultValue.name
      form.phone.value = defaultValue.phone
      form.gender.value = defaultValue.gender
      form.update('notes', defaultValue.notes || '')
      return
    }
    form.name.value = ''
    form.phone.value = ''
    form.notes.value = ''
    form.update('gender', 'female')
  }, [panel.state.stylistCustomerCreate])

  if (!params) return ''

  const { stylist, callback, defaultValue } = params

  const submit = async() => {
    const { name, gender, phone, notes } = form
    const input = {
      stylistId: stylist.id,
      name: name.value,
      gender: gender.value,
      phone: phone.value,
      notes: notes.value
    }
    await customerCreateOrUpdate(input)
    completeCreateRequest()
  }
  const customerCreateOrUpdate = input => {
    if (!defaultValue) return customerCreateRequest(input)
    input.customerId = defaultValue.id
    return customerUpdateRequest(input)
  }
  const completeCreateRequest = () => {
    const msg = defaultValue ? '修改成功' : '新增成功'
    $alert(msg)
    if (callback) callback(form.name.value)
    panel.close('stylistCustomerCreate')
  }

  const title = defaultValue ? '編輯顧客' : '新增顧客'

  return (
    <C.Modal
      visible={ panel.visible('stylistCustomerCreate') }
      close={ () => panel.close('stylistCustomerCreate') }
    >
      <div className='sm:w-[25rem] flex flex-col'>
        <div className='text-center py-1'>{ title }</div>
        <div className='overflow-scroll py-3'>
          <C.InputForm
            variant='standard'
            label='姓名'
            form={ form }
            field='name'
            fullWidth
          />
          <C.ComboBoxForm
            variant='standard'
            label='性別'
            form={ form }
            field='gender'
            options={ [
              { value: 'female', name: '女性' },
              { value: 'male', name: '男性' }
            ] }
            disabled={ !!defaultValue?.user }
          />
          <C.InputForm
            variant='standard'
            label='手機'
            form={ form }
            field='phone'
            fullWidth
            disabled={ !!defaultValue?.user }
          />
          <C.InputForm
            variant='standard'
            label='備註'
            field='notes'
            form={ form }
            fullWidth
            multiline
          />
        </div>
        <div className='relative'><C.Blur/></div>
        <div className='shrink-0'>
          <C.Button uid='stylistCustomerCreate.submit' className='w-full'
            check={ form } onClick={ submit }>送出</C.Button>
        </div>
      </div>
    </C.Modal>
  )
}

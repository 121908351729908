import StarRateIcon from '@mui/icons-material/StarRate'

export default function BasicInfo({ profile, studio }) {
  const t = useI18n()
  const { stylist } = profile

  return (
    <div className='container'>
      <div className='py-4 flex items-center space-x-5'>
        <div className='flex items-start'>
          <C.Image
            className='w-20 h-20 rounded-full shrink-0'
            src={ profile.avatar }
          />
        </div>
        <div className='font-bold flex-1'>
          <div className='text-lg flex items-center space-x-1'>
            <div className='line-clamp-1'>{ profile.name }</div>
          </div>
          <div className='text-gray-500 text-sm tracking-widest'>{ stylist.profession.name }</div>
          <div className='text-gray-500'>
            <div className='flex items-center'>
              <span className='text-yellow-400 -translate-y-0.5'>
                <StarRateIcon fontSize='inherit' />
              </span>
              <span className='text-xs'>
                { stylist.$rating } ({ t('page:userId.ratingCount', { count: stylist.$ratingCount }) })
              </span>
            </div>
          </div>

        </div>
      </div>

      <div className='tracking-widest'>
        <RowAddress iconKey='locationF' content={ studio.address || 'NA' } />
      </div>
    </div>
  )
}

const RowAddress = ({ content, iconKey }) => (
  <div className='flex space-x-1 items-start'>
    <div className='text-gray-500'>{ icon(iconKey) }</div>
    <div className='text-sm font-bold text-gray-500'>
      { content }
    </div>
  </div>
)
import Title from './title'

export default function EventInfo({ state }) {
  const t = useI18n()
  const { currentDiscount, otherDiscountDic } = state

  const renderFirstDiscount = () => {
    if (!currentDiscount) return
    return <Discount discount={ currentDiscount }/>
  }

  return (
    <div>
      <div className='container'>
        <Title>
          { t('panel:eventStylist.discount') }
          {/* <span className='text-xs text-gray-500 px-1'>({ remainQuotaLabel })</span> */}
        </Title>
      </div>
      <div className='container'>
        { renderFirstDiscount() }
        { store.stylistEventFilterCategories.filter(category => otherDiscountDic[category]).map(category => {
          const discount = otherDiscountDic[category]
          return <Discount key={ category } discount={ discount }/>
        }) }
      </div>
    </div>
  )
}

const Discount = ({ discount }) => (
  <div className='space-y-3 font-bold flex items-center justify-between'>

    <div className='text-sm tracking-widest'>{ discount.tag }</div>

    <div className='flex items-center space-x-2 tracking-wider'>
      <div className='font-normal line-through text-gray-400 text-xs'>${ discount.originPrice }</div>
      <div className='text-sm'>NT${ discount.price }</div>
    </div>
  </div>
)
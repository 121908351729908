const template = `
  query VendorProductOrders($userId: ID) {
    vendorProductOrders(userId: $userId) {
      id
      amount
      checkoutAt
      orderBuyerInfo {
        name
        phone
        studio
        address
      }
    }
  }
`

export default async function vendorReceipts({ userId }) {
  const { vendorProductOrders } = await request(template, { userId })
  return vendorProductOrders
}

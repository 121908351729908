const template = `
  query BookingRecords($input: PaginationInput!){
    me {
      bookings(input: $input) {
        id
        startTime
        endTime
        realPayment
        state
        payByMapPay
        isCommented
        stylist{
          user{
            id
            name
          }
        }
        services{
          service{
            name
          }
        }
      }
    }
  }
`

export default async function bookingRecords({ offset, limit }) {
  const input = { limit, offset }
  const { me } = await request(template, { input })
  return me.bookings
}

import Title from './title'
import Schedule from 'components/common/schedule'

export default function ScheduleBlock({ profile }) {
  const t = useI18n()

  return (
    <div className='container'>
      <Title>
        { t('page:userId.availableTime') }
      </Title>
      <div className='pt-2'/>
      <Schedule
        userId={ profile.id }
        duration={ 30 }
      />
    </div>
  )
}

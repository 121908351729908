import bookingUpdateRequest from 'request/stylist-booking/update'
import { range } from 'lodash-es'

export default function factoryModifyBookingTime({ state, modifyBookingCallback, loadStylistSchedule }) {
  return function useModifyBookingTime() {
    const [date, setDate] = useState(dayjs())
    const panel = usePanel()
    const { booking, stylistSchedule } = state
    const defaultStartSlot = booking?.startTime.diff(booking.startTime.ymd()) / (1000 * 60 * 30) || 0
    const defaultDurationSlot = booking?.endTime.diff(booking.startTime) / (1000 * 60 * 30) || 0
    const form = useForm({
      startSlot: { rule: 'required', value: defaultStartSlot },
      durationSlot: { rule: 'required', value: defaultDurationSlot }
    })

    useEffect(() => {
      if (!panel.state.modifyBookingTime) return
      setDate(dayjs(booking.startTime))
      form.startSlot.value = defaultStartSlot
      form.durationSlot.value = defaultDurationSlot
    }, [panel.state.modifyBookingTime])

    if (!booking || !stylistSchedule) return null

    const slotTime = slot => `${date.format('YYYY-MM-DD')} ${slot.slotToTime()}:00`

    const { startSlot, endSlot } = stylistSchedule

    const availableSlots = () => range(startSlot, endSlot)
      .map(value => ({ value, name: value.slotToTime() }))

    const durations = () => range(1, endSlot - startSlot + 1)
      .map(value => ({ value, name: `${value / 2} 小時` }))

    const submit = async() => {
      const { startSlot, durationSlot } = form
      await bookingUpdateRequest({
        bookingId: booking.id,
        startAt: slotTime(startSlot.value),
        endAt: slotTime(startSlot.value + durationSlot.value)
      })
      await completeUpdateRequest()
    }

    const completeUpdateRequest = async() => {
      await modifyBookingCallback()
      panel.close('modifyBookingTime')
    }

    return {
      date,
      setDate,
      availableSlots,
      durations,
      form,
      submit,
      loadStylistSchedule
    }
  }
}

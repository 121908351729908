import StepBar from './step-bar'
import Category from './category'
import Service from './service'
import Other from './other'
import Time from './time'
import Prepay from './prepay'
import { Context, useStore } from 'store/panel-prepay-booking'

const contentDic = {
  category: Category,
  service: Service,
  other: Other,
  time: Time,
  prepay: Prepay
}

export default function PrepayBooking() {
  const store = useStore()
  const panel = usePanel()
  const {
    previousStep,
    closeBookingPanel,
    showPreviousBtn
  } = store

  return (
    <C.Modal
      visible={ panel.visible('prepayBooking') }
      close={ closeBookingPanel }
      previous={ previousStep }
      showPreviousBtn={ showPreviousBtn && showPreviousBtn() }
      noPadding
    >
      <Context.Provider value={ store }>
        <Body />
      </Context.Provider>
    </C.Modal>
  )
}

const Body = () => {
  const {
    step,
    title,
    submit,
    submitText,
    stylistId
  } = useCtx('prepayBooking')

  if (!stylistId) return null

  const Content = contentDic[step]

  const renderSubmitBtn = () => {
    if (!submitText) return
    return (
      <div className='flex-shrink-0 p-5 pt-0'>
        <C.Button
          uid='prepayBooking.submit'
          fullWidth
          onClick={ submit }
        >
          { submitText }
        </C.Button>
      </div>
    )
  }

  return (
    <div className='flex flex-col sm:w-[28rem] sm:max-h-[35rem] select-none'>
      <div className='flex-shrink-0'>
        <div className='text-center py-4 truncate max-w-[80%] mx-auto'>{ title }</div>
        <StepBar />
      </div>
      <div className='p-5 overflow-scroll'>
        <Content />
      </div>
      <div className='relative'><C.Blur/></div>
      { renderSubmitBtn() }
    </div>
  )
}

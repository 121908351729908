import BasicInfo from './basic-info'
import EventInfo from './event-info'
import Collections from './collections'
import Reviews from './reviews'
import StudioInfo from './studio-info'
import Schedule from './schedule'
import stylistRequest from 'request/stylists/stylist'
import formatter from './formatter'

export default function EventStylistPanel() {
  const t = useI18n()
  const { state, mutate } = useStatic({
    user: null,
    studio: null,
    posts: [],
    reviews: [],
    labelText: '',
    remainQuotaLabel: '',
    currentDiscount: null,
    otherDiscountDic: {},
    visible: false
  })

  h.openEventStylist = async({
    userId,
    studioId,
    btnDisable,
    btnText,
    labelText,
    remainQuotaLabel,
    currentDiscount,
    otherDiscountDic,
    callback,
    updateCallback
  }) => {
    const { user, posts, reviews, studio } = await stylistRequest(userId, studioId)
    state.user = user
    state.studio = studio
    state.posts = posts
    state.reviews = reviews
    formatter({ state })
    state.btnDisable = btnDisable
    state.btnText = btnText
    state.labelText = labelText
    state.remainQuotaLabel = remainQuotaLabel
    state.currentDiscount = currentDiscount
    state.otherDiscountDic = otherDiscountDic
    state.callback = callback
    state.updateCallback = updateCallback
    state.visible = true
    mutate()
    mkt.eventStylistClick(user.id)
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body state={ state } closePanel={ closePanel }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ t('panel:eventStylist.title') }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({ state, closePanel }) => {
  const { user, callback, updateCallback, btnDisable, btnText } = state
  const openReview = () => h.reviewOpen({
    type: 'stylist',
    id: user.id
  })

  const openBookingClick = async() => {
    await h.openStylistEventBooking({
      stylistId: user.stylist.id,
      callback,
      updateCallback
    })
    closePanel()
  }

  return (
    <>
      <div className='flex flex-col overflow-scroll md:px-1'>
        <BasicInfo profile={ state.user } studio={ state.studio }/>
        <Hr />
        <EventInfo state={ state } />
        <Hr />

        <Collections profile={ state.user } posts={ state.posts } />
        <Hr />
        <Reviews profile={ state.user } reviews={ state.reviews } openReview={ openReview } />
        <Hr />
        <StudioInfo studio={ state.studio } />
        <Hr />
        <Schedule profile={ state.user } />
        <Hr />
      </div>
      <div className='relative'><C.Blur/></div>
      <div className='shrink-0 p-4 pt-0'>
        <C.Button className='h-[2.8rem] md:h-[2.4rem]' fullWidth onClick={ openBookingClick } disabled={ btnDisable }>{ btnText }</C.Button>
      </div>
    </>
  )
}

const Hr = () => (
  <div className='pb-5 mx-5'>
  </div>
)
import studioProductsRequest from 'request/studio-manage-product/studio-products'

export default function formatter({ state, mutate, serviceList, discountLimits, bookingStateDic, t }) {
  const { booking, services, products, newProductName } = state
  const { gender, phone, startTime, endTime, state: bookingState, payByMapPay, stylist, showQRCode } = booking

  const stateColor = () => {
    if (payByMapPay) return 'text-gray-400'
    if (bookingState === 1) return 'text-blue-400'
    if (bookingState === 2) return 'text-red-400'
    return 'text-gray-400'
  }

  const i18nState = () => {
    if (payByMapPay) return '已付款'
    if (bookingState === 1) return '未結帳'
    if (bookingState === 2) return '未付款'
    const key = bookingStateDic[bookingState]
    return t(`common:booking.state.${ key }`)
  }

  const penaltyResolver = () => {
    if (![3, 5].includes(bookingState)) return 0
    return booking.prepayAmount - booking.prepayDeposit
  }

  const checkoutBtnTextResolver = () => {
    if (showQRCode) return '重新結帳'
    if (bookingState === 2) return '預收現金'
    return '結帳'
  }

  const cancelBtnTextResolver = () => {
    if (showQRCode) return 'QR Code'
    return '取消'
  }

  const amount = services.sum(s => s.price) + products.sum(p => p.price * p.count)
  const alreadyPaid = booking.prepayAmount
  const finalAmount = amount - alreadyPaid
  booking.$details = {
    serviceTime: `${startTime.ymdhm()} - ${endTime.hm()}`,
    gender: gender === 'female' ? '女' : '男',
    phone: phone ? phone : '無',
    stateColor: stateColor(),
    i18nState: i18nState(),
    disabled: payByMapPay,
    amount: amount.currency(),
    alreadyPaid: alreadyPaid.currency(),
    penaltyAmount: penaltyResolver(),
    isNoShow: bookingState === 3,
    isCancelled: bookingState > 3,
    finalAmount: (finalAmount > 0 ? finalAmount : 0).currency(),
    checkoutBtnText: checkoutBtnTextResolver(),
    cancelBtnText: cancelBtnTextResolver(),
    noteLargeImageUrls: booking.noteImages.map(({ photo }) => photo.large.url),
    noteSmallImageUrls: booking.noteImages.map(({ photo }) => photo.small.url)
  }

  const prepareDiscount = () => {
    let { discount } = booking
    if (!discount) return
    const { name, originalMinPrice, originalMaxPrice } = discount
    discount.$title = name
    discount.$originalRange = `${ t('common:originalPrice') }: ${ getPriceRangeText(originalMinPrice, originalMaxPrice, t) }`
    discount.$range = getDiscountPrice(discount, t)
    discount.$remark = getDiscountRemark(discount, discountLimits, t)
  }
  prepareDiscount()

  const servicePriceChange = (current, price) => {
    state.services = services.map(service => {
      if (service !== current) return service
      return {
        ...current,
        price: price.toNumber()
      }
    })
    mutate()
  }

  const deleteService = current => {
    state.services = services.filter(p => p !== current)
    mutate()
  }

  const serviceLoadMore = ({ keyword, offset }) => {
    if (offset > 0) return []
    const substring = keyword.trim().toLowerCase()
    return serviceList.filter(({ service }) => service.name.toLowerCase().includes(substring))
  }

  const serviceOptions = service => {
    const priceRange = getPriceRangeText(service.minPrice, service.maxPrice, t)
    return {
      ...service,
      key: service.service.id,
      value: `${service.service.name} | ${priceRange}`
    }
  }

  const addService = value => {
    const { minPrice, maxPrice } = value
    const price = minPrice > 0 && minPrice === maxPrice ? minPrice : 0
    const service = {
      key: uuid(),
      id: value.service.id,
      name: value.service.name,
      price
    }
    state.services = [...services, service]
    mutate()
  }

  const productCountChange = (current, count) => {
    state.products = products.map(product => {
      if (product !== current) return product
      return {
        ...current,
        count: count.toNumber()
      }
    })
    mutate()
  }

  const productPriceChange = (current, price) => {
    state.products = products.map(product => {
      if (product !== current) return product
      return {
        ...current,
        price: price.toNumber()
      }
    })
    mutate()
  }

  const deleteProduct = current => {
    state.products = products.filter(p => p !== current)
    mutate()
  }

  const productLoadMore = ({ keyword, offset, limit }) => studioProductsRequest({ studioId: stylist.studio.id, keyword, offset, limit })

  const productOptions = option => {
    const { id, name, hairBrand, price, cost } = option
    const brandName = hairBrand?.name || '無'
    const url = hairBrand?.url || ''
    return {
      ...option,
      key: id,
      value: name,
      brandDesc: `品牌: ${brandName}`,
      priceDesc: `售價: ${price.currency()} (成本: ${cost.currency()})`,
      url
    }
  }

  const addProduct = value => {
    const product = {
      key: uuid(),
      id: value.id,
      name: value.name,
      price: value.price,
      count: 1
    }
    state.products = [...products, product]
    mutate()
  }

  const refreshProductFilter = keyword => {
    state.newProductName = keyword
    mutate()
  }

  const openProductCreate = () => h.openStudioProduct({ studioId: stylist.studio.id, callback: refreshProductFilter })

  booking.$serviceBlock = {
    services,
    servicePriceChange,
    deleteService,
    serviceLoadMore,
    serviceOptions,
    addService
  }

  booking.$productBlock = {
    products,
    productCountChange,
    productPriceChange,
    deleteProduct,
    productLoadMore,
    productOptions,
    addProduct,
    newProductName,
    openProductCreate
  }

}

const getDiscountRemark = (discount, discountLimits, t) => {
  const limit = getDiscountLimit(discount, discountLimits, t)
  const deadline = getDiscountDeadline(discount.deadline, t)
  if (!deadline) return limit
  return `${limit}\n${deadline}`
}

const getDiscountLimit = (discount, discountLimits, t) => {
  const { limitCustomer, limitDay, limitTimeSlot } = discount
  if (limitCustomer === 0) return ''
  const key = discountLimits[limitCustomer]
  const i18nPath = 'common:discount.limit'
  if (limitCustomer === 5) return t(`${i18nPath}.${key}`, { day: limitDay })
  if (limitCustomer !== 6) return t(`${i18nPath}.${key}`, { time: limitTimeSlot.slotToTime() })
  if (limitCustomer === 7) return t(`${i18nPath}.${key}`, { time: limitTimeSlot.slotToTime() })
  return t(`${i18nPath}.${key}`)
}

const getDiscountDeadline = (deadline, t) => {
  if (deadline === 2147483647) return ''
  const date = dayjs(deadline * 1000).format('YYYY/MM/DD')
  return t('common:discount:effectiveUntil', { date })
}
import Title from './title'
export default function Collections({ posts, profile }) {
  const t = useI18n()

  const showMore = () => h.openCollection({
    type: 'user',
    id: profile.id,
    title: profile.name
  })

  const renderShowMore = () => {
    if (posts.length < 7) return

    return (
      <div className='relative -translate-y-1'>
        <div className='flex h-12 overflow-hidden'>
          { postList(posts, 6, 9) }
        </div>
        <C.Blur>
          <div className='text-center font-bold cursor-pointer md:text-[1.05rem]'
            onClick={ showMore }
          >
            { t('page:userId.morePost') }
          </div>
        </C.Blur>
      </div>
    )
  }

  return (
    <div>
      <div className='container'>
        <Title>
          { t('page:userId.post') }
          <span className='text-xs text-gray-500 px-1'>({ profile.postCount })</span>
        </Title>
      </div>
      <div className='sm:container'>
        <div className='space-y-1'>
          <div className='flex flex-wrap'>
            { postList(posts, 0, 6) }
          </div>
          { renderShowMore() }
        </div>
      </div>
    </div>
  )
}

const postList = (posts, st, end) => posts.slice(st, end).map(post => (
  <C.Link href={ h.path.post({ id: post.id }) } preventDefault
    key={ post.id } className='w-1/3 p-[0.5px] md:p-[2px]'>
    <div onClick={ () => h.openPost({ id: post.id }) }>
      <C.Image
        src={ h.path.postPhoto({ id: post.photos[0].id }) }
        className='w-full aspect-w-1 aspect-h-1'
        alt={ post.alt }
      />
    </div>
  </C.Link>
))

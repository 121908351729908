export default function Other({ state, mutate }) {

  const toggleOther = service => {
    const key = service.id
    if (state.otherDic[key]) {
      state.otherDic[key] = false
      state.others = state.others.filter(current => current.id !== key)
      mutate()
      return
    }
    state.otherDic[key] = true
    state.others.push(service)
    mutate()
  }

  const isOtherSelect = service => state.otherDic[service.id]

  return (
    <div>
      { state.otherServices.map(row => (
        <div
          key={ row.id }
          onClick={ () => toggleOther(row) }
          className='flex items-center justify-between cursor-pointer hover:bg-gray-50 p-3 border-b'
        >
          <div className='space-y-1'>
            <div className='font-bold'>{ row.$title }</div>
            <div className='text-sm'>{ row.$range } ({ row.$discountRatio })</div>
            <div className='text-gray-400 text-sm line-through'>{ row.$originalRange }</div>
            <div className='text-gray-500 text-xs' { ...row.$remark.rawHtml() } />
          </div>
          <Icon.Check className={ cn({ hidden: !isOtherSelect(row) }) }/>
        </div>
      ))}
    </div>
  )
}

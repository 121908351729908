import useUserService from 'hooks/user-service'
import usePrepayBooking from './action/prepay'
import prepayConfigRequest from 'request/prepay-booking/config'
import durationRequest from 'request/prepay-booking/duration-resolver'
import stateSimulation from './state-simulation'

const steps = ['category', 'service', 'other', 'time', 'prepay']

let parseSource = false

export default function useStoreState() {
  const { method, methodParams, selectedCard, zerocardPlansResolver, feeResolver, installment } = usePaymentMethod()
  const { user } = useCurrent()
  const panel = usePanel()
  const t = useI18n()
  const { state, mutate } = useStatic({
    stylistId: 0,
    startTime: null,
    isStudioAccount: 0,
    callback: null,
    previous: [],
    defaultPrepayAmount: 0,
    isModel: false,
    applyFormId: 0
  })
  const { services, serviceMap, stylistServices } = useUserService({ userId: state.stylistUserId })

  const closeBookingPanel = () => {
    panel.close('prepayBooking')
    state.startTime = null
    state.callback && state.callback()
  }

  const prepayCallback = userId => {
    closeBookingPanel()
    $messenger.talkToUser(userId)
  }

  const prepayBooking = usePrepayBooking({
    user,
    state,
    callback: prepayCallback
  })

  const openPaymentMethod = () => {
    const source = stateToSource(state)
    h.openPaymentMethod({
      type: 'prepayBooking',
      path: window.location.pathname,
      source
    }, 'prepayBooking')
  }

  state.paymentMethod = method
  state.paymentMethodParams = methodParams
  state.selectedCard = selectedCard
  state.installment = installment
  state.openPaymentMethod = openPaymentMethod
  state.services = services
  state.serviceMap = serviceMap
  state.stylistServices = stylistServices

  useEffect(() => {
    if (!state.source) return
    if (!services.length) return
    if (parseSource) return
    state.forceParseSource = false
    parseSource = true
    stateSimulation({
      state,
      nextStep,
      selectTimeSlot
    })
  }, [state.services])

  useEffect(() => {
    if (!state.forceParseSource) return
    if (!state.source) return
    if (!services.length) return
    if (!parseSource) return
    state.forceParseSource = false
    stateSimulation({
      state,
      nextStep,
      selectTimeSlot
    })
  }, [state.forceParseSource])

  useEffect(() => {
    if (!state.finalPayment) return
    state.finalPayment = finalPaymentResolver(state)
    mutate()
  }, [method, installment])

  const checkDefaultDiscount = ({ category, discountId }) => {
    if (!category) return
    const infos = state.serviceMap[category]?.infos
    if (!infos) return

    const key = `discount_${discountId}`
    const service = infos.find(s => s.$key === key)
    if (!service) return

    service.shouldOpen = true
    state.previous = [steps[0]]
    state.category = category
    state.service = service
    state.step = steps[1]
    mutate()
  }

  const openOtherCategory = () => {
    state.previous = [steps[0]]
    state.category = 'other'
    state.step = steps[1]
    state.isModel = true
    mutate()
  }

  global.$openPrepayBooking = ({
    stylistId,
    stylistUserId,
    stylistName,
    callback,
    source,
    defaultDiscountId,
    defaultDiscountCategory,
    isModel,
    applyFormId,
    forceParseSource,
    defaultPrepayAmount,
    defaultMinPayment
  }) => {
    state.stylistId = stylistId
    state.stylistUserId = stylistUserId
    state.stylistName = stylistName
    state.source = source
    state.defaultPrepayAmount = defaultPrepayAmount || 0
    state.forceParseSource = forceParseSource
    state.step = 'category'
    state.previous = []
    state.category = ''
    state.service = {}
    state.otherDic = {}
    state.others = []
    state.callback = callback
    state.prepayPolicy = []
    state.notes = ''
    state.isModel = !!applyFormId && defaultPrepayAmount === 0
    state.applyFormId = applyFormId
    state.defaultMinPayment = defaultMinPayment

    checkDefaultDiscount({
      discountId: defaultDiscountId,
      category: defaultDiscountCategory
    })
    if (isModel) openOtherCategory()

    mutate()

    setTimeout(() => {
      checkDefaultDiscount({
        discountId: defaultDiscountId,
        category: defaultDiscountCategory
      })
    }, 100)

    panel.open('prepayBooking')
    mkt.bookingOpen(stylistUserId)
  }

  if (!state.stylistId) return { state, closeBookingPanel }

  const feedDuration = async() => {
    const { stylistUserId, service } = state
    const serviceIdResolver = () => {
      if (service.$type === 'discount') return service.id
      return service.service.id
    }
    const serviceId = serviceIdResolver()
    const addServiceIds = state.others.map(o => o.service.id)
    state.duration = await durationRequest({
      userId: stylistUserId,
      type: service.$type,
      typeParams: { id: serviceId },
      addServiceIds
    })
    state.durationText = getServiceTimeText(state.duration, t)
  }

  const nextStep = async notMutate => {
    if (state.step === 'other') await feedDuration()
    const index = steps.indexOf(state.step)
    state.previous = [...state.previous, state.step]
    state.step = steps[index + 1]

    if (notMutate) return
    mutate()
  }

  const previousStep = () => {
    if (state.step === 'time') state.startTime = null
    state.step = state.previous.at(-1)
    state.previous = state.previous.slice(0, -1)
    mutate()
  }

  const fetchSubmitText = () => {
    const count = state.others.length
    switch (state.step) {
      case 'prepay':
        if (state.prepayAmount) return t('panel:prepayBooking.submit.prepay')
        return t('panel:prepayBooking.submit.book')
      case 'other':
        if (count) return t('panel:prepayBooking.submit.add', { count })
        return t('panel:prepayBooking.submit.no')
      default:
        return ''
    }
  }
  const submitText = fetchSubmitText()

  const submit = async() => {
    if (state.step === 'other') return nextStep()
    await prepayBooking()
  }

  const title = t('panel:prepayBooking.title', { name: state.stylistName, interpolation: { escapeValue: false } })

  const selectCategory = name => {
    state.category = name
    nextStep()
  }

  const selectService = service => {
    state.service = service
    state.otherDic = {}
    state.others = []
    nextStep()
  }

  const toggleOther = service => {
    const key = service.id
    if (state.otherDic[key]) {
      state.otherDic[key] = false
      state.others = state.others.filter(current => current.id !== key)
      mutate()
      return
    }
    state.otherDic[key] = true
    state.others.push(service)
    mutate()
  }
  const isOtherSelect = service => state.otherDic[service.id]

  const selectTimeSlot = async slot => {
    if (!user) return panel.open('auth')

    mkt.selectBookingTime(slot.ymdhm())
    state.startTime = slot
    state.paymentDetails = []
    state.finalPayment = 0
    prepareBookingDetails()
    if (!state.prepayAmount) return nextStep()
    await preparePaymentDetails()
    nextStep()
  }

  const prepareBookingDetails = () => {
    const { defaultPrepayAmount, stylistName, service, others, startTime, durationText, defaultMinPayment } = state
    const prepayAmountResolver = () => {
      if (defaultMinPayment) return Math.floor(defaultMinPayment / 2)
      if (defaultPrepayAmount) return defaultPrepayAmount
      return service.prepayAmount + others.map(o => o.prepayAmount).sum()
    }
    const minPaymentResolver = () => {
      if (defaultMinPayment) return defaultMinPayment
      if (defaultPrepayAmount) return defaultPrepayAmount * 2
      return service.minPayment + others.map(o => o.minPayment).sum()
    }
    state.minPayment = minPaymentResolver()
    state.prepayAmount = prepayAmountResolver()

    const bookingDetails = [
      [t('panel:prepayBooking.detail.bookTime'), startTime.format('YYYY/MM/DD (dd) HH:mm')],
      [t('panel:prepayBooking.detail.bookStylist'), stylistName],
      [t('panel:prepayBooking.detail.serviceItems'), service.$title]
    ]
    const otherItems = others.map(o => o.service.name).join(' + ')
    if (otherItems) bookingDetails.push([t('panel:prepayBooking.detail.otherItems'), otherItems])
    bookingDetails.push([t('panel:prepayBooking.detail.serviceDuration'), durationText])

    state.bookingDetails = bookingDetails
    state.userDetails = [
      [t('panel:prepayBooking.detail.name'), `${ user.name } ${ t(`panel:prepayBooking.detail.${ user.gender }`) }`],
      [t('panel:prepayBooking.detail.phone'), user.phone]
    ]
  }

  const finalPaymentResolver = ({ minPayment, prepayAmount, remainDeposit }) => {
    const depositUsed = Math.min(prepayAmount, remainDeposit)
    const remain = remainDeposit - depositUsed
    const subtotal = prepayAmount - depositUsed
    const fee = feeResolver(subtotal)
    const finalPayment = subtotal + fee
    state.zerocardPlans = zerocardPlansResolver(subtotal)
    state.subtotal = subtotal
    state.paymentDetails = [
      [t('panel:prepayBooking.detail.minPayment'), minPayment.currency()],
      [t('panel:prepayBooking.detail.prepayAmount'), prepayAmount.currency(), 'text-red-500 font-bold']
    ]

    if (fee > 0)state.paymentDetails.push([t('panel:userBooking.zerocardFee'), fee.currency()])
    if (depositUsed > 0)state.paymentDetails.push([t('panel:prepayBooking.detail.depositUsed'), `-${depositUsed.currency()} (${ t('panel:prepayBooking.detail.remainAmount') }: ${remain.currency()})`])
    if (finalPayment !== prepayAmount) state.paymentDetails.push([t('panel:prepayBooking.detail.payAmount'), finalPayment.currency(), 'text-red-500 font-bold'])

    return finalPayment
  }

  const preparePaymentDetails = async() => {
    const { prepayPolicy, depositConfig } = await prepayConfigRequest({
      prepayAmount: state.prepayAmount,
      stylistId: state.stylistId
    })
    state.prepayPolicy = prepayPolicy
    state.remainDeposit = depositConfig.remainDeposit
    state.finalPayment = finalPaymentResolver(state)
  }

  const notesChange = event => {
    state.notes = event.target.value
  }

  const showPreviousBtn = () => {
    if (state.applyFormId && state.step === 'time') return false
    if (state.previous.length == 0) return false
    return true
  }

  return {
    state,
    title,
    submit,
    submitText,
    steps,
    nextStep,
    previousStep,
    selectCategory,
    selectService,
    toggleOther,
    isOtherSelect,
    selectTimeSlot,
    notesChange,
    prepayBooking,
    closeBookingPanel,
    showPreviousBtn
  }
}

const stateToSource = state => {
  const {
    category,
    service,
    others,
    startTime,
    stylistId,
    stylistUserId,
    stylistName,
    defaultPrepayAmount,
    defaultMinPayment,
    applyFormId
  } = state

  return {
    category,
    serviceKey: service.$key,
    otherIds: others.map(o => o.id),
    startTime: startTime.dateTime(),
    stylistId,
    stylistUserId,
    stylistName,
    defaultPrepayAmount,
    defaultMinPayment,
    applyFormId
  }
}

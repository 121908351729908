
export default function Payment({
  finalPayment,
  subsidyAmount,
  fee,
  t
}) {
  return (
    <div className='py-5'>
      <div className='font-bold'>{ t('panel:buyCourse.payment') }</div>
      <div className='py-3 space-y-3'>

        { subsidyAmount > 0 && <div className='flex justify-between items-center text-sm'>
          <div>{ t('panel:buyCourse.subsidyAmount') }</div>
          <div>-{ subsidyAmount.currency() }</div>
        </div>
        }

        { fee > 0 && <div className='flex justify-between items-center text-sm'>
          <div>{ t('panel:buyCourse.zerocardFee') }</div>
          <div>+{ fee.currency() }</div>
        </div>
        }

        <div className='flex justify-between items-center text-sm text-red-500 font-bold'>
          <div>{ t('panel:buyCourse.payAmount') }</div>
          <div>{ finalPayment.currency() }</div>
        </div>
      </div>
    </div>
  )
}

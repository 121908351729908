import bookingUpdateRequest from 'request/stylist-booking/update'

export default function factoryModifyBookingRemark({ state, modifyBookingCallback }) {
  return function useModifyBookingRemark() {
    const [remark, setRemark] = useState('')
    const panel = usePanel()
    const { booking } = state
    useEffect(() => {
      if (!panel.state.modifyBookingRemark) return
      setRemark(booking.notes)
    }, [panel.state.modifyBookingRemark])

    if (!booking) return null

    const completeUpdateRequest = async() => {
      await modifyBookingCallback()
      panel.close('modifyBookingRemark')
    }

    const submit = async noteImages => {
      await bookingUpdateRequest({
        bookingId: booking.id,
        note: remark,
        noteImages
      })
      await completeUpdateRequest()
    }

    return {
      remark,
      setRemark,
      submit,
      noteSmallImageUrls: booking.$details.noteSmallImageUrls || []
    }
  }
}
export default function SinglePhoto() {
  const { state, mutate } = useStatic({
    srcs: [],
    visible: false,
    index: 0
  })

  h.openGallery = (srcs, selectIndex = 0) => {
    if (srcs.length === 1) return h.$singlePhoto(srcs[0])
    state.visible = true
    state.index = selectIndex
    state.srcs = srcs.map((src, index) => {
      const record = {
        index,
        src,
        width: 0,
        height: 0,
        onLoad: ({ width, height }) => {
          record.width = width
          record.height = height
          mutate()
        }
      }
      return record
    })
    mkt.photoClick()
    mutate()
  }

  const close = () => {
    state.visible = false
    mutate()
  }

  return (
    <C.Modal
      noCloseBtn
      noPadding
      noBgColor
      noRounded
      fullHeightCenter
      bgMask='backdrop-blur bg-black/70'
      visible={ state.visible }
      close={ close }
    >
      <Body state={ state } mutate={ mutate } close={ close } />
    </C.Modal>
  )
}

const Body = ({ state, mutate, close }) => {
  const current = state.srcs[state.index]
  if (!current) return null

  const isReady = current.width

  const scale = () => {
    if (!isReady) return 'scale-0'
    return 'scale-100'
  }

  const opacity = () => {
    if (!isReady) return 'opacity-0'
    return 'opacity-100'
  }

  const renderLoading = () => {
    if (isReady) return ''
    return <LoadingIcon />
  }

  const setPicture = index => {
    if (!isReady) return
    if (index === state.index) return
    if (index < 0) return
    if (index >= state.srcs.length) return
    if (!state.srcs[index].width) return
    state.index = index
    mutate()
    mkt.photoClick()
  }

  const preStyle = () => {
    if (state.index > 0) return 'cursor-pointer'
    return 'opacity-30'
  }

  const nextStyle = () => {
    if (state.index < state.srcs.length - 1) return 'cursor-pointer'
    return 'opacity-30'
  }

  const smallStyle = index => {
    if (index === state.index) return ''
    return ' cursor-pointer opacity-30 hover:opacity-80'
  }

  const Btn = ({ Icon, className, ...props }) => (
    <div
      className={ cn('absolute rounded-full p-1 bg-gray-400/5', className) }
      { ...props }
    >
      <Icon className='text-white scale-[140%]'/>
    </div>
  )

  return (
    <div className={ cn('flex items-center justify-center relative') }>
      <div
        className={ cn('relative max-w-full max-h-full h-full') }
      >
        <div className='flex flex-col max-w-full w-[64rem] h-full'>
          <div className='flex-1 relative flex w-full h-full pt-16'>
            <C.Image
              src={ current.src }
              className={ cn('w-full h-full transition-all', scale(), opacity()) }
              type='contain'
            />
            { renderLoading() }
            <div className='absolute top-0 left-0 right-1/2 bottom-0'
              onClick={ () => setPicture(state.index - 1) }
            />
            <div className='absolute top-0 left-1/2 right-0 bottom-0'
              onClick={ () => setPicture(state.index + 1) }
            />
            <Btn
              Icon={ Icon.ChevronLeft }
              className={ cn('top-1/2 left-2 -translace-y-1/2', preStyle()) }
              onClick={ () => setPicture(state.index - 1) }
            />
            <Btn
              Icon={ Icon.ChevronRight }
              className={ cn('top-1/2 right-2 -translace-y-1/2', nextStyle()) }
              onClick={ () => setPicture(state.index + 1) }
            />
          </div>
          <div className='shrink-0 container w-full py-1 pb-16'>
            <div className='flex justify-center flex-wrap'>
              { state.srcs.map(({ src, index, onLoad }) => (
                <div
                  className='w-[16.6%] sm:w-[10%] max-w-[3rem]'
                  key={ index }
                >
                  <C.Image
                    src={ src }
                    className={ cn('pt-[100%] m-[1px] relative', smallStyle(index)) }
                    onClick={ () => setPicture(index) }
                    onLoad={ onLoad }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Btn
          Icon={ Icon.Close }
          className='top-2 right-2 cursor-pointer'
          onClick={ close }
        />
      </div>
    </div>
  )

}

const LoadingIcon = ({ className }) => (
  <div className={ cn('absolute inset-0 flex items-center justify-center', className) }>
    <C.Loading color='white' />
  </div>
)

const template = `
  query KeywordSearch($input: KeywordSearchInput!) {
    keywordSearch(input: $input) {
      tags {
        id
        name
        postNum
      }
    }
  }
`

export default async function tagSearch({ keyword, offset, limit, filterCategory, filterTagCategory, minPost }) {
  const input = {
    keyword: keyword || '',
    type: 'tag',
    paginationInput: { limit, offset },
    params: { filterCategory, filterTagCategory, minPost }
  }
  const { keywordSearch } = await request(template, { input })
  return keywordSearch.tags
}

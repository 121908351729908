import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined'
import TimerIcon from '@mui/icons-material/Timer'

const data = { changeStep: () => {} }

export default function PhoneUpdate() {
  return (
    <C.Panel
      noPadding
      panel='phoneUpdate'
    >
      <Layout />
    </C.Panel>
  )
}

const Layout = () => {
  const [step, changeStep] = useState('phone')
  const t = useI18n()

  data.changeStep = changeStep

  const renderComponent = () => {
    if (step === 'phone') {
      return <Phone changeStep={ changeStep } />
    }
    return <PhoneCode />
  }
  return (
    <div className='py-5 px-5 max-w-[27rem] sm:px-10 sm:w-[27rem]  mx-auto'>
      <div className='pt-5 pb-12 text-xl font-bold'>{ t('panel:phoneUpdate.title') }</div>
      { renderComponent() }
    </div>
  )
}

const sendRequest = async({ phone, code }) => {
  phone ||= data.phone
  const template = `
    mutation PhoneUpdate($phone: String!, $code: String) {
      phoneUpdate(phone: $phone, code: $code)
    }
  `
  try {
    await request(template, {
      phone,
      code
    })
  } catch (error) {
    switch (error.code) {
      case 'auth.phoneNeedCode':
      case 'auth.phoneCodeIsSending':
        data.phone = error.params.phone
        data.codeExpiredAt = error.params.codeExpiredAt
        data.changeStep('phoneCode')
        return
      default:
        throw error
    }
  }
}

const Phone = () => {
  const t = useI18n()
  const form = useForm({
    phoneCode: { value: '886TW' },
    phone: { rule: 'required' }
  })

  const { phoneCodes } = usePhoneCode()

  const onClick = async() => {
    const phone = `${parseInt(form.phoneCode.value)}${form.phone.value}`
    await sendRequest({ phone })
  }

  return (
    <>
      <C.ComboBoxForm
        form={ form }
        field='phoneCode'
        disableClearable
        options={ phoneCodes }
        label={ t('panel:auth.location') }
      />
      <C.InputForm
        label={ t('panel:auth.phone') }
        form={ form }
        field='phone'
        onEnter={ onClick }
        fullWidth
      />
      <C.Button
        uid='phoneUpdate.continue'
        check={ form }
        onClick={ onClick }
        fullWidth
      >
        { t('panel:auth.continue')}
      </C.Button>
    </>
  )
}

const PhoneCode = () => {
  const t = useI18n()
  const { setLoading } = useLoading()
  const { close } = usePanel()
  const [resendable, updateResendable] = useState(false)
  const [countDown, updateCountDown] = useState(data.codeExpiredAt.countDown())

  const runInterval = () => {
    clearInterval(data.interval)
    data.interval = setInterval(() => {
      const sendable = data.codeExpiredAt.diff() < 0
      updateCountDown(data.codeExpiredAt.countDown())
      updateResendable(sendable)
      if (!sendable) return
      clearInterval(data.interval)
    }, 200)
  }

  useEffect(() => {
    runInterval()
    return () => clearInterval(data.interval)
  })

  const sendPhoneCode = async code => {
    if (code.length !== 6) return
    setLoading('phoneUpdate.sendPhoneCode')
    try {
      await sendRequest({ code })
      close('phoneUpdate')
      $alert(t('panel:phoneUpdate.success'))
    } finally {
      setLoading()
    }
  }

  return (
    <>
      <div className='flex min-h-[4.5rem] mx-auto max-w-xs'>
        <div className='w-7 relative'>
          <LockClockOutlinedIcon className='absolute top-5' fontSize='small' />
        </div>
        <C.Input
          label={ t('panel:auth.phoneCode') }
          variant='standard'
          onChange={ sendPhoneCode }
          center
          fullWidth
        />
      </div>
      <div className='text-center pt-5 text-sm flex items-center justify-center'>
        <TimerIcon fontSize='small'/>
        <span className='px-1 w-12 inline-block'>{ countDown }</span>
        <C.Button
          sx={ { fontSize: '0.75rem' } }
          variant='text'
          disabled={ !resendable }
          onClick={ async() => {
            await sendRequest({})
            runInterval()
          } }
        >{ t('panel:auth.resendPhoneCode') }</C.Button>
      </div>
    </>
  )
}

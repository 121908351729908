import orderCheckout from 'hooks/checkout'
import usePayment from 'hooks/user-booking/payment'

export default function useCheckoutBooking({ booking, refreshBooking, closePanel }) {
  const t = useI18n()
  const { user } = useCurrent()
  const { method, methodParams } = usePaymentMethod()
  const { finalPayment } = usePayment(booking)
  const { id, stylist } = booking || {}
  const successCallback = async() => {
    $alert(t('panel:userBooking.paySuccess'))
    await refreshBooking(id)
    mkt.checkoutBooking({ stylistUserId: stylist.user.id, amount: finalPayment })
  }
  const atmCallback = () => {
    closePanel()
    $messenger.talkToUser(stylist.user.id)
    mkt.checkoutBooking({ stylistUserId: stylist.user.id, amount: finalPayment })
  }
  const zerocardCallback = () => {
    closePanel()
    mkt.checkoutBooking({ stylistUserId: stylist.user.id, amount: finalPayment })
  }
  const input = {
    amount: finalPayment,
    type: 'checkoutBooking',
    typeParams: { bookingId: id },
    method,
    methodParams
  }
  const checkout = orderCheckout({
    input,
    byVisitor: !user,
    onSuccess: successCallback,
    onAtm: atmCallback,
    onZerocard: zerocardCallback
  })

  return async() => {
    mkt.checkoutBookingClick()
    if (!stylist.mapPaySupported) return $alert(t('panel:userBooking.notSupportStylePay'), 'error')
    if (method === 'googlePay' || method === 'cash') {
      // Google pay will fail if pop up confirm dialog
      await checkout()
      return
    }
    if (!confirm(t('panel:userBooking.payByStylePay'))) return

    await checkout()
  }
}

const template = `
  mutation AskPhoneCall($phone: String!, $token: String) {
    askPhoneCall(phone: $phone, token: $token)
  }
`

export default async function askPhoneCall(phone, token) {
  const { askPhoneCall } = await request(template, { phone, token })
  return askPhoneCall
}

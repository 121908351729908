import StepBar from './step-bar'
import Service from './service'
import Other from './other'
import Time from './time'
import Prepay from './prepay'
import stateSimulation from './state-simulation'
import discountConfigRequest from 'request/stylists/discount-ratio'
import prepayConfigRequest from 'request/prepay-booking/config'
import durationRequest from 'request/prepay-booking/duration-resolver'
import useStylistEventBooking from 'hooks/stylist-event-booking'
import formatter from './formatter'

export default function StylistEventBooking() {
  const t = useI18n()
  const { user } = useCurrent()
  const { state, mutate } = useStatic({
    stylistId: 0,
    startTime: null,
    step: 'service',
    notes: '',
    discountConfig: null,
    callback: null,
    updateCallback: null,
    previous: [],
    source: null,
    visible: false
  })

  h.openStylistEventBooking = async({ stylistId, callback, updateCallback, source }) => {
    state.stylistId = stylistId
    state.callback = callback
    state.updateCallback = updateCallback
    state.source = source
    state.step = 'service'
    state.startTime = null
    state.notes = ''
    state.visible = true
    await refreshDiscountConfig()
    mkt.bookingOpen(state.discountConfig.stylistUser.id)
  }

  const refreshDiscountConfig = async notMutate => {
    state.discountConfig = await discountConfigRequest(state.stylistId)
    formatter({ t, ...state.discountConfig })
    const { usedQuota } = state.discountConfig
    state.eventStatus = !usedQuota ? '開放報名中' : `兩週內${ usedQuota }人報名`
    if (state.updateCallback) state.updateCallback(state.stylistId, usedQuota)
    if (notMutate) return
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const previousStep = () => {
    if (state.step === 'time') state.startTime = null
    state.step = state.previous.at(-1)
    state.previous = state.previous.slice(0, -1)
    if (state.step === 'other') {
      state.otherServices = state.discountConfig.services.filter(row => row.service.id !== state.service.service.id)
      if (!state.otherServices.length) return previousStep()
    }
    mutate()
  }

  const showPreviousBtn = () => {
    if (state.previous.length == 0) return false
    return true
  }

  const renderBody = () => {
    if (!state.discountConfig) return
    return (
      <Body
        t={ t }
        user={ user }
        state={ state }
        mutate={ mutate }
        closePanel={ closePanel }
        refreshDiscountConfig={ refreshDiscountConfig }
      />
    )
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      previous={ previousStep }
      showPreviousBtn={ showPreviousBtn() }
      noPadding
    >
      { renderBody() }
    </C.Modal>
  )
}

const contentDic = {
  service: Service,
  other: Other,
  time: Time,
  prepay: Prepay
}

const steps = ['service', 'other', 'time', 'prepay']

let parseSource = false

const Body = ({ t, user, state, mutate, closePanel, refreshDiscountConfig }) => {
  const { method, methodParams, feeResolver } = usePaymentMethod()
  const { stylistUser } = state.discountConfig
  const { open } = usePanel()
  state.paymentMethod = method
  state.paymentMethodParams = methodParams

  useEffect(() => {
    if (!state.source) return
    if (parseSource) return
    parseSource = true
    stateSimulation({
      state,
      nextStep,
      selectTimeSlot
    })
  }, [])

  const prepayCallback = userId => {
    closePanel()
    $messenger.talkToUser(userId)
    if (!state.callback) return
    state.callback()
  }

  const prepayBooking = useStylistEventBooking({ user, state, callback: prepayCallback })

  const submit = async() => {
    if (state.step === 'other') return nextStep()
    try {
      await prepayBooking()
    } catch (error) {
      await refreshDiscountConfig(true)
      prepareBookingDetails()
      if (state.prepayAmount) await preparePaymentDetails()
      mutate()
      throw error
    }
  }

  const feedDuration = async() => {
    const { id } = state.service.service
    const addServiceIds = state.others.map(o => o.service.id)
    state.duration = await durationRequest({
      userId: stylistUser.id,
      type: 'stylistService',
      typeParams: { id },
      addServiceIds
    })
    state.durationText = getServiceTimeText(state.duration, t)
  }

  const nextStep = async notMutate => {
    if (state.step === 'other') await feedDuration()
    const index = steps.indexOf(state.step)
    state.previous = [...state.previous, state.step]
    state.step = steps[index + 1]
    if (notMutate) return
    if (state.step === 'other') {
      state.otherServices = state.discountConfig.services.filter(row => row.service.id !== state.service.service.id)
      if (!state.otherServices.length) return nextStep()
    }
    mutate()
  }

  const fetchSubmitText = () => {
    switch (state.step) {
      case 'prepay':
        if (state.prepayAmount) return t('panel:prepayBooking.submit.prepay')
        return t('panel:prepayBooking.submit.book')
      case 'other':
        const count = state.others.length
        if (count) return t('panel:prepayBooking.submit.add', { count })
        return t('panel:prepayBooking.submit.no')
      default:
        return ''
    }
  }

  const selectTimeSlot = async slot => {
    mkt.selectBookingTime(slot.ymdhm())
    if (!user) return open('auth')
    state.startTime = slot
    state.paymentDetails = []
    state.finalPayment = 0
    await refreshDiscountConfig(true)
    prepareBookingDetails()
    if (!state.prepayAmount) return nextStep()
    await preparePaymentDetails()
    nextStep()
  }

  const prepareBookingDetails = () => {
    const { service, others, startTime, durationText, discountConfig } = state
    state.minPayment = service.customMinPayment + others.map(o => o.customMinPayment).sum()
    state.prepayAmount = service.prepayAmount + others.map(o => o.prepayAmount).sum()

    const bookingDetails = [
      [t('panel:prepayBooking.detail.bookTime'), startTime.format('YYYY/MM/DD (dd) HH:mm')],
      [t('panel:prepayBooking.detail.bookStylist'), stylistUser.name],
      [t('panel:prepayBooking.detail.serviceItems'), service.$title]
    ]
    const otherItems = others.map(o => o.service.name).join(' + ')
    if (otherItems) bookingDetails.push([t('panel:prepayBooking.detail.otherItems'), otherItems])
    bookingDetails.push([t('panel:prepayBooking.detail.serviceDuration'), durationText])
    bookingDetails.push([t('panel:prepayBooking.detail.eventStatus'), state.eventStatus])
    bookingDetails.push([t('panel:prepayBooking.detail.eventDiscount'), discountConfig.discount])

    state.bookingDetails = bookingDetails
    state.userDetails = [
      [t('panel:prepayBooking.detail.name'), `${ user.name } ${ t(`panel:prepayBooking.detail.${ user.gender }`) }`],
      [t('panel:prepayBooking.detail.phone'), user.phone]
    ]
  }

  const finalPaymentResolver = ({ minPayment, prepayAmount, remainDeposit }) => {
    const depositUsed = Math.min(prepayAmount, remainDeposit)
    const remain = remainDeposit - depositUsed
    const subtotal = prepayAmount - depositUsed
    const fee = feeResolver(subtotal)
    const finalPayment = subtotal + fee
    state.subtotal = subtotal
    state.paymentDetails = [
      [t('panel:prepayBooking.detail.minPayment'), minPayment.currency()],
      [t('panel:prepayBooking.detail.prepayAmount'), prepayAmount.currency(), 'text-red-500 font-bold']
    ]

    if (fee > 0) state.paymentDetails.push([t('panel:userBooking.zerocardFee'), fee.currency()])
    if (depositUsed > 0) state.paymentDetails.push([t('panel:prepayBooking.detail.depositUsed'), `-${depositUsed.currency()} (${ t('panel:prepayBooking.detail.remainAmount') }: ${remain.currency()})`])
    if (finalPayment !== prepayAmount) state.paymentDetails.push([t('panel:prepayBooking.detail.payAmount'), finalPayment.currency(), 'text-red-500 font-bold'])

    return finalPayment
  }

  const preparePaymentDetails = async() => {
    const { prepayPolicy, depositConfig } = await prepayConfigRequest({
      prepayAmount: state.prepayAmount,
      stylistId: state.stylistId
    })
    state.prepayPolicy = prepayPolicy
    state.remainDeposit = depositConfig.remainDeposit
    state.finalPayment = finalPaymentResolver(state)
  }

  const submitText = fetchSubmitText()
  const Content = contentDic[state.step]

  const renderSubmitBtn = () => {
    if (!submitText) return
    return (
      <div className='flex-shrink-0 p-5 pt-0'>
        <C.Button
          uid='stylistEventBooking.submit'
          fullWidth
          onClick={ submit }
        >
          { submitText }
        </C.Button>
      </div>
    )
  }

  return (
    <div className='flex flex-col sm:w-[28rem] sm:max-h-[35rem] select-none'>
      <div className='flex-shrink-0'>
        <div className='text-center py-4 truncate max-w-[80%] mx-auto'>
          { t('panel:prepayBooking.title', { name: stylistUser.name, interpolation: { escapeValue: false } }) }
        </div>
        <StepBar step={ state.step } steps={ steps }/>
      </div>
      <div className='p-5 overflow-scroll'>
        <Content
          state={ state }
          mutate={ mutate }
          nextStep={ nextStep }
          selectTimeSlot={ selectTimeSlot }
        />
      </div>
      <div className='relative'><C.Blur/></div>
      { renderSubmitBtn() }
    </div>
  )
}

import orderCheckout from 'hooks/checkout'

export default function useBuyCourse({ courseId, quantity, finalPayment, callback }) {
  const t = useI18n()
  const { method, methodParams } = usePaymentMethod('buyCourse')

  const successCallback = () => {
    $alert(t('panel:buyCourse.success'))
    callback({ status: 'buySuccess' })
  }
  const atmCallback = () => {
    callback({ status: 'buyPending' })
    $messenger.talkToUser(1)
  }
  const input = {
    amount: finalPayment,
    type: 'buyCourse',
    typeParams: {
      courseId,
      quantity
    },
    method,
    methodParams
  }

  const checkout = orderCheckout({
    input,
    onSuccess: successCallback,
    onAtm: atmCallback,
    onZerocard: () => callback({ status: 'buyPending' })
  })

  return async() => {
    await checkout()
  }
}

export default function formatter(customer) {
  const { user, gender, birthday } = customer
  customer.$avatar = user ? user.avatar : cdn('user/default/profile_pic2.png')
  customer.$gender = gender === 'female' ? '女性' : '男性'
  customer.$birthday = renderBirthday(birthday)
}

const renderBirthday = birthday => {
  if (!birthday) return ''
  return `${birthday} (${birthday.age()}歲)`
}
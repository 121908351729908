import fetchResult from './fetch-result'
import { debounce } from 'lodash-es'

export default function SearchKeyword() {
  const { state, mutate } = useStatic({
    visible: false,
    searching: false,
    finishSearch: false,
    result: {
      'style': [],
      'stylist': [],
      'studio': []
    },
    keyword: '',
    keywordType: 'style',
    fetchDebounce: null
  })
  const ref = useRef(null)
  const t = useI18n()

  state.searchQuery = () => {
    fetchResult({ state, mutate, t })
  }

  state.fetchDebounce ||= debounce(() => {
    state.searchQuery()
  }, 500)

  const resetResult = () => {
    state.result[state.keywordType] = []
    state.finishSearch = false
    state.searching = true
    state.uid = uuid()
    mutate()
  }

  const closePanel = e => {
    e.stopPropagation()
    if (state.searching) return
    state.visible = false
    mutate()
  }

  const resultClick = result => {
    const { id, url } = result
    if (url) return h.path.direct(url)
    $messenger.talkToUser(id)
  }

  const keywordTypeChange = type => {
    state.keywordType = type
    if (!state.visible) return mutate()
    resetResult()
    state.searchQuery()
  }

  const keywordClick = () => {
    ref.current.focus()
    if (state.visible) return
    state.visible = true
    resetResult()
    state.searchQuery()
  }

  const keywordChange = e => {
    state.keyword = e.target.value
    resetResult()
    state.fetchDebounce()
  }

  const onScroll = () => infinite(() => {
    if (state.finishSearch) return
    if (state.searching) return
    state.searching = true
    state.uid = uuid()
    mutate()
    state.searchQuery()
  })

  const placeholderKey = () => {
    const dic = {
      style: 'panel:search.placeholder.style',
      stylist: 'panel:search.placeholder.stylist',
      studio: 'panel:search.placeholder.studio'
    }
    return dic[state.keywordType]
  }
  return (
    <div className='pt-6'>
      <div className={ cn('inset-0 fixed z-50', { hidden: !state.visible }) }
        onClick={ closePanel }
      />
      <div className='flex justify-center'>
        <div className='flex shadow rounded-full overflow-hidden mb-5 w-[80%] z-50'>
          <KeywordBtn
            state={ state }
            typeChange={ keywordTypeChange }
            type='style'
            t={ t }
          />
          <KeywordBtn
            state={ state }
            typeChange={ keywordTypeChange }
            type='stylist'
            t={ t }
          />
          <KeywordBtn
            state={ state }
            typeChange={ keywordTypeChange }
            type='studio'
            t={ t }
          />
        </div>
      </div>
      <div className='flex border border-gray-300 rounded-full pl-2 py-2 pr-2 shadow-inner items-center bg-gray-50/5 relative z-50'
        onClick={ keywordClick }
      >
        <div className='scale-75 text-gray-500'>
          <Icon.Search />
        </div>
        <div className='flex-1 p-1 overflow-hidden'>
          <input
            ref={ ref }
            value={ state.keyword }
            onChange={ keywordChange }
            className='bg-white/0 reset'
            placeholder={ t(placeholderKey()) } />
        </div>
        <SearchResult
          state={ state }
          onScroll={ onScroll }
          resultClick={ resultClick }
          t={ t }
        />
      </div>
    </div>
  )
}

const KeywordBtn = ({ state, typeChange, type, t }) => {
  const isActive = type === state.keywordType

  const i18nKey = () => {
    const dic = {
      style: 'panel:search.style',
      stylist: 'panel:search.stylist',
      studio: 'panel:search.studio'
    }
    return dic[type]
  }
  const style = () => {
    if (isActive) return 'text-white'
    return ''
  }

  return (
    <div
      className={ cn('relative text-sm text-[0.8rem] cursor-pointer py-2 w-1/3 text-center', style()) }
      onClick={ () => typeChange(type) }
    >
      <div className={ cn('bg-gray-700 absolute rounded-full top-0 bottom-0 left-0 right-0', { hidden: !isActive }) } />
      <div className='relative'>
        { t(i18nKey()) }
      </div>
    </div>
  )
}

const SearchResult = ({ state, onScroll, resultClick, t }) => (
  <div onScroll={ onScroll() } className={ cn('absolute z-50 top-[2.6rem] left-0 bg-white shadow py-2 w-full h-[25rem] md:h-[20rem] overflow-scroll text-gray-600', { hidden: !state.visible }) }>
    { state.result[state.keywordType].map(result => (
      <Row
        key={ result.id }
        { ...result }
        onClick={ () => resultClick(result) }
      />
    )) }
    { !state.searching && !state.result[state.keywordType].length && (
      <div className='py-5 text-center text-gray-600'>{ t('panel:search.noResult') }</div>
    ) }
    <div className={ cn('p-5 mx-auto opacity-50', { hidden: !state.searching }) }>
      <C.Loading />
    </div>
  </div>
)

const Row = ({ src, title, subTitle, rating, authorized, onClick }) => {
  const renderRating = () => {
    if (!rating) return
    const { score, countText } = rating
    return (
      <div className='text-xs text-gray-500 flex items-center'>
        <C.Stars score={ score } className='w-3 h-3'/>
        <div className='pl-1'>{ countText }</div>
      </div>
    )
  }
  const renderBadge = () => {
    if (!authorized) return
    return <C.Image src={ cdn('images/badge/guarantee64.png') } className='w-4 h-4' alt=''/>
  }

  return (
    <div className='flex p-2 cursor-pointer hover:bg-gray-100 items-center'
      onClick={ onClick }>
      <C.Image
        src={ src }
        className='w-11 h-11' />
      <div className='pl-2'>
        <div className='font-medium text-[0.8rem] flex items-center space-x-1'>
          <div className='line-clamp-1'>{ title }</div>
          { renderBadge() }
        </div>
        <div className='text-xs text-gray-500'>{ subTitle }</div>
        { renderRating() }
      </div>
    </div>
  )
}

import bookingRecordsRequest from 'request/common/booking-records'
import adminBookingRecordsRequest from 'request/admin/booking-records'

const limit = 20
export default async function fetchBookings({ state, mutate }) {
  state.fetching = true
  mutate()
  try {
    const fetched = await fetchResolver(state)
    const idDic = state.bookings.keyBy('id')
    fetched.forEach(booking => {
      if (idDic[booking.id]) return
      state.bookings.push(booking)
    })

    state.finish = fetched.length < limit

  } finally {
    state.fetching = false
    mutate()
  }
}
const fetchResolver = async state => {
  if (state.isAdmin) {
    return adminBookingRecordsRequest({
      offset: state.bookings.length,
      limit,
      userId: state.userId
    })
  }
  return bookingRecordsRequest({
    offset: state.bookings.length,
    limit
  })
}

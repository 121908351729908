const template = `
  query StylistSchedule($userId:ID!, $date:Date!){
    stylistSchedules(input:{
      stylistUserId: $userId, startDate: $date, endDate: $date, duration: 30
    }) {
      startSlot
      endSlot
    }
  }
`

export default async function schedule({ userId, date }) {
  const { stylistSchedules } = await request(template, { userId, date })
  return stylistSchedules.shift()
}

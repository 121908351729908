const template = `
  query Config($stylistId: ID!) {
    me {
      mapPayRewards
    }
    depositConfig(stylistId: $stylistId) {
      remainDeposit
    }
  }
`

export default async function config(stylistId) {
  let { me, depositConfig } = await request(template, { stylistId })
  depositConfig.mapPayRewards = me?.mapPayRewards || 0
  return depositConfig
}

const template = `
  query PrepayPolicy($prepayAmount: Int!) {
    prepayPolicy(prepayAmount: $prepayAmount) {
      title
      content
    }
  }
`

export default async function prepayPolicy(prepayAmount) {
  const { prepayPolicy } = await request(template, { prepayAmount })
  return prepayPolicy
}
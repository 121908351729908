
export default function usePayment(courseAmount) {
  const { feeResolver } = usePaymentMethod()

  const fee = feeResolver(courseAmount)
  const finalPayment = courseAmount + fee

  return {
    finalPayment,
    fee
  }
}
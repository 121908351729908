import { debounce } from 'lodash-es'
import fetchSaveMessages from './fetch-save-messages'
import messageDeleteRequest from 'request/save-message/delete'
import messageClickRequest from 'request/save-message/click'
import MessageCreateOrUpdatePanel from './createOrUpdate'

export default function SaveMessages() {
  const { state, mutate } = useStatic({
    keyword: '',
    messages: [],
    offset: 0,
    fetching: true,
    finish: false,
    fetchDebounce: null,
    callback: null
  })

  state.searchQuery = () => fetchSaveMessages({ state, mutate })

  state.fetchDebounce ||= debounce(() => {
    state.searchQuery()
  }, 500)

  const startLoading = () => {
    state.fetching = true
    state.uid = uuid()
    mutate()
  }

  h.openSaveMessages = ({ callback }) => {
    state.callback = callback
    state.visible = true
    keywordChange('')
  }

  const keywordChange = keyword => {
    state.keyword = keyword
    state.messages = []
    state.offset = 0
    startLoading()
    state.fetchDebounce()
  }

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    startLoading()
    state.searchQuery()
  })

  const renderLoading = () => {
    if (state.fetching) return <div className='py-5'><C.Loading /></div>
    if (state.messages.length) return
    return <div className='py-5 text-center text-gray-600'>無符合的結果</div>
  }

  const newMessageClick = () => h.openSaveMessageCreateOrUpdate({ callback: () => keywordChange('') })

  const closePanel = () => mutate({ visible: false })

  const renderBody = () => (
    <>
      <div className='flex-1 overflow-scroll p-5 flex flex-col' onScroll={ onScroll() }>

        <div className='shrink-0 px-1'>
          <C.Input
            fullWidth
            autoComplete='off'
            variant='standard'
            label={ '搜尋預存回覆' }
            value={ state.keyword }
            onChange={ keywordChange }
          />
        </div>
        <div className=''>
          { state.messages.map(message => (
            <Row
              key={ message.id }
              message={ message }
              state={ state }
              mutate={ mutate }
              closePanel={ closePanel }
            />
          )) }
          { renderLoading() }
        </div>
      </div>
      <div className='relative'><C.Blur/></div>
      <div className='shrink-0 p-5'>
        <C.Button onClick={ newMessageClick } className='w-full'>
          新增預存回覆
        </C.Button>
      </div>
    </>
  )

  return (
    <>
      <C.Modal
        visible={ state.visible }
        close={ closePanel }
        noPadding
      >
        <C.ModalHeader title='預存回覆'>
          { renderBody() }
        </C.ModalHeader>
      </C.Modal>
      <MessageCreateOrUpdatePanel/>
    </>
  )
}

const Row = parent => {
  const { state, mutate } = useStatic({ message: parent.message })

  const messageClick = message => {
    parent.state.callback(message)
    parent.closePanel()
    messageClickRequest(state.message.id)
  }

  const removeMessageFromParent = () => {
    const messages = parent.state.messages.filter(message => message.id != state.message.id)
    parent.mutate({ messages })
  }

  const messageUpdate = () => mutate()

  const editClick = () => h.openSaveMessageCreateOrUpdate({ message: state.message, callback: messageUpdate })

  const deleteClick = async() => {
    if (!confirm(`確定刪除預存回覆『${state.message.title}』?`)) return
    await messageDeleteRequest(state.message.id)
    $alert('已刪除')
    removeMessageFromParent()
  }

  return (

    <div className='border-b px-1 py-3 hover:bg-gray-50 flex justify-between'>
      <div className='cursor-pointer flex-1' onClick={ () => messageClick(state.message.message) }>
        <div className='font-medium'>{ state.message.title }</div>
        <div className='text-sm' { ...state.message.message.rawHtml() }/>
      </div>
      <div className='flex flex-col justify-center space-y-1 text-sm'>
        <Button onClick={ editClick } className='bg-black text-white hover:shadow-xl'>
          編輯
        </Button>
        <Button onClick={ deleteClick } className='border hover:bg-gray-100'>
          刪除
        </Button>
      </div>
    </div>

  )
}

const Button = ({ children, className, ...props }) => (
  <div { ...props } className={ cn('py-2 px-4 rounded-[4px] cursor-pointer text-xs', className) }>
    { children }
  </div>
)
import useStoreState from './state'
import useModifyBookingTime from './factory/modify-booking-time'
import useModifyBookingStylist from './factory/modify-booking-stylist'
import useModifyBookingRemark from './factory/modify-booking-remark'

const Context = ctx('stylistBooking')

const useStore = () => useStoreFactory(
  useStoreState,
  {
    useModifyBookingTime,
    useModifyBookingStylist,
    useModifyBookingRemark
  }
)

export { Context, useStore }

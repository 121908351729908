export default function Other() {
  const { stylistServices, service, toggleOther, isOtherSelect } = useCtx('prepayBooking')

  const fetchRows = () => {
    const hairModelServiceIds = ['59', '65']
    if (service.$type === 'stylistService') {
      return stylistServices.filter(row => !hairModelServiceIds.includes(row.service.id))
        .filter(row => row.service.id !== service.service.id)
    }
    const serviceIds = service.serviceList.map(s => s.id)
    const serviceIdDic = serviceIds.trueDic()
    return stylistServices.filter(row => !hairModelServiceIds.includes(row.service.id))
      .filter(row => !serviceIdDic[row.service.id])
  }

  return (
    <div>
      { fetchRows().map(row => (
        <div
          key={ row.id }
          onClick={ () => toggleOther(row) }
          className='flex items-center justify-between cursor-pointer hover:bg-gray-50 p-3 border-b'
        >
          <div className='space-y-1'>
            <div className='font-bold'>{ row.$title }</div>
            <div className='text-sm'>{ row.$range }</div>
          </div>
          <Icon.Check className={ cn({ hidden: !isOtherSelect(row) }) }/>
        </div>
      ))}
    </div>
  )
}

const template = `
  mutation($input: LoginInput!) {
    login(input: $input) {
      user {
        name
      }
    }
  }
`

export default async function login({ ...input }) {
  const { login } = await request(template, { input })
  return login.user
}

export default function PanelModifyBookingStylist() {
  const { useModifyBookingStylist } = useCtx('stylistBooking')
  const panel = usePanel()
  const state = useModifyBookingStylist()
  if (!state) return ''
  const {
    stylistOptions,
    stylist,
    setStylist,
    submit
  } = state

  return (
    <C.Modal
      visible={ panel.visible('modifyBookingStylist') }
      close={ () => panel.close('modifyBookingStylist') }
    >
      <div className='sm:w-[25rem]'>
        <div className='text-center pt-1 pb-5'>更換設計師</div>
        <C.ComboBox
          label='選擇設計師'
          variant='standard'
          options={ stylistOptions }
          value={ stylist }
          onChange={ value => setStylist(value) }
        />
        <C.Button uid='modifyBookingStylist.submit' className='w-full'
          onClick={ submit }>送出</C.Button>
      </div>
    </C.Modal>
  )
}

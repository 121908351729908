const template = `
  query Tags($type: TagQueryType!, $typeParams: TagQueryTypeParams, $paginationInput: PaginationInput!){
    tags(input: {
      type: $type,
      typeParams: $typeParams,
      paginationInput: $paginationInput
    }) {
      id
      name
      covers{
        id
        firstPhoto {
          url
          width
          height
        }
      }
    }
  }
`

export default async function tags({ type, userId, limit, offset }) {
  const typeParams = { userId }
  const paginationInput = { offset, limit }
  const { tags } = await request(template, { type, typeParams, paginationInput })
  return tags
}

export default function formatter({ t, services, haircutRatio, othersRatio, isFirstTimeBooking }) {
  services.forEach(stylistService => {
    const { name, isHairCutAndWash } = stylistService.service
    const { minPrice, maxPrice, serviceTime } = stylistService
    const ratio = isHairCutAndWash ? haircutRatio : othersRatio
    const minDiscountPrice = Math.ceil(minPrice * ratio)
    const maxDiscountPrice = Math.ceil(maxPrice * ratio)
    stylistService.$title = name
    stylistService.$originalRange = `${ t('common:originalPrice') }: ${ getPriceRangeText(minPrice, maxPrice, t) }`
    stylistService.$range = `${ t('common:discount.discountType.price') }: ${ getPriceRangeText(minDiscountPrice, maxDiscountPrice, t) }`
    stylistService.$discountRatio = `${ ratio * 10 }${ t('common:discount.discountType.priceOff') }`
    stylistService.$serviceTimeText = getServiceTimeText(serviceTime, t)
    stylistService.$remark = isFirstTimeBooking ? t('common:discount.limit.firstTimeBooking') : t('common:discount.limit.firstTimeVisitors')
  })
}
import useStylistService from 'hooks/stylist-service'
import bookingRequest from 'request/common/booking'
import bookingFormatter from './formatter/booking'
import customerFormatter from './formatter/customer'
import stylistsRequest from 'request/stylist-booking/stylists'
import stylistScheduleRequest from 'request/stylist-booking/stylist-schedule'
import bookingCheckoutRequest from 'request/stylist-booking/checkout'
import bookingCancelRequest from 'request/stylist-booking/cancel'
import bookingPenaltyCancelRequest from 'request/stylist-booking/cancel-penalty'
import stylistCustomerRequest from 'request/stylist-booking/customer'
import checkoutByManagerRequest from 'request/studio-manage-booking/checkout-by-manager'

export default function useStoreState() {
  const t = useI18n()
  const { discountLimits, bookingStateDic } = useBookingConfig()
  const panel = usePanel()
  const { state, mutate } = useStatic({
    booking: null,
    callback: null,
    services: [],
    products: [],
    newProductName: '',
    stylistSchedule: null,
    stylists: [],
    customer: null,
    closeQRCodePanel: null
  })
  const { booking, callback } = state
  const { stylistServices } = useStylistService(booking?.stylist.user.id)

  const defaultServices = () => booking.services.map(service => ({
    key: uuid(),
    id: service.service.id,
    name: service.service.name,
    price: service.finalPrice
  }))
  const defaultProducts = () => booking.products.map(product => ({
    key: uuid(),
    id: product.product,
    name: product.name,
    count: product.quantity,
    price: product.finalPrice
  }))

  useEffect(() => {
    if (!panel.state.stylistBooking) return
    state.services = defaultServices()
    state.products = defaultProducts()
    state.newProductName = ''
    mutate()
    return () => {
      mutate({
        booking: null,
        closeQRCodePanel: null
      })
    }
  }, [panel.state.stylistBooking])

  s.CheckoutBookingComplete = ({ message, bookingId }) => {
    if (!state.booking) return
    if (`${bookingId}` !== `${state.booking.id}`) return

    $alert(message)
    completeUpdateRequest()
    if (!state.closeQRCodePanel) return
    state.closeQRCodePanel()
    state.closeQRCodePanel = null
  }

  const openQRCodePanel = () => {
    const { id, stylist } = state.booking
    state.closeQRCodePanel = h.openQRCode({
      title: t('panel:stylistBooking.qrcodeTitle'),
      code: h.path.full.visitorCheckout({ bookingId: id, studioId: stylist.studio.id })
    })
  }

  const refreshBooking = async bookingId => {
    state.booking = await bookingRequest(bookingId)
    mutate()
  }

  global.$openStylistBooking = async({ bookingId, callback, booking }) => {
    const fetchBooking = () => {
      if (!booking) return refreshBooking(bookingId)
      state.booking = booking
      mutate()
    }

    state.callback = callback
    await fetchBooking()
    panel.open('stylistBooking')
  }

  if (!booking) return { state }

  const bookingDate = () => dayjs(booking.startTime).ymd()

  const loadStylists = async() => {
    const studioId = booking.stylist.studio.id
    state.stylists = await stylistsRequest({ studioId, date: bookingDate() })
    mutate()
  }

  const loadStylistSchedule = async date => {
    const userId = booking.stylist.user.id
    state.stylistSchedule = await stylistScheduleRequest({ userId, date })
    mutate()
  }

  const checkout = async() => {
    if (!booking.showQRCode && !booking.payByMapPay && booking.state === 2) return checkoutByManager(booking.id)
    const { services, products } = state
    if (!services.length) return $alert('尚未選擇服務項目', 'error')
    await bookingCheckoutRequest(booking.id, services, products)
    $alert('結帳成功')
    await completeUpdateRequest()
  }

  const checkoutByManager = async bookingId => {
    if (!confirm('設計師已經預收現金?')) return
    await checkoutByManagerRequest(bookingId)
    $alert('設計師代刷成功')
    await completeUpdateRequest()
  }

  const cancel = () => {
    if (booking.showQRCode) return openQRCodePanel()

    h.openBookingCancelReasons({
      bookingId: booking.id,
      callback: confirmCancel
    })
  }

  const cancelPenalty = async() => {
    if (!confirm('確定要取消違約?')) return
    await bookingPenaltyCancelRequest(booking.id)
    await completeUpdateRequest()
  }

  const confirmCancel = async({ cancelType, cancelOtherReason }) => {
    await bookingCancelRequest(booking.id, cancelType, cancelOtherReason)
    await completeUpdateRequest()
  }

  const completeUpdateRequest = async() => {
    if (callback) {
      await refreshBooking(booking.id)
      await callback(state.booking)
    }
    if (state.booking.showQRCode) return
    panel.close('stylistBooking')
  }

  const modifyBookingTime = async() => {
    await loadStylistSchedule(bookingDate())
    panel.open('modifyBookingTime')
  }

  const modifyBookingStylist = async() => {
    await loadStylists()
    panel.open('modifyBookingStylist')
  }

  const modifyBookingRemark = () => {
    panel.open('modifyBookingRemark')
  }

  const modifyBookingCallback = async() => {
    await refreshBooking(booking.id)
    $alert('修改成功')
    if (!callback) return
    await callback(state.booking)
  }

  const refreshCustomer = async() => {
    const customerId = booking.customer.id
    state.customer = await stylistCustomerRequest(customerId)
    customerFormatter(state.customer)
    mutate()
  }

  const bookingCustomer = async() => {
    await refreshCustomer()
    panel.open('bookingCustomer')
  }

  bookingFormatter({ state, mutate, serviceList: stylistServices, panel, discountLimits, bookingStateDic, t })

  return {
    state,
    checkout,
    cancel,
    cancelPenalty,
    modifyBookingTime,
    modifyBookingStylist,
    modifyBookingRemark,
    modifyBookingCallback,
    bookingCustomer,
    refreshCustomer,
    loadStylistSchedule
  }
}

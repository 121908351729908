const template = `
  query RatingInfo($queryType: ReviewQueryType!, $queryParams: ReviewQueryParams!) {
    ratingInfo(type: booking, queryType: $queryType, queryParams: $queryParams) {
      name
      rating
      ratingCounts
    }
  }
`

export default async function ratingInfo({ type, id }) {
  const { ratingInfo } = await request(template, {
    queryType: type,
    queryParams: { id }
  })
  return ratingInfo
}
import albumsRequest from 'request/collection/albums'

export default async function fetchAlbums({ parent, state, mutate }) {
  const limit = 30
  state.fetching = true
  mutate()

  try {
    const fetched = await albumsRequest({
      userId: parent.state.id,
      offset: state.albums.length,
      limit
    })

    const idDic = state.albums.keyBy('id')
    fetched.forEach(album => {
      if (album.privacy !== 'public') return
      if (idDic[album.id]) return
      album.key = album.id
      state.albums.push(album)
    })

    state.finish = fetched.length < limit

  } finally {
    state.fetching = false
    mutate()
  }
}
import bookingChangeStylistRequest from 'request/stylist-booking/change-stylist'

export default function factoryModifyBookingStylist({ state, modifyBookingCallback }) {
  return function useModifyBookingStylist() {
    const [stylist, setStylist] = useState(null)
    const panel = usePanel()
    const { booking, stylists } = state
    useEffect(() => {
      if (!panel.state.modifyBookingStylist) return
      const stylist = stylists.find(stylist => stylist.id === booking.stylist.id)
      setStylist(stylist)
    }, [panel.state.modifyBookingStylist])

    if (!stylist) return null

    const stylistOptions = stylists.map(value => ({ value, name: value.user.name }))

    const completeUpdateRequest = async() => {
      await modifyBookingCallback()
      panel.close('modifyBookingStylist')
      panel.close('stylistBooking')
    }

    const submit = async() => {
      if (stylist.id === booking.stylist.id) {
        panel.close('modifyBookingStylist')
        return
      }
      await bookingChangeStylistRequest({
        bookingId: booking.id,
        newStylistId: stylist.id
      })
      await completeUpdateRequest()
    }

    return {
      stylistOptions,
      stylist,
      setStylist,
      submit
    }
  }
}
const template = `
  mutation($input: RegisterInput!) {
    register(input: $input) {
      user {
        name
      }
    }
  }
`

export default async function register({ ...input }) {
  const { register } = await request(template, { input })
  return register.user
}

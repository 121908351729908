export default function Locale() {
  const t = useI18n()
  const router = useRouter()
  const { state, mutate } = useStatic({
    visible: false,
    locales: [
      { label: '中文 (繁體)', key: 'zh-TW' },
      { label: 'English', key: 'en-US' }
    ]
  })

  h.openChangeLocale = () => {
    state.visible = true
    mutate()
  }

  useEffect(() => {
    if (router.locale === getCookie('locale')) return
    setCookie('locale', router.locale)
  }, [router.locale])

  const closePanel = () => mutate({ visible: false })

  const renderBody = () => {
    if (!state.visible) return
    return <div className='flex flex-col'>
      <List state={ state } closePanel={ closePanel } router={ router }/>
    </div>
  }

  return (
    <C.Modal
      noPadding
      visible={ state.visible }
      close={ closePanel }
    >
      <C.ModalHeader title={ t('common:changeLocale') }>
        { renderBody() }
      </C.ModalHeader>
    </C.Modal>
  )
}

const List = ({ state, closePanel, router }) => <div className='overflow-scroll' >
  { state.locales.map(locale => (
    <Row
      key={ locale.key }
      locale={ locale }
      closePanel={ closePanel }
      router={ router }
    />
  )) }
</div>

const Row = ({ locale, closePanel, router }) => {
  const { key, label } = locale
  const { pathname, asPath, query } = router
  const onClick = () => {
    closePanel()
    setCookie('NEXT_LOCALE', key)
    router.push({ pathname, query }, asPath, { locale: key })
  }
  return <div className='py-4 px-3 cursor-pointer hover:bg-gray-100 flex justify-between relative' onClick={ onClick }>
    <div className='flex-1 pl-10'>{ label }</div>
    { router.locale === key && <Icon.Check className='w-6 h-6'/> }
  </div>
}

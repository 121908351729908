export default function factoryRegister({ state, register }) {
  return function useStepRegister() {
    const { phoneCodes } = usePhoneCode()
    const { locale } = useRouter()
    const t = useI18n()
    const form = useForm({
      phoneCode: {
        rule: 'required',
        value: state.registerForm.phoneCode || '886TW'
      },
      phone: {
        rule: 'required',
        value: state.registerForm.phone
      },
      email: {
        rule: 'email',
        value: state.registerForm.email
      },
      name: {
        rule: 'required',
        value: state.registerForm.name
      },
      gender: {
        rule: 'required',
        value: state.registerForm.gender || 'female'
      },
      birthday: { value: null }
    })

    const submit = () => register({
      phoneCode: form.phoneCode.value,
      phone: form.phone.value,
      email: form.email.value,
      name: form.name.value,
      gender: form.gender.value,
      token: state.registerForm.token,
      birthday: form.birthday.value?.ymd()
    })

    const genderOptions = [
      {
        value: 'female',
        text: t('panel:auth.female')
      },
      {
        value: 'male',
        text: t('panel:auth.male')
      }
    ]

    return {
      phoneCodes,
      locale,
      submit,
      genderOptions,
      form
    }
  }
}

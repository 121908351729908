import reviewRepliesRequest from 'request/review/review-replies'

const limit = 20

export default async function fetchReviewReplies({ review, state, mutate }) {
  state.fetching = true
  mutate()
  try {
    const fetched = await reviewRepliesRequest({
      reviewId: review.id,
      offset: state.replies.length,
      limit
    })
    const idDic = state.replies.keyBy('id')
    const replies = []
    fetched.forEach(reply => {
      if (idDic[reply.id]) return
      formatter(reply)
      replies.push(reply)
    })
    state.replies = [...replies.reverse(), ...state.replies]

    state.finish = fetched.length < limit

  } finally {
    state.fetching = false
    mutate()
  }
}

const formatter = reply => {
  const replyName = () => {
    const { name } = reply.replyUser
    if (!reply.shouldHideName) return name
    return `${name.substring(0, 1)}****`
  }
  reply.$replyerName = replyName()
}
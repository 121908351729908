export default function SwitchAccount() {
  const { user, switchToUser } = useCurrent()
  const { state, mutate } = useStatic({
    visible: false,
    userGroups: []
  })
  h.openSwitchAccount = () => mutate({
    visible: true,
    userGroups: user.userGroups.filter(u => u.id !== user.id)
  })

  const closePanel = () => mutate({ visible: false })

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <Body state={ state } mutate={ mutate } switchToUser={ switchToUser }/>
    </C.Modal>
  )
}

const Body = parent => {
  const t = useI18n()
  const { state, mutate } = useStatic({ toUser: null })
  if (!parent.state.visible) return
  return (
    <div className='sm:w-[22rem] flex flex-col'>
      <div className='text-center py-4 flex-shrink-0'>{ t('common:switchAccount') }</div>
      <div className=''>
        <div className='px-5'>
          { parent.state.userGroups.map(user => (
            <Row
              key={ user.id }
              user={ user }
              state={ state }
              mutate={ mutate }
              parent={ parent }
            />
          )) }
        </div>
      </div>
    </div>
  )
}

const Row = ({ user, state, mutate, parent }) => {
  const userClick = async() => {
    if (state.toUser) return
    mutate({ toUser: user.id })
    await parent.switchToUser(user.id)
    parent.mutate({ visible: false })
  }
  return (
    <div className='flex items-center justify-between cursor-pointer border-b hover:bg-gray-50 py-3 space-x-3'
      onClick={ userClick }>
      <div className='flex items-center space-x-3'>
        <C.Image
          className='rounded-full w-10 h-10'
          src={ user.avatar }
          alt='avatar'
        />
        <div className='text-sm line-clamp-1 flex-1'>{ user.name }</div>
      </div>
      { state.toUser === user.id && <C.Loading/>}
      <div className='w-[2.3rem] shrink-0 text-right'>
        <C.Badge count={ user.unreadCount } />
      </div>
    </div>
  )
}

import postsRequest from 'request/collection/posts'

export default async function fetchPhotos({ parent, state, mutate }) {
  const limit = 40
  state.fetching = true
  mutate()

  try {
    const fetched = await postsRequest({
      serviceCategory: parent.state.serviceCategory,
      type: parent.state.type,
      id: parent.state.id,
      tagId: parent.state.tagId,
      offset: state.offset,
      limit
    })

    const idDic = state.photos.keyBy('postId')
    fetched.forEach(post => {
      if (post.isPrivate) return
      if (!post.photos.length) return
      if (idDic[post.id]) return
      const alt = post.tags?.slice(0, 3).map(tag => tag.name).join('、') || ''
      state.photos.push({
        key: post.id,
        postId: post.id,
        liked: post.isCollected,
        src: h.path.postPhoto({ id: post.photos[0].id }),
        beforePhoto: post.postBeforePhoto?.photo.small?.url,
        alt
      })
    })

    state.offset += limit
    state.finish = fetched.length < limit

  } finally {
    state.fetching = false
    mutate()
  }
}

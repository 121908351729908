const template = `
  query DepositLogs($id: ID!) {
    me {
      deposit(id: $id) {
        depositLogs {
          id
          description
          createdAt
          amount
        }
      }
    }
  }
`

export default async function depositLogs(id) {
  const { me } = await request(template, { id })
  return me.deposit.depositLogs
}

export default function Guarantee() {
  const t = useI18n()
  return (
    <div className='p-5'>
      <div className='border-b py-5'>
        <div className='flex items-center space-x-2'>
          <div><C.Image src={ cdn('images/badge/guarantee256.png') } className='w-8 h-8' alt=''/></div>
          <div className='py-3 text-[1.4rem] font-medium'>{ t('common:guarantee.detailTitle') }</div>
        </div>
        <div className='py-1 font-light'>{ t('common:guarantee.detailDesc') }</div>
      </div>
      <div className='py-5'>
        { t('common:guarantee.details').map(({ title, description }) => (
          <div key={ title }>
            <Title>{ title }</Title>
            <Desc>{ description }</Desc>
          </div>
        )) }
      </div>
    </div>
  )
}

const Title = ({ children }) => (
  <div className='py-1 text-[1.1rem] font-medium'>
    { children }
  </div>
)

const Desc = ({ children }) => (
  <div className='text-gray-800 font-light pb-5'>
    { children }
  </div>
)